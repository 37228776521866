import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import swal from 'sweetalert';

const Navbar = ({ isPosition }) => {
    const history = useHistory();
    const onLogout = () => {
        swal({
            title: "Session End!",
            text: "Successfull Logout",
            icon: "success",
            timer: 2000,
            button: false
        }).then(() => {
            sessionStorage.setItem('isLoggedin', null);
            history.push('/admin')
        })
    }



    // const [AccountPosition, setAccountPosition] = useState(false);
    const AccountPosition = (isPosition) => {
        if (isPosition === 'ADMIN') {
            return (
                <ul>
                    <li>
                        <a href={'/admin/appointment'}>
                            <span className="menu-text">Appointment</span>
                        </a>
                    </li>
                    <li>
                        <a href={'/admin/pending/result'}>
                            <span className="menu-text">Pending Result</span>
                        </a>
                    </li>
                    <li>
                        <a href={'/admin/swabbed/result'}>
                            <span className="menu-text">QRCode Results</span>
                        </a>
                    </li>
                    <li>
                        <a href={'/admin/accounts'}>
                            <span className="menu-text">Accounts</span>
                        </a>
                    </li>
                    {/* <li>
                        <a href={'/admin/pricing'}>
                            <span className="menu-text">Departmental Item Price List</span>
                        </a>
                    </li> */}
                </ul>
            )
        } else if (isPosition === 'SECRETARY') {
            return (
                <ul>
                    <li>
                        <a href={'/admin/appointment'}>
                            <span className="menu-text">Appointment</span>
                        </a>
                    </li>
                    <li>
                        <a href={'/admin/pending/result'}>
                            <span className="menu-text">Pending Result</span>
                        </a>
                    </li>
                    <li>
                        <a href={'/admin/swabbed/result'}>
                            <span className="menu-text">QRCode Results</span>
                        </a>
                    </li>
                </ul>
            )
        } else if (isPosition === 'STAFF') {

        } else if (isPosition === 'SWABBER') {
            return (
                <ul>
                    <li>
                        <a href={'/admin/appointment'}>
                            <span className="menu-text">Appointment</span>
                        </a>
                    </li>
                </ul>
            )
        }
    }

    if(isPosition === 'BDC' || isPosition === 'INFO' || isPosition === 'SWABBER' || isPosition === 'STAFF'){
        return (<></>)
    } else {
        return (
            <div className="vd_navbar vd_nav-width vd_navbar-tabs-menu vd_navbar-left " style={{ backgroundColor: "#520408" }}>
                <div className="navbar-menu clearfix" style={{ backgroundColor: "#520408" }}>
                    <div className="vd_panel-menu hidden-xs">
                    </div>
                    <h3 className="menu-title hide-nav-medium hide-nav-small"></h3>
                    <div className="vd_menu">
                        {AccountPosition(isPosition)}
                    </div>
                </div>
                <div className="navbar-spacing clearfix"></div>
                <div className="vd_menu vd_navbar-bottom-widget">
                    <ul>
                        <li>
                            <a onClick={() => onLogout()}>
                                <span className="menu-icon"><i className="fa fa-sign-out" /></span>
                                <span className="menu-text">Logout</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navbar
