import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import {
    useParams,
    useHistory
} from "react-router-dom";
import validator from 'validator'
import image from './logo-no-background-001.png';
import COUNTRY from './world_countries/Country';
import STATES from './world_countries/States';
import ENV from './ENV';
import QRCode from "qrcode.react";
import QRCodes from 'qrcode'
import swal from 'sweetalert';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import html2canvas from "html2canvas";
import { styled, Box, borderRadius } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import MenuIcon from '@mui/icons-material/Menu';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { format } from 'date-fns'



import LaboratoryTable from './Departmental/Laboratory';
import CTScan from './Departmental/CTScan';
import OperatingRoomTable from './Departmental/OperatingRoom';
import UltrasoundTable from './Departmental/Ultrasound';
import Xray from './Departmental/Xray';
import Pulmonary from './Departmental/Pulmonary';
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';
// 1110963309746630



const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
    width: 600,
    height: 700,
    bgcolor: '#fff',
    // border: '2px solid #000',
    p: 1,
    // px: 4,
    // pb: 3,
};









const Homepage_services = () => {

    const history = useHistory();

    const [table, setTable] = useState(null);
    const [title, setTitle] = useState('');


    const PULMONARY = (v) => {


        setTitle(v)
        setTable(<Pulmonary />)
    } 
    const Laboratory = (v) => {


        setTitle(v)
        setTable(<LaboratoryTable />)
    } 
    const XRay = (v) => {


        setTitle(v)
        setTable(<Xray />)
    } 
    const CTScans = (v) => {


        setTitle(v)
        setTable(<CTScan />)
    } 
    const Ultrasound = (v) => {


        setTitle(v)
        setTable(<UltrasoundTable />)
    } 

    useEffect(()=>{
        PULMONARY('Pulmonary');
    }, [])


    return (
        <div>
            <div>
                {/*<![endif]*/}{/* Specific Page Data */}{/* End of Data */}{/* Mirrored from www.venmond.com/demo/vendroid/layouts-simple.php by HTTrack Website Copier/3.x [XR&CO'2014], Fri, 06 Jan 2023 21:43:15 GMT */}{/* Added by HTTrack */}<meta httpEquiv="content-type" content="text/html;charset=UTF-8" />{/* /Added by HTTrack */}
                <meta charSet="utf-8" />
                <title>BQMHospital</title>
                <meta name="keywords" content="BQMHospital" />
                <meta name="description" content="BQMHospital" />
                <meta name="author" content="BQMHospital" />
                {/* Set the viewport width to device width for mobile */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                {/* Fav and touch icons */}
                <link rel="apple-touch-icon-precomposed" sizes="144x144" href="img/ico/apple-touch-icon-144-precomposed.html" />
                <link rel="apple-touch-icon-precomposed" sizes="114x114" href="img/ico/apple-touch-icon-114-precomposed.png" />
                <link rel="apple-touch-icon-precomposed" sizes="72x72" href="img/ico/apple-touch-icon-72-precomposed.png" />
                <link rel="apple-touch-icon-precomposed" href="img/ico/apple-touch-icon-57-precomposed.png" />
                <link rel="shortcut icon" href="img/ico/favicon.png" />
                {/* CSS */}
                {/* Bootstrap & FontAwesome & Entypo CSS */}
                <link href="css/bootstrap.min.css" rel="stylesheet" type="text/css" />
                <link href="css/font-awesome.min.css" rel="stylesheet" type="text/css" />
                {/*[if IE 7]><link type="text/css" rel="stylesheet" href="css/font-awesome-ie7.min.css"><![endif]*/}
                <link href="css/font-entypo.css" rel="stylesheet" type="text/css" />
                {/* Fonts CSS */}
                <link href="css/fonts.css" rel="stylesheet" type="text/css" />
                {/* Plugin CSS */}
                <link href="plugins/jquery-ui/jquery-ui.custom.min.css" rel="stylesheet" type="text/css" />
                <link href="plugins/prettyPhoto-plugin/css/prettyPhoto.css" rel="stylesheet" type="text/css" />
                <link href="plugins/isotope/css/isotope.css" rel="stylesheet" type="text/css" />
                <link href="plugins/pnotify/css/jquery.pnotify.css" media="screen" rel="stylesheet" type="text/css" />
                <link href="plugins/google-code-prettify/prettify.css" rel="stylesheet" type="text/css" />
                <link href="plugins/mCustomScrollbar/jquery.mCustomScrollbar.css" rel="stylesheet" type="text/css" />
                <link href="plugins/tagsInput/jquery.tagsinput.css" rel="stylesheet" type="text/css" />
                <link href="plugins/bootstrap-switch/bootstrap-switch.css" rel="stylesheet" type="text/css" />
                <link href="plugins/daterangepicker/daterangepicker-bs3.css" rel="stylesheet" type="text/css" />
                <link href="plugins/bootstrap-timepicker/bootstrap-timepicker.min.css" rel="stylesheet" type="text/css" />
                <link href="plugins/colorpicker/css/colorpicker.css" rel="stylesheet" type="text/css" />
                {/* Specific CSS */}
                {/* Theme CSS */}
                <link href="css/theme.min.css" rel="stylesheet" type="text/css" />
                {/*[if IE]> <link href="css/ie.css" rel="stylesheet" > <![endif]*/}
                <link href="css/chrome.css" rel="stylesheet" type="text/chrome" /> {/* chrome only css */}
                {/* Responsive CSS */}
                <link href="css/theme-responsive.min.css" rel="stylesheet" type="text/css" />
                {/* for specific page in style css */}
                {/* for specific page responsive in style css */}
                {/* Custom CSS */}
                <link href="custom/custom.css" rel="stylesheet" type="text/css" />
                {/* Head SCRIPTS */}
                {/* HTML5 shim and Respond.js IE8 support of HTML5 elements and media queries */}
                {/*[if lt IE 9]>
  
 
<![endif]*/}
                <div className="vd_body">
                    {/* Header Start */}
                    <header className="header-2" id="header">
                        <div className="vd_top-menu-wrapper" style={{ background: "#520408" }}>
                            <div className="container">
                                <div className="vd_top-nav vd_nav-width  ">
                                    <div className="vd_panel-header">
                                        <div className="logo">
                                            <a href="index.php">
                                                {/* <img alt="logo" src="img/logo-white.png" /> */}
                                            </a>
                                        </div>
                                        {/* logo */}
                                        <div className="vd_panel-menu left-pos visible-sm visible-xs">
                                            <span className="menu" data-action="toggle-navbar-left">
                                                {/* <i className="fa fa-ellipsis-v" /> */}
                                                <MenuIcon style={{color:"#ddd", fontSize:15, marginTop:6}} />
                                            </span>
                                        </div>

                                        <div className="vd_panel-menu visible-sm visible-xs">
                                            <span className="menu visible-xs" data-action="submenu">
                                                {/* <i className="fa fa-bars" /> */}
                                                <WidgetsIcon style={{color:"#ddd", fontSize:15, marginTop:6}} />
                                            </span>
                                        </div>
                                        {/* vd_panel-menu */}
                                    </div>
                                    {/* vd_panel-header */}
                                </div>
                                <div className="vd_container">
                                    <div className="row">
                                        <div className="col-sm-8 col-xs-12">
                                            <div className="vd_mega-menu-wrapper text-center">
                                                <div className="vd_mega-menu">
                                                    <ul className="mega-ul nav">
                                                        <li className="mega-li">
                                                            <a href="/" className="mega-link" data-waypoint="services">
                                                                <span className="menu-text">HOME</span>
                                                            </a>
                                                        </li>
                                                        <li className="hover-trigger mega-li">
                                                            <a href="#" className="mega-link" data-action="click-trigger">
                                                                <span className="menu-text">SERVICES</span>
                                                            </a>
                                                            <div className="vd_mega-menu-content  width-xs-2  right-xs hover-target no-top" data-action="click-target" style={{ display: 'none' }}>
                                                                <div className="child-menu">
                                                                    <div className="content-list content-menu">
                                                                        <ul className="list-wrapper">
                                                                            <li> <a style={{ cursor: "pointer" }} onClick={() => history.push('/form')}> APPOINTMENT FOR RT-PCR TEST</a> </li>
                                                                            <li>
                                                                                <a href="/services/room/rates">ROOM RATES</a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="/services/medical/packages">MEDICAL PACKAGES</a>
                                                                            </li>
                                                                            {/* <li> <a href="/services/pricing"> DEPARTMENTAL ITEM PRIVATE LIST</a> </li> */}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="mega-li">
                                                            <a href="/services/contact" className="mega-link" data-waypoint="services">
                                                                <span className="menu-text">CONTACT</span>
                                                            </a>
                                                        </li>
                                                        <li className="mega-li">
                                                            <a href="/services/about" className="mega-link" data-waypoint="services">
                                                                <span className="menu-text">ABOUT</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    {/* Head menu search form ends */}                       </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* container */}
                        </div>
                        {/* vd_primary-menu-wrapper */}
                    </header>
                    {/* Header Ends */}
                    <div className="content">
                        <div className="container">
                            <div className="vd_navbar vd_nav-width vd_navbar-tabs-menu vd_navbar-left  " style={{ background: "#520408", padding: 0, }}>

                                <div className="navbar-menu clearfix" style={{ position: "absolute", zIndex:1 }}>
                                    <h3 className="menu-title hide-nav-medium hide-nav-small" style={{padding:10, fontFamily:"timesnewroman", fontWeight:"bold", marginTop:0}}>DEPARTMENTAL ITEM PRICE LIST</h3>
                                    <div className="vd_menu">
                                        <ul>
                                            <li style={{width:280, paddingLeft:10}}>
                                                <a href="#" onClick={()=>PULMONARY('Pulmonary')}>
                                                    <span className="menu-text">PULMONARY</span>
                                                </a>
                                            </li>
                                            <li style={{width:280, paddingLeft:10}}>
                                                <a href="#" onClick={()=>Laboratory('Laboratory')}>
                                                    <span className="menu-text">LABORATORY</span>
                                                </a>
                                            </li>
                                            <li style={{width:280, paddingLeft:10}}>
                                                <a href="#" onClick={()=>XRay('X-Ray')}>
                                                    <span className="menu-text">X-RAY</span>
                                                </a>
                                            </li>
                                            <li style={{width:280, paddingLeft:10}}>
                                                <a href="#" onClick={()=>CTScans('CT-Scan')}>
                                                    <span className="menu-text">CT-SCAN</span>
                                                </a>
                                            </li>
                                            <li style={{width:280, paddingLeft:10}}>
                                                <a href="#" onClick={()=>Ultrasound('Ultrasound')}>
                                                    <span className="menu-text">ULTRASOUND</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <img style={{ width: 600, borderRadius:'100%', position:"absolute" }} src={image} /> */}
                                    
                                </div>
                                <div style={{left:10, top:120, position:"absolute", height: "44vh", backgroundImage: "url(" + image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", width: 250 }}>

                                </div>
                            </div>
                            {/* Middle Content Start */}
                            <div className="vd_content-wrapper" style={{ minHeight: 1000 }}>
                                <div className="vd_container" style={{ minHeight: 1000 }}>
                                    <div className="vd_content clearfix">
                                        {/* vd_head-section */}
                                        <div className="vd_title-section clearfix">
                                            <div className="vd_panel-header">
                                                <h1>{title}</h1>
                                                {/* <small className="subtitle">Simple Layouts with Minimal Button</small>  */}
                                            </div>
                                        </div>
                                        {/* vd_title-section */}
                                        <div className="vd_content-section clearfix" style={{minHeight: '100vh', padding:1}}>
                                            {
                                                table
                                            }
                                            {/* <p>Just by configuring our config.php, you can change whatever style you want. Also can do in html files just by adding specific class, all described in documentation.<br /><br /></p>
                                            <pre className="prettyprint pd-20 prettyprinted" style={{}}><span className="com">// Specific Configuration for Simple Layouts</span><span className="pln">{"\n"}$footer{"  "}</span><span className="pun">=</span><span className="pln"> </span><span className="str">'footer-2'</span><span className="pun">;</span><span className="pln">{"   "}</span><span className="com">// Navigation Footer Clean Style </span><span className="pln">{"\n"}$header{"  "}</span><span className="pun">=</span><span className="pln"> </span><span className="str">'header-4'</span><span className="pun">;</span><span className="pln">{"   "}</span><span className="com">// Navigation Footer Clean Style</span><span className="pln">{"\n"}$medium_nav_toggle </span><span className="pun">=</span><span className="pln"> </span><span className="lit">0</span><span className="pun">;</span><span className="pln"> </span><span className="com">// 1= visible, 0 = none</span><span className="pln">{"\n"}$small_nav_toggle </span><span className="pun">=</span><span className="pln"> </span><span className="lit">0</span><span className="pun">;</span><span className="pln"> </span><span className="com">// 1= visible, 0 = none</span><span className="pln">{"\n"}$navbar_right_config </span><span className="pun">=</span><span className="pln"> </span><span className="lit">0</span><span className="pun">;</span><span className="pln"> </span><span className="com">// no navbar right</span></pre> */}
                                        </div>
                                        {/* .vd_content-section */}
                                    </div>
                                    {/* .vd_content */}
                                </div>
                                {/* .vd_container */}
                            </div>
                            {/* .vd_content-wrapper */}
                            {/* Middle Content End */}
                        </div>
                        {/* .container */}
                    </div>
                    {/* .content */}
                    {/* Footer Start */}
                    {/* <footer className="footer-2" id="footer">
                        <div className="vd_bottom ">
                            <div className="container">
                                <div className="row">
                                    <div className=" col-xs-12">
                                        <div className="copyright text-center">
                                            Copyright ©2014 Venmond Inc. All Rights Reserved
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer> */}
                    {/* Footer END */}
                </div>
                {/* .vd_body END  */}
                <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
                {/*
<a class="back-top" href="#" id="back-top"> <i class="icon-chevron-up icon-white"> </i> </a> */}
                {/* Javascript =============================================== */}
                {/* Placed at the end of the document so the pages load faster */}
                {/*[if lt IE 9]>

<![endif]*/}
            </div>



        </div>

    )
}




export default Homepage_services
