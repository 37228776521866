import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import {
    useParams,
    useHistory
} from "react-router-dom";
import validator from 'validator'
import image from './logo-no-background-001.png';
import bqmlogowhite from './bqmlogowhite.png';
import bqmimageBuilding from './308777896_190624610138660_3905672919237228630_n_front2 (2).jpg';
import hexagonbg from './illustration-of-abstract-hexagon-pattern-background-vector.jpg';
import COUNTRY from './world_countries/Country';
import STATES from './world_countries/States';
import ENV from './ENV';
import QRCode from "qrcode.react";
import QRCodes from 'qrcode'
import swal from 'sweetalert';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import html2canvas from "html2canvas";
import { styled, Box, borderRadius } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import MenuIcon from '@mui/icons-material/Menu';
import WidgetsIcon from '@mui/icons-material/Widgets';
import CallSharpIcon from '@mui/icons-material/CallSharp';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { format } from 'date-fns'
import { Map, GoogleApiWrapper } from 'google-maps-react';



import LaboratoryTable from './Departmental/Laboratory';
import CTScan from './Departmental/CTScan';
import OperatingRoomTable from './Departmental/OperatingRoom';
import UltrasoundTable from './Departmental/Ultrasound';
import Xray from './Departmental/Xray';
import Pulmonary from './Departmental/Pulmonary';
import Maps from './Maps';
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';
// 1110963309746630



const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
    width: 600,
    height: 700,
    bgcolor: '#fff',
    // border: '2px solid #000',
    p: 1,
    // px: 4,
    // pb: 3,
};









const Homepage_contact = () => {

    const history = useHistory();

    const [table, setTable] = useState(null);
    const [title, setTitle] = useState('');


    const PULMONARY = (v) => {


        setTitle(v)
        setTable(<Pulmonary />)
    } 
    const Laboratory = (v) => {


        setTitle(v)
        setTable(<LaboratoryTable />)
    } 
    const XRay = (v) => {


        setTitle(v)
        setTable(<Xray />)
    } 
    const CTScans = (v) => {


        setTitle(v)
        setTable(<CTScan />)
    } 
    const Ultrasound = (v) => {


        setTitle(v)
        setTable(<UltrasoundTable />)
    } 

    useEffect(()=>{
        PULMONARY('Pulmonary');
    }, [])


    return (
        <div>
            <div>
                {/*<![endif]*/}{/* Specific Page Data */}{/* End of Data */}{/* Mirrored from www.venmond.com/demo/vendroid/layouts-simple.php by HTTrack Website Copier/3.x [XR&CO'2014], Fri, 06 Jan 2023 21:43:15 GMT */}{/* Added by HTTrack */}<meta httpEquiv="content-type" content="text/html;charset=UTF-8" />{/* /Added by HTTrack */}
                <meta charSet="utf-8" />
                <title>BQMHospital</title>
                <meta name="keywords" content="BQMHospital" />
                <meta name="description" content="BQMHospital" />
                <meta name="author" content="BQMHospital" />
                {/* Set the viewport width to device width for mobile */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                {/* Fav and touch icons */}
                <link rel="apple-touch-icon-precomposed" sizes="144x144" href="img/ico/apple-touch-icon-144-precomposed.html" />
                <link rel="apple-touch-icon-precomposed" sizes="114x114" href="img/ico/apple-touch-icon-114-precomposed.png" />
                <link rel="apple-touch-icon-precomposed" sizes="72x72" href="img/ico/apple-touch-icon-72-precomposed.png" />
                <link rel="apple-touch-icon-precomposed" href="img/ico/apple-touch-icon-57-precomposed.png" />
                <link rel="shortcut icon" href="img/ico/favicon.png" />
                {/* CSS */}
                {/* Bootstrap & FontAwesome & Entypo CSS */}
                <link href="css/bootstrap.min.css" rel="stylesheet" type="text/css" />
                <link href="css/font-awesome.min.css" rel="stylesheet" type="text/css" />
                {/*[if IE 7]><link type="text/css" rel="stylesheet" href="css/font-awesome-ie7.min.css"><![endif]*/}
                <link href="css/font-entypo.css" rel="stylesheet" type="text/css" />
                {/* Fonts CSS */}
                <link href="css/fonts.css" rel="stylesheet" type="text/css" />
                {/* Plugin CSS */}
                <link href="plugins/jquery-ui/jquery-ui.custom.min.css" rel="stylesheet" type="text/css" />
                <link href="plugins/prettyPhoto-plugin/css/prettyPhoto.css" rel="stylesheet" type="text/css" />
                <link href="plugins/isotope/css/isotope.css" rel="stylesheet" type="text/css" />
                <link href="plugins/pnotify/css/jquery.pnotify.css" media="screen" rel="stylesheet" type="text/css" />
                <link href="plugins/google-code-prettify/prettify.css" rel="stylesheet" type="text/css" />
                <link href="plugins/mCustomScrollbar/jquery.mCustomScrollbar.css" rel="stylesheet" type="text/css" />
                <link href="plugins/tagsInput/jquery.tagsinput.css" rel="stylesheet" type="text/css" />
                <link href="plugins/bootstrap-switch/bootstrap-switch.css" rel="stylesheet" type="text/css" />
                <link href="plugins/daterangepicker/daterangepicker-bs3.css" rel="stylesheet" type="text/css" />
                <link href="plugins/bootstrap-timepicker/bootstrap-timepicker.min.css" rel="stylesheet" type="text/css" />
                <link href="plugins/colorpicker/css/colorpicker.css" rel="stylesheet" type="text/css" />
                {/* Specific CSS */}
                {/* Theme CSS */}
                <link href="css/theme.min.css" rel="stylesheet" type="text/css" />
                {/*[if IE]> <link href="css/ie.css" rel="stylesheet" > <![endif]*/}
                <link href="css/chrome.css" rel="stylesheet" type="text/chrome" /> {/* chrome only css */}
                {/* Responsive CSS */}
                <link href="css/theme-responsive.min.css" rel="stylesheet" type="text/css" />
                {/* for specific page in style css */}
                {/* for specific page responsive in style css */}
                {/* Custom CSS */}
                <link href="custom/custom.css" rel="stylesheet" type="text/css" />
                {/* Head SCRIPTS */}
                {/* HTML5 shim and Respond.js IE8 support of HTML5 elements and media queries */}
                {/*[if lt IE 9]>
  
 
<![endif]*/}
                <div className="vd_body">
                    {/* Header Start */}
                    <header className="header-2" id="header">
                        <div className="vd_top-menu-wrapper" style={{ background: "#520408" }}>
                            <div className="container">
                                <div className="vd_top-nav vd_nav-width  ">
                                    <div className="vd_panel-header">
                                        <div className="vd_panel-menu visible-sm visible-xs">
                                            <span className="menu visible-xs" data-action="submenu">
                                                {/* <i className="fa fa-bars" /> */}
                                                <WidgetsIcon style={{color:"#ddd", fontSize:15, marginTop:6}} />
                                            </span>
                                        </div>
                                        {/* vd_panel-menu */}
                                    </div>
                                    {/* vd_panel-header */}
                                </div>
                                <div className="vd_container">
                                    <div className="row">
                                        <div className="col-sm-8 col-xs-12">
                                            <div className="vd_mega-menu-wrapper text-center">
                                                <div className="vd_mega-menu">
                                                    <ul className="mega-ul nav">
                                                        <li className="mega-li">
                                                            <a href="/" className="mega-link" data-waypoint="services">
                                                                <span className="menu-text">HOME</span>
                                                            </a>
                                                        </li>
                                                        <li className="hover-trigger mega-li">
                                                            <a href="#" className="mega-link" data-action="click-trigger">
                                                                <span className="menu-text">SERVICES</span>
                                                            </a>
                                                            <div className="vd_mega-menu-content  width-xs-2  right-xs hover-target no-top" data-action="click-target" style={{ display: 'none' }}>
                                                                <div className="child-menu">
                                                                    <div className="content-list content-menu">
                                                                        <ul className="list-wrapper">
                                                                            <li> <a style={{ cursor: "pointer" }} onClick={() => history.push('/form')}> APPOINTMENT FOR RT-PCR TEST</a> </li>
                                                                            <li> <a href="/services/pricing"> DEPARTMENTAL ITEM PRICE LIST</a> </li>
                                                                            <li>
                                                                                <a href="/services/room/rates">ROOM RATES</a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="/services/medical/packages">MEDICAL PACKAGES</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="mega-li">
                                                            <a href="#" className="mega-link" data-waypoint="services">
                                                                <span className="menu-text">CONTACT</span>
                                                            </a>
                                                        </li>
                                                        <li className="mega-li">
                                                            <a href="/services/about" className="mega-link" data-waypoint="services">
                                                                <span className="menu-text">ABOUT</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    {/* Head menu search form ends */}                       </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* container */}
                        </div>
                        {/* vd_primary-menu-wrapper */}
                    </header>
                    {/* Header Ends */}
                    <div className="content">
                        <div className="container" style={{padding:0}}>
                            
                            {/* Middle Content Start */}
                            <div className="vd_content-wrapper" style={{ minHeight: 1000 }}>
                                <div className="" style={{ minHeight: 1000 }}>
                                    <div className="vd_content clearfix hidden-xs">
                                        {/* vd_head-section */}
                                        <div className="vd_title-section clearfix" style={{backgroundColor: 'rgb(82, 4, 8)', padding:0, width:"105%", height:300}}>
                                            <img src={bqmimageBuilding} style={{width:'105%', height:300}} />
                                            <div style={{position:"absolute", backgroundColor: 'rgb(82, 4, 8)', opacity:.8, zIndex:1, width:"105%", top:0, height:301}}></div>
                                            {/* <div style={{ left: 10, top: 15, position: 'absolute', height: 250, backgroundImage: {'url("'+bqmlogowhite+'")'}, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: 250 }} /> */}
                                            <img src={bqmlogowhite} style={{ left: 20, top: 15, position: 'absolute', height: 250, width: 250, zIndex:1}} />
                                            <div style={{position:"absolute", zIndex:5, width:"100%", top:140, zIndex:2}}>
                                                <div style={{width:600, margin:"0 auto", padding:50, fontSize:35, fontWeight:"800", textAlign:"center", color:"#fff"}}>Contact Us</div>
                                                <div style={{width:600, height:null, margin:"0 auto", backgroundColor:"#fff", padding:10, borderRadius:5}}>
                                                    {/* <div style={{padding:10, marginBottom:10, border:"5px solid rgb(82, 4, 8)", margin:"0 auto", display:"flex", width:80, height:80, borderRadius:100}}>
                                                        <div style={{justifyContent:"center", alignItems:"center"}}>
                                                            <CallSharpIcon style={{fontSize:50, color: 'rgb(82, 4, 8)'}} />
                                                        </div>
                                                    </div> */}
                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                                                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", textAlign:"center", color:"rgb(82, 4, 8)", fontSize:30, padding:8}}><div style={{padding:10, backgroundColor:'rgb(82, 4, 8)', width:40, height:40, display:"flex", alignItems:"center", justifyContent:"center", borderRadius:10}}><PhoneInTalkIcon style={{fontSize:25, color:"#fff"}} /></div> &nbsp; (034) 485-2764</div>
                                                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", textAlign:"center", color:"rgb(82, 4, 8)", fontSize:30, padding:8}}><div style={{padding:10, backgroundColor:'rgb(82, 4, 8)', width:40, height:40, display:"flex", alignItems:"center", justifyContent:"center", borderRadius:10}}><PhoneInTalkIcon style={{fontSize:25, color:"#fff"}} /></div> &nbsp; (034) 431-3000</div>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                                                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", textAlign:"center", color:"rgb(82, 4, 8)", fontSize:30, padding:8}}><div style={{padding:10, backgroundColor:'rgb(82, 4, 8)', width:40, height:40, display:"flex", alignItems:"center", justifyContent:"center", borderRadius:10}}><PhoneInTalkIcon style={{fontSize:25, color:"#fff"}} /></div> &nbsp; (034) 431-2647</div>
                                                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", textAlign:"center", color:"rgb(82, 4, 8)", fontSize:30, padding:8}}><div style={{padding:10, backgroundColor:'rgb(82, 4, 8)', width:40, height:40, display:"flex", alignItems:"center", justifyContent:"center", borderRadius:10}}><PhoneInTalkIcon style={{fontSize:25, color:"#fff"}} /></div> &nbsp; 0933-815-8482</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* vd_title-section */}
                                        <div className="vd_content-section clearfix" style={{padding:0}}>
                                        {/* style={{minHeight: '100vh', padding:1, backgroundImage: 'url("http://localhost/bqmh/php/illustration-of-abstract-hexagon-pattern-background-vector.jpg")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', opacity:0.5}} */}
                                            <img src={hexagonbg} style={{width:"100%", height:"100%", objectFit:"cover"}} />

                                            <div style={{position:"absolute", width:"100%", zIndex:1, top:300}}>
                                                <div style={{width: '100%', height: '50%', margin: "0 auto", backgroundColor:"#fff"}}>
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1842.873318587844!2d122.97043876262828!3d10.690245203971159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33aed16dd96c95e5%3A0x425f622577ce408!2sBacolod%20Queen%20Of%20Mercy%20Hospital!5e1!3m2!1sen!2sph!4v1678947159292!5m2!1sen!2sph" width={"100%"} height={600} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />


                                                </div>
                                            </div>
                                        </div>
                                        {/* .vd_content-section */}
                                    </div>
                                    <div className="vd_content clearfix visible-xs">
                                        {/* vd_head-section */}
                                        <div className="vd_title-section clearfix" style={{padding:0, width:"105%"}}>
                                            <img src={bqmimageBuilding} style={{width:'105%', height:100}} />
                                            <div style={{position:"absolute", backgroundColor: 'rgb(82, 4, 8)', opacity:.8, zIndex:1, width:"105%", top:0, height:100}}></div>
                                            {/* <div style={{ left: 30, top: 35, position: 'absolute', height: 50, backgroundImage: 'url("/static/media/logo-no-background-001.f3fcb4db8ec8a39597bb.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: 50 }} /> */}
                                            <img src={bqmlogowhite} style={{ left: 30, top: 35, top: 25, position: 'absolute', height: 50, width: 50, zIndex:1}} />
                                            <div style={{position:"absolute", zIndex:5, width:"100%", top:0}}>
                                                <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:400, margin:"0 auto", height:100, fontSize:25, fontWeight:"800", textAlign:"center", color:"#fff"}}>Contact Us</div>
                                                <div style={{width:400, height:null, margin:"0 auto", backgroundColor:"#fff", padding:10, top:50}}>
                                                    {/* <div style={{padding:10, marginBottom:15, border:"5px solid rgb(82, 4, 8)", margin:"0 auto", display:"flex", width:65, height:65, borderRadius:100}}>
                                                        <div style={{justifyContent:"center", alignItems:"center"}}>
                                                            <CallSharpIcon style={{fontSize:37, color: 'rgb(82, 4, 8)'}} />
                                                        </div>
                                                    </div> */}
                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                                                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", textAlign:"center", color:"rgb(82, 4, 8)", fontSize:15, padding:8}}><div style={{padding:5, backgroundColor:'rgb(82, 4, 8)', width:20, height:20, display:"flex", alignItems:"center", justifyContent:"center", borderRadius:5}}><PhoneInTalkIcon style={{fontSize:15, color:"#fff"}} /></div> &nbsp; (034) 485-2764</div>
                                                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", textAlign:"center", color:"rgb(82, 4, 8)", fontSize:15, padding:8}}><div style={{padding:5, backgroundColor:'rgb(82, 4, 8)', width:20, height:20, display:"flex", alignItems:"center", justifyContent:"center", borderRadius:5}}><PhoneInTalkIcon style={{fontSize:15, color:"#fff"}} /></div> &nbsp; (034) 431-3000</div>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                                                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", textAlign:"center", color:"rgb(82, 4, 8)", fontSize:15, padding:8}}><div style={{padding:5, backgroundColor:'rgb(82, 4, 8)', width:20, height:20, display:"flex", alignItems:"center", justifyContent:"center", borderRadius:5}}><PhoneInTalkIcon style={{fontSize:15, color:"#fff"}} /></div> &nbsp; (034) 431-2647</div>
                                                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", textAlign:"center", color:"rgb(82, 4, 8)", fontSize:15, padding:8}}><div style={{padding:5, backgroundColor:'rgb(82, 4, 8)', width:20, height:20, display:"flex", alignItems:"center", justifyContent:"center", borderRadius:5}}><PhoneInTalkIcon style={{fontSize:15, color:"#fff"}} /></div> &nbsp; 0933-815-8482</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{position:"absolute", top:200, width:"105%"}}>
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1842.873318587844!2d122.97043876262828!3d10.690245203971159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33aed16dd96c95e5%3A0x425f622577ce408!2sBacolod%20Queen%20Of%20Mercy%20Hospital!5e1!3m2!1sen!2sph!4v1678947159292!5m2!1sen!2sph" width={"100%"} height={600} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

                                            </div>
                                        </div>
                                        {/* vd_title-section */}
                                        {/* .vd_content-section */}
                                    </div>
                                    {/* .vd_content */}
                                </div>
                                {/* .vd_container */}
                            </div>
                            {/* .vd_content-wrapper */}
                            {/* Middle Content End */}
                        </div>
                        {/* .container */}
                    </div>
                    {/* .content */}
                    {/* Footer Start */}
                    {/* <footer className="footer-2" id="footer">
                        <div className="vd_bottom ">
                            <div className="container">
                                <div className="row">
                                    <div className=" col-xs-12">
                                        <div className="copyright text-center">
                                            Copyright ©2014 Venmond Inc. All Rights Reserved
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer> */}
                    {/* Footer END */}
                </div>
                {/* .vd_body END  */}
                <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
                {/*
<a class="back-top" href="#" id="back-top"> <i class="icon-chevron-up icon-white"> </i> </a> */}
                {/* Javascript =============================================== */}
                {/* Placed at the end of the document so the pages load faster */}
                {/*[if lt IE 9]>

<![endif]*/}
            </div>



        </div>

    )
}




export default Homepage_contact;
