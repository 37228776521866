import axios from 'axios'
// import QRCode from 'qrcode.react'
import QRCodes from 'qrcode'
import QRCode from "qrcode.react";
// import * as React from 'react';
import { React, useEffect, useMemo, useRef, useState } from 'react'
import Header from './Extra/Header'
import Navbar from './Extra/Navbar'
import image from './46964720_931525337041328_7708105959918272512_n.jpg';
import sig from './Van_Binh_signature.png';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Link, useHistory } from 'react-router-dom'
import './../App.css';
import html2canvas from "html2canvas";
import ENV from "./ENV";

import SignaturePad from 'react-signature-canvas'

import {
  Box,
  Stack,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField
} from '@mui/material';
import swal from 'sweetalert'
import { format } from 'date-fns';
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';







const AdminViewSwabbedResult = () => {
  const isPosition = sessionStorage.getItem("isPosition")
  const printRef = useRef();
  let signPad = useRef();
  const history = useHistory()
  const { id, swabid } = useParams();

  if (isPosition === 'SWABBER' || isPosition === 'BDC' || isPosition === 'INFO') {
    history.push('/admin/appointment')
  } else {

  }



  const ddate = new Date()
  const MM = format(ddate, 'MM');
  const DD = format(ddate, 'dd');
  const YY = format(ddate, 'Y');
  const HH = format(ddate, 'HH');
  const MN = format(ddate, 'mm');




  const [EditDetails, setEditDetails] = useState('')

  const [status, setStatus] = useState('')
  const [OnDate, setOnDate] = useState('')
  const [OnTime, setOnTime] = useState('')
  // const [OnTemp, setTemp] = useState('')
  const [DataDecease, setDataDecease] = useState([])


  useEffect(() => {
    DisplayData();
    DisplayDataLastResult()
    // QRCodes.toDataURL(ENV.DOMAIN+'php/printPDF.php?id='+EditDetails.qrcode)
    // .then(async(data)  => {
    //   var formDataQRCode = new FormData();
    //   formDataQRCode.append('resno', EditDetails.qrcode);
    //   formDataQRCode.append('qrcode_url', ENV.DOMAIN+'php/QRCode/'+EditDetails.qrcode);
    //   formDataQRCode.append('base64', data);
    //   await axios({
    //     method:'post',
    //     url:ENV.DOMAIN+'php/saveQRCodeImage.php',
    //     data: formDataQRCode
    //   })
    //   console.log(data)
    // });
    // window.scrollTo(0, 0)
  }, [EditDetails, ENV])

  const DisplayData = () => {
    axios.get(ENV.DOMAIN + 'php/showResult.php?getResult&id=' + id)
      .then((res) => {
        // console.log(res.data)
        setEditDetails(res.data[0])
      }).catch((err) => {
        console.log(err)
      })
  }

  const [DataLastResult, setDataLastResult] = useState([])
  const DisplayDataLastResult = () => {
    axios.get(ENV.DOMAIN + 'php/showResult.php?getResult_lastsent&id=' + id)
      .then((res) => {
        // console.log(res.data)
        setDataLastResult(res.data)
      }).catch((err) => {
        console.log(err)
      })
  }

  const onBack = (value) => {
    history.push('/bqmadminappoint')
  }

  const [open, setOpen] = useState(false);
  const [ShowQRCode, setQRCode] = useState(false);
  const [base64, setBase64] = useState(false);


  // let signData = '';
  const [signData, setSignData] = useState(sig);
  const ClearSign = () => {
    signPad.current.clear();
  }
  const saveSign = () => {
    const data = signPad.current.toDataURL();
    setSignData(data);
    signPad.current.clear();
  }
  const ShowSign = () => {
    signPad.current.toDataURL(signData);
  }

  const [TestCOV, setTestCOV] = useState('')
  const [ColorPosiCOV, setColorPosiCOV] = useState({ color: "red" })
  const [ColorNegaCOV, setColorNegaCOV] = useState({ color: "#000" })
  const [ColorInvaCOV, setColorInvaCOV] = useState({ color: "#000" })
  const [ColorSelectedCOV, setColorSelectedCOV] = useState({ color: "#000" })
  const setResultCOV = (ResultCOV) => {
    if (ResultCOV === 'POSITIVE FOR SARS-COV-2 RNA') {
      setColorPosiCOV({ color: "red" });
      setColorSelectedCOV({ color: "red" });
    } else {
      setColorNegaCOV({ color: "#000" });
      setColorInvaCOV({ color: "#000" });
      setColorSelectedCOV({ color: "#000" });
    }
    setTestCOV(ResultCOV)
  }

  // const [ResultCOV, setResultCOV] = useState('')

  const [Requisitioner, setRequisitioner] = useState('');
  const [SpecimenType, setSpecimenType] = useState('');
  const [AccessNo, setAccessNo] = useState('');
  const [LabProNo, setLabProNo] = useState('');
  const [PaLoc, setPaLoc] = useState('');
  const [PaLocDetails, setPaLocDetails] = useState('');
  const [Collection, setCollection] = useState('');
  const [SpecimenReceipt, setSpecimenReceipt] = useState('');
  const [ResultReleased, setResultReleased] = useState('');
  const [ResultForwarded, setResultForwarded] = useState('');
  const [MedicalDirector, setMedicalDirector] = useState('');
  const [PatientLocDisplay, setPatientLocDisplay] = useState(false);

  const sendViaEmail = async () => {
    // console.log(id)
    // console.log(YY + '-' + MM + '-' + DD + 'T' + HH + ':' + MN)
    var formData = new FormData();
    formData.append("resno", id);
    // formData.append("base64", data);
    formData.append("email", EditDetails.email);
    formData.append("dateTimeForwarded", YY + '-' + MM + '-' + DD + 'T' + HH + ':' + MN);
    // YY+'-'+MM+'-'+DD+'T'+HH+':'+MN
    await axios({
      method: "post",
      url: ENV.DOMAIN + "php/sendUrlEmail.php",
      data: formData,
    })
      .then((res) => {
        if (res.data === "success") {
          swal({
            title: "Done!",
            text: "Successfully sent",
            icon: "success",
            timer: 2000,
            button: false,
          }).then(() => {
            DisplayData();
          });
          console.log("success")
        }
        // console.log(res.data)
        DisplayData();
        DisplayDataLastResult()
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const ShowPositiveRes = (value) => {
    if (value === 'POSITIVE FOR SARS-COV-2 RNA') {
      return (<div style={{ color: "red" }}>POSITIVE FOR SARS-COV-2 RNA</div>)
    } else if (value === 'NEGATIVE FOR SARS-COV-2 RNA') {
      return (<div>NEGATIVE FOR SARS-COV-2 RNA</div>)
    } else if (value === 'INVALID') {
      return (<div>INVALID</div>)
    } else {
      return (<div></div>)
    }
  }


  const SuffixDisplay = (value) => {
    if (value != 'None') {
      return ', ' + EditDetails.suffix;
    } else {
      return '';
    }
  }

  const paclocDes = () => {
    if (EditDetails.PaLocDesc != '') {
      return true;
    } else {
      return false;
    }
  }

  const AddressDisplay = () => {
    if (EditDetails.inter_local === 'International') {
      return (
        <>
          <div style={{ borderBottom: '1px solid #000' }}>
            <b>Address:</b>  {EditDetails.country}{EditDetails.province ? ', ' + EditDetails.province : ''} {EditDetails.address}
          </div>
        </>
      )
    } else {
      return (
        <>
          <div style={{ borderBottom: '1px solid #000' }}>
            <b>Address:</b>  {EditDetails.province}{EditDetails.city ? ', ' + EditDetails.city : ''}{EditDetails.barangay ? ', ' + EditDetails.barangay : ''} {EditDetails.address}
          </div>
        </>
      )
    }
  }


  const ViewResultOption = (isPosition) => {
    if (isPosition == 'ADMIN') {
      return (
        <>
          <a className="btn menu-icon vd_bd-blue vd_blue" href={"/admin/pending/result/encode/edit/" + EditDetails.resno + "/" + swabid}>Edit Result</a> <a href={"/admin/appointment/details/edit/" + EditDetails.id} className="btn menu-icon vd_bd-blue vd_blue">View/Edit Information</a>
        </>
      )
    } else if (isPosition == 'SECRETARY') {
      return (
        <>
        </>
      )
    }
  }

  var listnumber = 1;

  return (
    <div id="pages" className="full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975" data-active="pages " data-smooth-scrolling={1}>
      <div className="vd_body">
        {/* Header Start */}
        <Header />
        {/* Header Ends */}
        <div className="content">
          <div className="container">
            <Navbar isPosition={isPosition} />

            {/* Middle Content Start */}
            <div className="vd_content-wrapper" style={{ minHeight: 1059 }}>
              <div className="vd_container" style={{ minheight: 1059 }}>
                <div className="vd_content clearfix">
                  <div className="vd_head-section clearfix">
                    <div className="vd_panel-header">

                    </div>
                  </div>
                  <div className="vd_title-section clearfix">
                    <div className="vd_panel-header no-subtitle">
                      <h1>Result</h1>
                    </div>
                  </div>
                  <div className="vd_content-section clearfix">
                    <div className="row">
                      <div className='col-sm-8'>
                        <iframe src={ENV.DOMAIN + "php/QRCodeResult.php?id=" + EditDetails.resno} style={{ width: '100%', height: 1313, overflowX: "hidden" }}></iframe>
                      </div>
                      <div className='col-sm-4'>
                        <div className="panel widget" style={{ height: 700 }}>
                          <div class="panel-body" style={{ borderRadius: 10 }}>
                            <div>
                              {ViewResultOption(isPosition)}
                              {/* /admin/pending/result/encode/edit/9 */}
                              {/* <button className="btn menu-icon vd_bd-blue vd_blue" onClick={()=>UpdateResult()}>Update Result</button>&nbsp; */}
                              {/* <a href={"/admin/appointment/details/edit/"+EditDetails.id} className="btn menu-icon vd_bd-blue vd_blue">View/Edit Information</a> */}
                            </div>
                            <br />
                            <div className='well'>
                              <span class="btn btn-success btn-xs" style={{ cursor: "pointer" }} onClick={() => sendViaEmail()}>Send to Email</span>&nbsp;
                              {/* <span class="label vd_bg-blue append-icon">Copy a</span> */}
                              <br />
                              <div>
                                Email: <span style={{ wordBreak: "break-word", textDecoration: "underline" }}>{EditDetails.email}</span>
                              </div>
                              <a href={ENV.DOMAIN + "php/QRCodeResult.php?id=" + EditDetails.resno} target="_blank" style={{ wordBreak: "break-word", textDecoration: "underline" }}>{ENV.DOMAIN}php/QRCodeResult.php?id={EditDetails.resno}</a>
                            </div>
                            <div>
                              <div>Last Sent</div>
                              <div className='well' style={{padding:5}}>
                                {
                                  DataLastResult.map((item)=>{
                                    return (<div>{listnumber++}: {item.datetime}</div>)
                                  })
                                }
                              </div>
                            </div>
                            {/* <div style={{width:"100%"}}>
                                <SignaturePad ref={signPad} canvasProps={{width: 500, height: 200, backgroundColor:'rgba(0,0,0,0)', className: 'sigCanvas'}} />
                              </div>
                              <button onClick={()=>ClearSign()} >Clear</button>
                              <button onClick={()=>saveSign()} >Capture</button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* row */}
                  </div>
                  {/* .vd_content-section */}
                </div>
                {/* .vd_content */}
              </div>
              {/* .vd_container */}
            </div>
            {/* .vd_content-wrapper */}
            {/* Middle Content End */}
          </div>
          {/* .container */}
        </div>
        {/* .content */}
        {/* Footer Start */}
        {/* <footer className="footer-1" id="footer">      
          <div className="vd_bottom ">
            <div className="container">
              <div className="row">
                <div className=" col-xs-12">
                  <div className="copyright">
                    Copyright ©{Copyright} STGCInc
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
        {/* Footer END */}
      </div>
      {/* .vd_body END  */}
      <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
      {/*
    <a class="back-top" href="#" id="back-top"> <i class="icon-chevron-up icon-white"> </i> </a> */}
      {/* Javascript =============================================== */}
      {/* Placed at the end of the document so the pages load faster */}
      {/*[if lt IE 9]>
      
    <![endif]*/}
    </div>

  )
}

export default AdminViewSwabbedResult
