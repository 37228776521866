import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import Header from './Extra/Header'
import Navbar from './Extra/Navbar'
import ReservationDetails from './Extra/ReservationDetails'
import PendingTable from './Extra/PendingTable'
import swal from 'sweetalert'
import { useHistory } from 'react-router-dom'
import SwabbedResultTable from './Extra/SwabbedResultTable'
import ENV from './ENV'
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';

const AdminSwabbedResult = () => {
  useEffect(() => {
    DisplayData();
  }, [])
  const isPosition = sessionStorage.getItem("isPosition")
  // console.log(isPosition)
  const history = useHistory();
  if (isPosition === 'SWABBER' || isPosition === 'BDC' || isPosition === 'INFO') {
    history.push('/admin/appointment')
  } else {

  }










  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState({ field: "", order: "" });



  const HeaderTable = [
    { name: "#", field: "", width: 50, sortable: false },
    { name: "Reservation #", field: "id", width: 150, sortable: true },
    { name: "Fullname", field: "fullname", width: 250, sortable: true },
    { name: "Contact", field: "contact", width: 200, sortable: true },
    { name: "Gender", field: "gender", width: 200, sortable: false },
    { name: "Date Issued", field: 'date_issued', width: 150, sortable: true },
    { name: "", field: null, width: 200, sortable: false },
  ];

  const DisplayData = async () => {
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchSwabbedResult.php?AllData',
    })
      .then((res) => {
        if (res.data) {
          setData(res.data)
        }
      }).catch((err) => {
        console.log(err)
      })
  }

  const [ViewDetails, setViewDetails] = useState(false);
  const [ViewIds, setViewIds] = useState();

  const onView = (item) => {
    setViewIds(item);
    setViewDetails(true);
    console.log(item);
  }

  const onBack = (value) => {
    setViewDetails(value);
    DisplayData();
  }


  const onDelete = (id) => {
    swal({
      title: "Done!",
      text: "Successfully Deleted",
      icon: "success",
      timer: 2000,
      button: false
    }).then(() => {
      // history.push('/bqmadminappoint')
    })
  }
  // console.log(ViewIds)


  const ShowAllData = async () => {
    await axios({
        method: "get",
        // url: "http://bqmh.goldtigerclub.com/php/fetchReservation.php",
        url: ENV.DOMAIN + 'php/fetchSwabbedResult.php?AllData',
        // url:'http://bqmh.goldtigerclub.com/php/adminLogin.php',
    })
        .then((res) => {
            if (res.data) {
                // sessionStorage.setItem('isLoggedin', 'true')
                // history.push('./bqmadminhome')
                setData(res.data);
            }
            // console.log(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
}


const Today = async () => {
  await axios({
      method: "get",
      url: ENV.DOMAIN + 'php/fetchSwabbedResult.php?today',
  })
      .then((res) => {
          if (res.data) {
              // sessionStorage.setItem('isLoggedin', 'true')
              // history.push('./bqmadminhome')
              setData(res.data);
              // console.log(res.data);
          }
          // console.log(res.data)
      })
      .catch((err) => {
          console.log(err);
      });
}


const LastDayData = async () => {
  await axios({
      method: "get",
      url: ENV.DOMAIN + 'php/fetchSwabbedResult.php?LastDayData',
  })
      .then((res) => {
          if (res.data) {
              // sessionStorage.setItem('isLoggedin', 'true')
              // history.push('./bqmadminhome')
              setData(res.data);
              // console.log(res.data);
          }
          // console.log(res.data)
      })
      .catch((err) => {
          console.log(err);
      });
}




  const pad = (number, length) => {
    var str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }



  const reserveData = useMemo(() => {
    let computedReserveData = data;
    if (search) {
      computedReserveData = computedReserveData.filter(Search =>
        Search.id.toLowerCase().includes(search.toLowerCase()) ||
        Search.resnumber.toLowerCase().includes(search.toLowerCase()) ||
        Search.fullname.toLowerCase().includes(search.toLowerCase()) ||
        Search.contact.toLowerCase().includes(search.toLowerCase())
      );
    }

    // console.log(computedReserveData)
    if (sorting.field) {

      computedReserveData = computedReserveData.sort((a, b) => {
        if (sorting.field == 'id') {
          return (
            a[sorting.field].toString().localeCompare(b[sorting.field].toString(), "en", {
              numeric: true,
            }) * (sorting.order === "asc" ? 1 : -1)
          );
        } else if (sorting.field == 'resnumber') {
          return (
            a[sorting.field].toString().localeCompare(b[sorting.field].toString(), "en", {
              numeric: true,
            }) * (sorting.order === "asc" ? 1 : -1)
          );
        } else if (sorting.field == 'contact') {
          return (
            a[sorting.field].toString().localeCompare(b[sorting.field].toString(), "en", {
              numeric: true,
            }) * (sorting.order === "asc" ? 1 : -1)
          );
        } else {
          return (
            a[sorting.field].toString().localeCompare(b[sorting.field].toString(), "en", {
              numeric: false,
            }) * (sorting.order === "asc" ? 1 : -1)
          );
        }

      })


      // const reserved = sorting.order === "asc" ? 1 : -1;
      // // console.log(reserved, sorting.field)
      // computedReserveData = computedReserveData.sort((a, b) => 
      //     reserved * a[sorting.field].toString().localeCompare(b[sorting.field].toString())
      //     // console.log(a[sorting.field].toString().localeCompare(b[sorting.field].toString()))

      // )
    }
    // console.log(computedReserveData)

    return computedReserveData;
  }, [data, search, sorting])



  return (
    <div id="pages" className="full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975" data-active="pages " data-smooth-scrolling={1}>
      <div className="vd_body">
        {/* Header Start */}
        <Header />
        {/* Header Ends */}
        <div className="content">
          <div className="container">
            <Navbar isPosition={isPosition} />

            {/* Middle Content Start */}
            <div className="vd_content-wrapper" style={{ minHeight: 1059 }}>
              <div className="vd_container" style={{ minheight: 1059 }}>
                <div className="vd_content clearfix">
                  <div className="vd_head-section clearfix">
                    <div className="vd_panel-header">
                      <div className="vd_panel-menu hidden-sm hidden-xs" data-intro="<strong>Expand Control</strong><br/>To expand content page horizontally, vertically, or Both. If you just need one button just simply remove the other button code." data-step={5} data-position="left">

                      </div>
                    </div>
                  </div>
                  <div className="vd_title-section clearfix">
                    <div className="vd_panel-header no-subtitle">
                      <h1>QRCode Result</h1>
                    </div>
                  </div>
                  <div className="vd_content-section clearfix" style={{ minHeight: 1000 }}>
                    <div className="row">
                      <SwabbedResultTable isPosition={isPosition} HeaderTable={HeaderTable} onSorting={(field, order) => setSorting({ field, order })} tableList={reserveData} setSearch={setSearch} onView={onView} onDelete={onDelete} search={search} ShowAllData={ShowAllData} Today={Today} LastDayData={LastDayData} />
                    </div>
                    {/* row */}
                  </div>
                  {/* .vd_content-section */}
                </div>
                {/* .vd_content */}
              </div>
              {/* .vd_container */}
            </div>
            {/* .vd_content-wrapper */}
            {/* Middle Content End */}
          </div>
          {/* .container */}
        </div>
        {/* .content */}
        {/* Footer Start */}
        {/* <footer className="footer-1" id="footer">      
          <div className="vd_bottom ">
            <div className="container">
              <div className="row">
                <div className=" col-xs-12">
                  <div className="copyright">
                    Copyright ©{Copyright} STGCInc
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
        {/* Footer END */}
      </div>
      {/* .vd_body END  */}
      <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
      {/*
    <a className="back-top" href="#" id="back-top"> <i className="icon-chevron-up icon-white"> </i> </a> */}
      {/* Javascript =============================================== */}
      {/* Placed at the end of the document so the pages load faster */}
      {/*[if lt IE 9]>
      
    <![endif]*/}
    </div>

  )
}

export default AdminSwabbedResult
