import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie } from 'recharts';
import Header from './Extra/Header'
import Navbar from './Extra/Navbar'
import PRCtable from './Extra/PRCtable';
import ENV from "./ENV";
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';

const AdminHome = () => {
  const isPosition = sessionStorage.getItem("isPosition")
  // https://f001.backblazeb2.com/file/buonzz-assets/jquery.ph-locations.js
  // https://documenter.getpostman.com/view/1134062/T1LJjU52

  const [data, setData] = useState([])
  useEffect(()=>{
    DisplayData();
    DisplayStatusNegative();
    DisplayStatusPositive();
  },[])


  const dataChart = [
    {
      name: '2021-01-00',
      Positive: 10000,
      Negative: 500,
      amt: 2400,
    },
    {
      name: '2021-02-00',
      Positive: 3000,
      Negative: 1398,
      amt: 2210,
    },
    {
      name: '2021-03-00',
      Positive: 2000,
      Negative: 9800,
      amt: 2290,
    },
    {
      name: '2021-04-00',
      Positive: 2780,
      Negative: 3908,
      amt: 2000,
    },
    {
      name: '2021-05-00',
      Positive: 1890,
      Negative: 4800,
      amt: 2181,
    },
    {
      name: '2021-06-00',
      Positive: 2390,
      Negative: 3800,
      amt: 2500,
    },
    {
      name: '2021-07-00',
      Positive: 3490,
      Negative: 4300,
      amt: 2100,
    },
  ];




  const datas = [
    { name: 'Male', value: 400 },
    { name: 'Female', value: 300 },
    // { name: 'Group C', value: 300 },
    // { name: 'Group D', value: 200 },
  ];
  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}% `}
      </text>
    );
  };


  const tableRegistered  = [
    {
      "id" : 1,
      "name" : "asdasd",
      "date_sched" : "0000-00-00"
    },
    {
      "id" : 1,
      "name" : "asdasd",
      "date_sched" : "0000-00-00"
    }
  ]



  const DisplayData = async () => {
    // var formData = new FormData();
    // formData.append('username', username);
    // formData.append('password', password);
    await axios({
      method:'get',
      url:ENV.DOMAIN+'php/fetchReservation.php',
      // url:'http://bqmh.goldtigerclub.com/php/adminLogin.php',
    })
    .then((res)=>{
      if(res.data){
        // sessionStorage.setItem('isLoggedin', 'true')
        // history.push('./bqmadminhome')
        setData(res.data)
      }
      // console.log(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }


  const [onNegative, setNegative] = useState(0);
  const [onPositive, setPositive] = useState(0);
  const DisplayStatusNegative = async () => {
    // var formData = new FormData();
    // formData.append('username', username);
    // formData.append('password', password);
    await axios({
      method:'get',
      url:ENV.DOMAIN+'php/getHome.php?status_test&status=Negative',
      // url:'http://bqmh.goldtigerclub.com/php/adminLogin.php',
    })
    .then((res)=>{
      if(res.data){
        // sessionStorage.setItem('isLoggedin', 'true')
        // history.push('./bqmadminhome')
        setNegative(res.data.length);
      }
      // console.log(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }

  const DisplayStatusPositive = async () => {
    // var formData = new FormData();
    // formData.append('username', username);
    // formData.append('password', password);
    await axios({
      method:'get',
      url:ENV.DOMAIN+'php/getHome.php?status_test&status=Positive',
      // url:'http://bqmh.goldtigerclub.com/php/adminLogin.php',
    })
    .then((res)=>{
      if(res.data){
        // sessionStorage.setItem('isLoggedin', 'true')
        // history.push('./bqmadminhome')
        setPositive(res.data.length);
      }
      // console.log(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }








  return (
<div id="pages" className="full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975" data-active="pages " data-smooth-scrolling={1}>     
  <div className="vd_body">
    {/* Header Start */}
    <Header />
    {/* Header Ends */} 
    <div className="content">
      <div className="container">
        <Navbar isPosition={isPosition} />    
          
        {/* Middle Content Start */}
        <div className="vd_content-wrapper" style={{minHeight: 1059}}>
          <div className="vd_container" style={{height: 1059}}>
            <div className="vd_content clearfix">
              <div className="vd_head-section clearfix">
                <div className="vd_panel-header">
                  <ul className="breadcrumb">
                    <li><a href="index.php">Home</a> </li>
                    <li><a href="pages-custom-product.php">Pages</a> </li>
                    <li className="active">Timeline</li>
                  </ul>
                  <div className="vd_panel-menu hidden-sm hidden-xs" data-intro="<strong>Expand Control</strong><br/>To expand content page horizontally, vertically, or Both. If you just need one button just simply remove the other button code." data-step={5} data-position="left">
                    <div data-action="remove-navbar" data-original-title="Remove Navigation Bar Toggle" data-toggle="tooltip" data-placement="bottom" className="remove-navbar-button menu"> <i className="fa fa-arrows-h" /> </div>
                    <div data-action="remove-header" data-original-title="Remove Top Menu Toggle" data-toggle="tooltip" data-placement="bottom" className="remove-header-button menu"> <i className="fa fa-arrows-v" /> </div>
                    <div data-action="fullscreen" data-original-title="Remove Navigation Bar and Top Menu Toggle" data-toggle="tooltip" data-placement="bottom" className="fullscreen-button menu"> <i className="glyphicon glyphicon-fullscreen" /> </div>
                  </div>
                </div>
              </div>
              <div className="vd_title-section clearfix">
                <div className="vd_panel-header no-subtitle">
                  <h1>Home</h1>
                </div>
              </div>
              <div className="vd_content-section clearfix">


              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 mgbt-xs-15">
                    <div className="vd_status-widget vd_bg-yellow widget">
                        <div className="vd_panel-menu">
                            <div data-action="refresh" data-original-title="Refresh" data-rel="tooltip" className=" menu entypo-icon smaller-font"><i className="icon-cycle" /></div>
                        </div>
                        {/* vd_panel-menu */}
                        <a className="panel-body" href="#">
                            <div className="clearfix">
                                <span className="menu-icon">
                                    {/* <i className="icon-users" /> */}
                                </span>
                                <span className="menu-value">
                                    {data.length}
                                </span>
                            </div>
                            <div className="menu-text clearfix">
                                New Patient
                            </div>
                        </a>
                    </div>
                </div>
            </div>








                
                <div className="row">

                    <div className='col-sm-6 text-center'>
                      {/* {onNegative} */}
                      <PRCtable onNegative={onNegative} onPositive={onPositive} />
                      
                      <br />
                    </div>
                    <div className='col-sm-6 text-center'>


                    <table className="table no-head-border table-striped">
                      <thead className="vd_bg-blue vd_white">
                        <tr>
                          <th>#</th>
                          <th>Customer Name</th>
                          <th style={{width:20}}>Contact</th>
                          <th style={{idth:140}}>Date Schedule</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                          {
                            data.map((item)=>{
                              return (
                                <tr>
                                  <td className="text-left" style={{width:140}}>{item.resnumber}</td>
                                  <td className="text-left" style={{width:250}}>{item.fullname}</td>
                                  <td className="text-center">{item.contact}</td>
                                  <td className="text-left">{item.date_sched}</td>
                                  <td className="menu-action text-center">
                                    <Link to={"/bqmadminappointdetails/"+item.id} class="btn menu-icon vd_bd-grey vd_grey" href="/bqmadminappointdetails/9"> View </Link>
                                  </td>
                                </tr>
                                )
                            })
                          }
                      </tbody>
                    </table>












                      {/* <ResponsiveContainer style={{width:"100%", height:"100 !important"}}> */}
                        {/* <BarChart
                        // style={{with:"100%", height:"100%"}}
                          width={540}
                          height={300}
                          data={dataChart}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="Positive" fill="#fa7d7d" />
                          <Bar dataKey="Negative" fill="#82ca9d" />
                        </BarChart> */}
                      {/* </ResponsiveContainer> */}


                      {/* <ResponsiveContainer width="100%" height="100%"> */}
                        {/* <PieChart width={400} height={400}>
                          <Pie
                            data={datas}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                          >
                            {data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                        </PieChart> */}
                      {/* </ResponsiveContainer> */}






                    </div>
                    <div className='col-sm-6'></div>
                </div>
                {/* row */} 
              </div>
              {/* .vd_content-section */} 
            </div>
            {/* .vd_content */} 
          </div>
          {/* .vd_container */} 
        </div>
        {/* .vd_content-wrapper */} 
        {/* Middle Content End */} 
      </div>
      {/* .container */} 
    </div>
    {/* .content */}
    {/* Footer Start */}
        {/* <footer className="footer-1" id="footer">      
          <div className="vd_bottom ">
            <div className="container">
              <div className="row">
                <div className=" col-xs-12">
                  <div className="copyright">
                    Copyright ©{Copyright} STGCInc
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
    {/* Footer END */}
  </div>
  {/* .vd_body END  */}
  <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
  {/*
<a class="back-top" href="#" id="back-top"> <i class="icon-chevron-up icon-white"> </i> </a> */}
  {/* Javascript =============================================== */} 
  {/* Placed at the end of the document so the pages load faster */} 
  {/*[if lt IE 9]>
  
<![endif]*/}
</div>

  )
}

export default AdminHome
