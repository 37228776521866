import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import ENV from "./ENV";
import Header from "./Extra/Header";
import Navbar from "./Extra/Navbar";
import ReservationDetails from "./Extra/ReservationDetails";
import ReservationTable from "./Extra/ReservationTable";
// const API_URL = 'http://bqmhospital.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';



const AdminReservation = () => {
    // document.body.id = 'tables';
    // document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix nav-left-hide';
    // const handleResize = () => {
    //     if (window.innerWidth < 720) {
    //         document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix';
    //     } else {
    //         document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975';
    //     }
    //   }

    
    const isPosition = sessionStorage.getItem("isPosition")
    const isFullname = sessionStorage.getItem("isFullname")
    const isUsername = sessionStorage.getItem("isUsername")
    const history = useHistory();
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    useEffect(() => {
        DisplayData();
        window.scrollTo(0, 0)
        // handleResize();
    }, []);

    let HeaderTable;
    if (isPosition != 'BDC' && isPosition != 'INFO' && isPosition != 'SWABBER') {
        HeaderTable = [
            { name: "#", field: "", width: 50, sortable: false },
            { name: "Res #", field: "resnumber", width: 110, sortable: true },
            { name: "Purpose", field: "purpose", width: 100, sortable: true },
            { name: "Fullname", field: "fullname", width: 300, sortable: true },
            { name: "Contact", field: "contact", width: 150, sortable: true },
            { name: "Email Address", field: "email", width: 200, sortable: true },
            { name: "Gender", field: "gender", width: 100, sortable: true },
            { name: "Schedule of Swabbing", field: "ScheduleSwabbing", width: 200, sortable: true },
            { name: "Swabbing Time", field: "SwabbingTime", width: 200, sortable: true },
            // {name:"Date Registered",            field:"date_registered",        width:200, sortable:true},
            { name: "Action", field: null, width: 150, sortable: false },
        ];
    } else {
        HeaderTable = [
            { name: "#", field: "", width: 50, sortable: false },
            { name: "Res #", field: "resnumber", width: 110, sortable: true },
            { name: "Purpose", field: "purpose", width: 100, sortable: true },
            { name: "Fullname", field: "fullname", width: 300, sortable: true },
            { name: "Contact", field: "contact", width: 150, sortable: true },
            { name: "Email Address", field: "email", width: 200, sortable: true },
            { name: "Gender", field: "gender", width: 100, sortable: true },
            { name: "Schedule of Swabbing", field: "ScheduleSwabbing", width: 200, sortable: true },
            // {name:"Date Registered",            field:"date_registered",        width:200, sortable:true},
            { name: "Swabbing Time", field: "SwabbingTime", width: 200, sortable: true },
        ];
    }


    const DisplayData = async () => {
        // var formData = new FormData();
        // formData.append('username', username);
        // formData.append('password', password);
        await axios({
            method: "get",
            // url: "http://bqmh.goldtigerclub.com/php/fetchReservation.php",
            url: ENV.DOMAIN + 'php/fetchReservation.php?AllData',
            // url:'http://bqmh.goldtigerclub.com/php/adminLogin.php',
        })
            .then((res) => {
                if (res.data) {
                    // sessionStorage.setItem('isLoggedin', 'true')
                    // history.push('./bqmadminhome')
                    setData(res.data);
                }
                // console.log(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [ViewDetails, setViewDetails] = useState(false);
    const [ViewIds, setViewIds] = useState();

    const onView = (item) => {
        setViewIds(item);
        setViewDetails(true);
        // console.log(item);
    };

    const onBack = (value) => {
        setViewDetails(value);
        DisplayData();
    };

    const onDelete = async ({ id, firstname, middlename, lastname }) => {
        swal({
            title: "Are you sure delete this Appointment",
            text: firstname + ' ' + middlename + ' ' + lastname,
            icon: "info",
            buttons: true,
        }).then(async (isdelete) => {
            if (isdelete) {
                var formData = new FormData();
                formData.append("id", id);
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + "php/deleteReservation.php",
                    data: formData,
                })
                .then((res) => {
                    if (res.data === "success") {
                        DisplayData();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }
        });
    };
    // console.log(ViewIds)

    const pad = (number, length) => {
        var str = "" + number;
        while (str.length < length) {
            str = "0" + str;
        }
        return str;
    };

    const reserveData = useMemo(() => {
        let computedReserveData = data;
        if (search) {
            computedReserveData = computedReserveData.filter((Search) => Search.resnumber.includes(search)
                || Search.fullname.toLowerCase().includes(search.toLowerCase())
                || Search.contact.includes(search)
                || Search.purpose.toLowerCase().includes(search.toLowerCase()));
        }

        if (sorting.field) {

            computedReserveData = computedReserveData.sort((a, b) => {
                if (sorting.field == 'id') {
                    return (
                        a[sorting.field].toString().localeCompare(b[sorting.field].toString(), "en", {
                            numeric: true,
                        }) * (sorting.order === "asc" ? 1 : -1)
                    );
                } else if (sorting.field == 'resnumber') {
                    return (
                        a[sorting.field].toString().localeCompare(b[sorting.field].toString(), "en", {
                            numeric: true,
                        }) * (sorting.order === "asc" ? 1 : -1)
                    );
                } else if (sorting.field == 'contact') {
                    return (
                        a[sorting.field].toString().localeCompare(b[sorting.field].toString(), "en", {
                            numeric: true,
                        }) * (sorting.order === "asc" ? 1 : -1)
                    );
                } else {
                    return (
                        a[sorting.field].toString().localeCompare(b[sorting.field].toString(), "en", {
                            numeric: false,
                        }) * (sorting.order === "asc" ? 1 : -1)
                    );
                }

            })
        }
        return computedReserveData;
    }, [data, search, sorting]);
    // console.log(isPosition)
    const vd_container = (isPosition) => {
        let status;
        if (isPosition === 'BDC' || isPosition === 'INFO' || isPosition === 'SWABBER' || isPosition === 'STAFF') {
            status = '';
        } else {
            status = 'vd_container';
        }
        return status;
    }



    const ShowAllData = async () => {
        await axios({
            method: "get",
            // url: "http://bqmh.goldtigerclub.com/php/fetchReservation.php",
            url: ENV.DOMAIN + 'php/fetchReservation.php?AllData',
            // url:'http://bqmh.goldtigerclub.com/php/adminLogin.php',
        })
            .then((res) => {
                console.log(res.data);
                if (res.data) {
                    // sessionStorage.setItem('isLoggedin', 'true')
                    // history.push('./bqmadminhome')
                    console.log(res.data);
                    setData(res.data);
                }
                // console.log(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const Today = async () => {
        await axios({
            method: "get",
            // url: "http://bqmh.goldtigerclub.com/php/fetchReservation.php",
            url: ENV.DOMAIN + 'php/fetchReservation.php?today',
            // url: ENV.DOMAIN + 'php/fetchReservation.php?LastDayData',
            // url:'http://bqmh.goldtigerclub.com/php/adminLogin.php',
        })
            .then((res) => {
                console.log(res.data)
                if (res.data) {
                    // sessionStorage.setItem('isLoggedin', 'true')
                    // history.push('./bqmadminhome')
                    setData(res.data);
                    console.log(res.data);
                }
                // console.log(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const LastDayData = async () => {
        await axios({
            method: "get",
            // url: "http://bqmh.goldtigerclub.com/php/fetchReservation.php",
            url: ENV.DOMAIN + 'php/fetchReservation.php?LastDayData',
            // url: ENV.DOMAIN + 'php/fetchReservation.php?LastDayData',
            // url:'http://bqmh.goldtigerclub.com/php/adminLogin.php',
        })
            .then((res) => {
                console.log(res.data)
                if (res.data) {
                    // sessionStorage.setItem('isLoggedin', 'true')
                    // history.push('./bqmadminhome')
                    setData(res.data);
                    console.log(res.data);
                }
                // console.log(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }



    return (
        <div id="pages" className="full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975" data-active="pages " data-smooth-scrolling={1}>
            <div className="vd_body">
                {/* Header Start */}
                <Header />
                {/* Header Ends */}
                <div className="content">
                    <div className="container">
                        <Navbar isPosition={isPosition} />
                        {/* Middle Content Start */}
                        <div className="vd_content-wrapper">
                            <div className={vd_container(isPosition)}>
                                <div className="vd_content clearfix">
                                    <div className="vd_head-section clearfix">
                                        <div className="vd_panel-header">

                                        </div>
                                    </div>
                                    <div className="vd_title-section clearfix">
                                        <div className="vd_panel-header no-subtitle">{ViewDetails ? <h1>Details</h1> : <h1>Appointment</h1>}</div>
                                    </div>
                                    <div className="vd_content-section clearfix">
                                        <div className="row" style={{ minHeight: 1059 }}>
                                            {ViewDetails ? (
                                                <ReservationDetails tableList={reserveData} setViewDetails={setViewDetails} ViewIds={ViewIds} onBack={onBack} />
                                            ) : (
                                                <ReservationTable isPosition={isPosition} isFullname={isFullname} isUsername={isUsername} HeaderTable={HeaderTable} onSorting={(field, order) => setSorting({ field, order })} tableList={reserveData} setSearch={setSearch} onView={onView} onDelete={onDelete} search={search} ShowAllData={ShowAllData} Today={Today} LastDayData={LastDayData} />
                                            )}
                                        </div>
                                        {/* row */}
                                    </div>
                                    {/* .vd_content-section */}
                                </div>
                                {/* .vd_content */}
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
                {/* .content */}
                {/* Footer Start */}
                {/* <footer className="footer-1" id="footer">      
          <div className="vd_bottom ">
            <div className="container">
              <div className="row">
                <div className=" col-xs-12">
                  <div className="copyright">
                    Copyright ©{Copyright} STGCInc
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
                {/* Footer END */}
            </div>
            {/* .vd_body END  */}
            <a id="back-top" href="#" data-action="backtop" className="vd_back-top">
                {" "}
                <i className="fa  fa-angle-up"> </i>{" "}
            </a>
            {/*
    <a class="back-top" href="#" id="back-top"> <i class="icon-chevron-up icon-white"> </i> </a> */}
            {/* Javascript =============================================== */}
            {/* Placed at the end of the document so the pages load faster */}
            {/*[if lt IE 9]>
      
    <![endif]*/}
        </div>
    );
};

export default AdminReservation;
