import React from 'react'

const PRCtable = ({onNegative, onPositive}) => {
  return (
    <table className="table table-bordered">
      <tbody className="vd_bg-blue vd_white">
        <tr>
          <td rowSpan={5} style={{textAlign:"center", width:180, fontSize:20}}>
            <div>Covid-19 test Resuld</div>
          </td>
          <td colSpan={2} style={{textAlign:"center", width:180}}>
            PCR Comparator
          </td>
          <td rowSpan={2} style={{textAlign:"center", width:100, fontSize:20}}>
            Subtotal
          </td>
        </tr>
        <tr>
          <td style={{textAlign:"center", width:100}}>
            Positive
          </td>
          <td style={{textAlign:"center", width:100}}>
            Negative
          </td>
        </tr>
        <tr>
          {/* <td style={{textAlign:"center"}}>
            Positive
          </td> */}
          <td style={{textAlign:"center"}}>
            {onPositive}
          </td>
          <td style={{textAlign:"center"}}>
            {onNegative}
          </td>
          <td style={{textAlign:"center"}}>
          {onPositive+onNegative}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default PRCtable
