import axios from 'axios'
// import QRCode from 'qrcode.react'
import QRCode from 'qrcode'
// import * as React from 'react';
import {React, useEffect, useMemo, useRef, useState } from 'react'
import Header from './Extra/Header'
import Navbar from './Extra/Navbar'
import image from './46964720_931525337041328_7708105959918272512_n.jpg';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Link, useHistory } from 'react-router-dom'
import './../App.css';


import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
// import Stack from '@mui/material/Stack';

import SignaturePad from 'react-signature-canvas'
import moment from 'moment'

import {
    Box,
    Stack, 
    Chip, 
    Radio, 
    RadioGroup, 
    FormControlLabel, 
    FormControl, 
    FormLabel, 
    TextField 
} from '@mui/material';
import swal from 'sweetalert'
import ENV from "./ENV";
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';







const AdminEditEncodeResult = () => {
  const isPosition = sessionStorage.getItem("isPosition")
    let signPad = useRef();
    const history = useHistory()
    const {id, swabid} = useParams();
    // console.log(id, swabid)

    const [EditDetails, setEditDetails] = useState('')

    const [status, setStatus] = useState('')
    const [OnDate, setOnDate] = useState('')
    const [OnTime, setOnTime] = useState('')
    // const [OnTemp, setTemp] = useState('')
    const [DataDecease, setDataDecease] = useState([])



    const [Requisitioner, setRequisitioner] = useState('NONE');
    const [SpecimenType, setSpecimenType] = useState('OPS & NPS');
    const [AccessNo, setAccessNo] = useState('');
    const [LabProNo, setLabProNo] = useState('');
    const [PaLoc, setPaLoc] = useState(''); 
    const [PaLocDesc, setPaLocDesc] = useState(''); 
    const [Collection, setCollection] = useState(''); 
    const [SpecimenReceipt, setSpecimenReceipt] = useState(''); 
    const [ResultReleased, setResultReleased] = useState(''); 
    const [ResultForwarded, setResultForwarded] = useState(''); 
    const [MedicalDirectorId, setMedicalDirectorId] = useState(0); 
    const [MedicalDirector, setMedicalDirector] = useState(''); 
    const [EncodedBy, setEncodedBy] = useState(''); 
    const [PerformedBy, setPerformedBy] = useState(''); 
    const [PerformedByTwo, setPerformedByTwo] = useState(''); 
    const [PerformedByThree, setPerformedByThree] = useState(''); 
    const [VerifiedById, setVerifiedById] = useState(''); 
    const [VerifiedBy, setVerifiedBy] = useState(''); 
    const [ValidatedBy, setValidatedBy] = useState(''); 
    const [PatientLocDisplay, setPatientLocDisplay] = useState(false);

  // console.log(MedicalDirectorId)

    const [TextOPD, setTextOPD] = useState('');
    const [TextTRAVEL, setTextTRAVEL] = useState('');
    const [TextCOMPANY, setTextCOMPANY] = useState('');
    const [TextINPATIENT, setTextINPATIENT] = useState('');
    // setTextOPD
    // setTextTRAVEL
    // setTextCOMPANY
    // setTextINPATIENT

    const [ErrorRequisitioner, setErrorRequisitioner] = useState({color:"#000"}); 
    const [ErrorSpecimenType, setErrorSpecimenType] = useState({color:"#000"}); 
    const [ErrorAccessNo, setErrorAccessNo] = useState({color:"#000"}); 
    const [ErrorLabProNo, setErrorLabProNo] = useState({color:"#000"}); 
    const [ErrorPaLoc, setErrorPaLoc] = useState({color:"#000"}); 
    const [ErrorCollection, setErrorCollection] = useState({color:"#000"}); 
    const [ErrorSpecimenReceipt, setErrorSpecimenReceipt] = useState({color:"#000"}); 
    const [ErrorResultReleased, setErrorResultReleased] = useState({color:"#000"}); 
    const [ErrorResultForwarded, setErrorResultForwarded] = useState({color:"#000"}); 
    const [ErrorMedicalDirector, setErrorMedicalDirector] = useState({width:300, textAlign:"center", color:"#000"}); 
    
    const [TextAreaPaLoc, setTextAreaPaLoc] = useState(false); 




    const [DataAccount, setDataAccount] = useState([])











    useEffect(()=>{
        DisplayData();
        MedicalDirectorDisplay();
        EncodedByDisplay()
        // VerifiedByDisplay()
        ValidatedByDisplay()
        PerformedByDisplay()
        MedicalDirectorData()
        VerifiedByData()
    },[])



    const DisplayData = () => {
        axios.get(ENV.DOMAIN+'php/showResult.php?getResult&id='+id)
        .then((res)=>{
          // console.log(res.data)
            setEditDetails(res.data[0])
            setRequisitioner(res.data[0].Requisitioner)
            setSpecimenType(res.data[0].SpecimenType)
            setAccessNo(res.data[0].AccNo)
            setLabProNo(res.data[0].LabProNo)
            onPaLocBTN(res.data[0].purpose)
            setPaLocDesc(res.data[0].PaLocDesc)
            setTextOPD(res.data[0].OPD)
            setTextTRAVEL(res.data[0].TRAVEL)
            setTextCOMPANY(res.data[0].COMPANY)
            setTextINPATIENT(res.data[0].INPATIENT)



            // setMedicalDirector(res.data[0].MedicalDirector)


            setCollection(res.data[0].Collection)
            setSpecimenReceipt(res.data[0].SpecimenReceipt)
            setResultReleased(res.data[0].ResultReleased)
            setResultForwarded(res.data[0].ResultForwarded)




            setEncodedBy(res.data[0].EncodedBy)
            setPerformedBy(res.data[0].PerformedBy)
            setPerformedByTwo(res.data[0].PerformedByTwo)
            setPerformedByThree(res.data[0].PerformedByThree)
            setMedicalDirectorId(res.data[0].MedicalDirector)
            // setVerifiedBy(res.data[0].VerifiedBy)
            setValidatedBy(res.data[0].ValidatedBy)
            onResultCOVBTN(res.data[0].RTPCRTestRresult)
        }).catch((err)=>{
          
            console.log(err)
        })
    }




    const [loopMedicalDirector, setloopMedicalDirector] = useState([])
    const MedicalDirectorDisplay = () => {
      axios.post(ENV.DOMAIN+'php/getAccounts.php?MedicalDirector')
      .then((res)=>{
        // console.log(res.data)
          setloopMedicalDirector(res.data)
      }).catch((err)=>{
          console.log(err)
      })
    }
    const [loopEncodedBy, setloopEncodedBy] = useState([])
    const EncodedByDisplay = () => {
      axios.post(ENV.DOMAIN+'php/getAccounts.php?EncodedBy')
      .then((res)=>{
          setloopEncodedBy(res.data)
      }).catch((err)=>{
          console.log(err)
      })
    }
    // const [loopVerifiedBy, setloopVerifiedBy] = useState([])
    // const VerifiedByDisplay = () => {
    //   axios.post(ENV.DOMAIN+'php/getAccounts.php?VerifiedBy')
    //   .then((res)=>{
    //       setloopVerifiedBy(res.data)
    //   }).catch((err)=>{
    //       console.log(err)
    //   })
    // }
    const [loopValidatedBy, setloopValidatedBy] = useState([])
    const ValidatedByDisplay = () => {
      axios.post(ENV.DOMAIN+'php/getAccounts.php?ValidatedBy')
      .then((res)=>{
          setloopValidatedBy(res.data)
      }).catch((err)=>{
          console.log(err)
      })
    }
    const [loopPerformedBy, setloopPerformedBy] = useState([])
    const PerformedByDisplay = () => {
      axios.post(ENV.DOMAIN+'php/getAccounts.php?PerformedBy')
      .then((res)=>{
          setloopPerformedBy(res.data)
      }).catch((err)=>{
          console.log(err)
      })
    }



    // const [errorRequisitioner, setErrorRequisitioner] = useState('')
    const [errorPaLocDesc, setErrorPaLocDesc] = useState({height: 100, outline: 'none', resize: 'none'})

    const UpdateResult = async () => {






      if(Requisitioner!='' && SpecimenType!='' 
      && AccessNo!='' && LabProNo!='' && Collection!='' 
      && SpecimenReceipt!='' && TestCOV!='' 
      && EncodedBy!='' && ValidatedBy!='' 
      && PerformedBy!='' && PerformedByTwo!='' && PerformedByThree!=''){

        var formData = new FormData();
        formData.append('id', EditDetails.id);
        formData.append('Requisitioner', Requisitioner)
        formData.append('SpecimenType', SpecimenType)
        formData.append('AccessNo', AccessNo)
        formData.append('LabProNo', LabProNo)
        formData.append('TextOPD', TextOPD)
        formData.append('TextTRAVEL', TextTRAVEL)
        formData.append('TextCOMPANY', TextCOMPANY)
        formData.append('TextINPATIENT', TextINPATIENT)
        formData.append('PaLoc', PaLoc)
        formData.append('Collection', Collection)
        formData.append('SpecimenReceipt', SpecimenReceipt)
        formData.append('ResultReleased', ResultReleased)
        formData.append('ResultForwarded', ResultForwarded)
        formData.append('TestCOV', TestCOV)
        formData.append('MedicalDirector', MedicalDirectorId)
        formData.append('EncodedBy', EncodedBy)
        formData.append('PerformedBy', PerformedBy ? PerformedBy : 'NONE')
        formData.append('PerformedByTwo', PerformedByTwo ? PerformedByTwo : 'NONE')
        formData.append('PerformedByThree', PerformedByThree ? PerformedByThree : 'NONE')
        formData.append('VerifiedBy', VerifiedById)
        formData.append('ValidatedBy', ValidatedBy)

        // setTextOPD
        // setTextTRAVEL
        // setTextCOMPANY
        // setTextINPATIENT


        
        console.log(formData)
        await axios({
          method:'post',
          url:ENV.DOMAIN+'php/updateResult.php',
          data:formData
        })
        .then((res)=>{
          console.log(res.data)
          if (res.data === 'success') {
            swal({
              title: "Done!",
              text: "Successfully",
              icon: "success",
              timer: 2000,
              button: false,
            }).then(() => {
              DisplayData();
            });
          } else {
            
          }
          console.log(res.data)
        }).catch((err)=>{
          console.log(err)
        })
      } else {
        swal({
          title: "",
          text: "Unsuccessfully",
          icon: "error",
          timer: 2000,
          button: false,
        }).then(() => {
          if (PaLocDesc=='') {
            setErrorPaLocDesc({height: 100, outline: 'none', resize: 'none', border:"1px solid red"})
          }
          if (Requisitioner) {
            // setErrorRequisitioner({border:"1px solid red"})
          }
        });
      }
    }











    // let signData = '';
    const [signData, setSignData] = useState();
    const ClearSign = () => {
      signPad.current.clear();
    }
    const saveSign = () => {
      const data = signPad.current.toDataURL();
      setSignData(data);
      signPad.current.clear();
    }
    const ShowSign = () => {
      signPad.current.toDataURL(signData);
    }

    const [TestCOV, setTestCOV] = useState('')
    const [ColorPosiCOV, setColorPosiCOV] = useState({color:"red"})
    const [ColorNegaCOV, setColorNegaCOV] = useState({color:"#000"})
    const [ColorInvaCOV, setColorInvaCOV] = useState({color:"#000"})
    const [ColorSelectedCOV, setColorSelectedCOV] = useState({color:"#000"})
    const setResultCOV = (ResultCOV) => {
      if(ResultCOV === 'POSITIVE FOR SARS-COV-2 RNA'){
        setColorPosiCOV({color:"red"});
        setColorSelectedCOV({color:"red"});
      } else {
        setColorNegaCOV({color:"#000"});
        setColorInvaCOV({color:"#000"});
        setColorSelectedCOV({color:"#000"});
      }
      setTestCOV(ResultCOV)
    }

    // const [ResultCOV, setResultCOV] = useState('')


    




    const onSpecimenType = (value) => {
      if(value != '')
      {
        setSpecimenType(value)
        setErrorSpecimenType({color:"#000"})
      } else {
        setSpecimenType('')
        setErrorSpecimenType({color:"red"})
      }
    }
    const onRequisitioner = (value) => {
      if(value != '')
      {
        setRequisitioner(value)
        setErrorRequisitioner({color:"#000"})
      } else {
        setRequisitioner('')
        setErrorRequisitioner({color:"red"})
      }
    }
    const onAccessNo = (value) => {
      if(value != '')
      {
        setAccessNo(value)
        setErrorAccessNo({color:"#000"})
      } else {
        setAccessNo('')
        setErrorAccessNo({color:"red"})
      }
    }
    const onLabProNo = (value) => {
      if(value != '')
      {
        setLabProNo(value)
        setErrorLabProNo({color:"#000"})
      } else {
        setLabProNo('')
        setErrorLabProNo({color:"red"})
      }
    }
    const onPaLoc = (value) => {
      if(value != '')
      {
        setPaLoc(value)
        setErrorPaLoc({color:"#000"})
      } else {
        setPaLoc('')
        setErrorPaLoc({color:"red"})
      }
      setTextAreaPaLoc(true)
    }
    const onCollection = (value) => {
      if(value != '')
      {
        setCollection(value)
        setErrorCollection({color:"#000"})
      } else {
        setCollection('')
        setErrorCollection({color:"red"})
      }
    }
    const onSpecimenReceipt = (value) => {
      if(value != '')
      {
        setSpecimenReceipt(value)
        setErrorSpecimenReceipt({color:"#000"})
      } else {
        setSpecimenReceipt('')
        setErrorSpecimenReceipt({color:"red"})
      }
    }
    const onResultReleased = (value) => {
      if(value != '')
      {
        setResultReleased(value)
        setErrorResultReleased({color:"#000"})
      } else {
        setResultReleased('')
        setErrorResultReleased({color:"red"})
      }
    }
    const onResultForwarded = (value) => {
      if(value != '')
      {
        setResultForwarded(value)
        setErrorResultForwarded({color:"#000"})
      } else {
        setResultForwarded('')
        setErrorResultForwarded({color:"red"})
      }
    }
    const onMedicalDirector = (value) => {
      if(value != '')
      {
        setMedicalDirectorId(value)
        setErrorMedicalDirector({width:300, textAlign:"center", color:"#000"})
      } else {
        setMedicalDirectorId('')
        setErrorMedicalDirector({width:300, textAlign:"center", color:"red"})
      }
    }

    const onEncodedBy = (value) => {
      if(value != '')
      {
        setEncodedBy(value)
      }
    }
    const onPerformedBy = (value) => {
      if(value != '')
      {
        setPerformedBy(value)
      }
    }
    const onPerformedByTwo = (value) => {
      if(value != '')
      {
        setPerformedByTwo(value)
      }
    }
    const onPerformedByThree = (value) => {
      if(value != '')
      {
        setPerformedByThree(value)
      }
    }
    const onVerifiedBy = (value) => {
      if(value != '')
      {
        setVerifiedBy(value)
      }
    }
    const onValidatedBy = (value) => {
      if(value != '')
      {
        setValidatedBy(value)
      }
    }
    

    const [onOPD, setOPD] = useState(false);
    const [onTRAVEL, setTRAVEL] = useState(false);
    const [onCOMPANY, setCOMPANY] = useState(false);
    const [onINPATIENT, setINPATIENT] = useState(false);


    const onPaLocBTN = (value) => {
      if(value === 'OPD') {
        setOPD(true)
        setTRAVEL(false)
        setCOMPANY(false)
        setINPATIENT(false)
        setTextAreaPaLoc(true)
      } else 
      if(value === 'TRAVEL') {
        setOPD(false)
        setTRAVEL(true)
        setCOMPANY(false)
        setINPATIENT(false)
        setTextAreaPaLoc(true)
      } else 
      if(value === 'COMPANY') {
        setOPD(false)
        setTRAVEL(false)
        setCOMPANY(true)
        setINPATIENT(false)
        setTextAreaPaLoc(true)
      } else 
      if(value === 'INPATIENT') {
        setOPD(false)
        setTRAVEL(false)
        setCOMPANY(false)
        setINPATIENT(true)
        setTextAreaPaLoc(true)
      }
      setPaLoc(value)
    }



    const [onPOSITIVERNA, setPOSITIVERNA] = useState(false);
    const [onNEGATIVERNA, setNEGATIVERNA] = useState(false);
    const [onPENDINGRNA, setPENDINGRNA] = useState(false);

    const onResultCOVBTN = (value) => {
      setResultCOV(value)
      // console.log(value)
      if(value === 'POSITIVE FOR SARS-COV-2 RNA') {
        setPOSITIVERNA(true)
        setNEGATIVERNA(false)
        setPENDINGRNA(false)
      } else 
      if(value === 'NEGATIVE FOR SARS-COV-2 RNA') {
        setPOSITIVERNA(false)
        setNEGATIVERNA(true)
        setPENDINGRNA(false)
      } else
      if(value === 'PENDING') {
        setPOSITIVERNA(false)
        setNEGATIVERNA(false)
        setPENDINGRNA(true)
      }
    }


    // const { height, width } = window;
    // console.log(window.innerWidth, window.innerHeight)
    const AutoSizeWidthRight = () => {
      if(window.innerWidth == '1280'){
        return 'col-sm-1'
      } else if(window.innerWidth == '1366'){
        return 'col-sm-2'
      } else {
        return 'col-sm-3'
      }
    }
    const AutoSizeWidthCenter = () => {
      if(window.innerWidth == '1280'){
        return 'col-sm-10'
      } else if(window.innerWidth == '1366'){
        return 'col-sm-8'
      } else {
        return 'col-sm-6'
      }
    }



    const patientLocation = (value, desc) => {
      const date = desc;
      const dateFormat = 'DD-MM-YYYY';
      const toDateFormat = moment(new Date(date)).format(dateFormat);
      // alert(moment(toDateFormat, dateFormat, true).isValid());
      
      if(moment(toDateFormat, dateFormat, true).isValid()){
        var verify = true;
      } else {
        var verify = false;
      }
    
      if(value === 'OPD') {
        return <div class="col-xs-12">
                <input type="text" onChange={(e)=>setTextOPD(e.target.value)}  placeholder="OPD" value={TextOPD} style={{height:38, width:"100%"}} /> 
                <br/>
                <span class="help-inline" style={{color: "rgb(0, 0, 0)"}}>OPD - Purpose</span>
              </div>;
      } else if(value === 'TRAVEL') {
        return <div class="col-xs-12">
                <input type="datetime-local" onChange={(e)=>setTextTRAVEL(e.target.value)}  placeholder="TRAVEL" value={TextTRAVEL} style={{height:38, width:"100%"}} /> 
                <br/>
                <span class="help-inline" style={{color: "rgb(0, 0, 0)"}}>TRAVEL - Purpose</span>
              </div>;
      } else if(value === 'COMPANY') {
        return <div class="col-xs-12">
                <input type="text" onChange={(e)=>setTextCOMPANY(e.target.value)}  placeholder="COMPANY" value={TextCOMPANY} style={{height:38, width:"100%"}} /> 
                <br/>
                <span class="help-inline" style={{color: "rgb(0, 0, 0)"}}>COMPANY - Purpose</span>
              </div>;
      } else if(value === 'INPATIENT') {
        return <div class="col-xs-12">
                <input type="text" onChange={(e)=>setTextINPATIENT(e.target.value)}  placeholder="INPATIENT" value={TextINPATIENT} style={{height:38, width:"100%"}} /> 
                  <br/>
                  <span class="help-inline" style={{color: "rgb(0, 0, 0)"}}>INPATIENT - Purpose</span>
              </div>;
      }
    }

    // ////////////////////////
    const MedicalDirectorData = () => {
      axios.get(ENV.DOMAIN+'php/getAccounts.php?MedicalDirector')
      .then((res)=>{
        setMedicalDirectorId(res.data[0].id)
        setMedicalDirector(res.data[0].fullname)
      }).catch((err)=>{
          console.log(err)
      })
    }
    const VerifiedByData = () => {
      axios.get(ENV.DOMAIN+'php/getAccounts.php?VerifiedBy')
      .then((res)=>{
        setVerifiedById(res.data[0].id)
        setVerifiedBy(res.data[0].fullname)
      }).catch((err)=>{
          console.log(err)
      })
    }










  return (
    <div id="pages" className="full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975" data-active="pages " data-smooth-scrolling={1}>     
      <div className="vd_body">
        {/* Header Start */}
        <Header />
        {/* Header Ends */} 
        <div className="content">
          <div className="container">
            <Navbar isPosition={isPosition} />    
              
            {/* Middle Content Start */}
            <div className="vd_content-wrapper" style={{minHeight: 1059}}>
              <div className="vd_container" style={{minheight: 1059}}>
                <div className="vd_content clearfix">
                  <div className="vd_head-section clearfix">
                    <div className="vd_panel-header">
                      
                    </div>
                  </div>
                  <div className="vd_title-section clearfix">
                    <div className="vd_panel-header no-subtitle">
                        <h1>Edit Result {EditDetails.firstname? ' / '+EditDetails.firstname+' '+EditDetails.middlename+' '+EditDetails.lastname:null}</h1> 
                    </div>
                  </div>
                  <div className="vd_content-section clearfix">
                    <div className="row"> 
                        {/* <div className='col-sm-4'></div> */}
                        {/* <div className='col-sm-4'></div> */}
                        <div className={AutoSizeWidthRight()}></div>
                        <div className={AutoSizeWidthCenter()}>
                          <div className="vd_register-page">
                              <div className="panel widget" style={{minHeight:500}}>
                                  <div className="panel-body form-horizontal" style={{borderRadius:10}}>
                                    
                                    {/* <div><b>Reservation No.:</b> {EditDetails.resnumber}</div>
                                    <div><b>Fullname:</b> {EditDetails.firstname} {EditDetails.middlename} {EditDetails.lastname}</div>
                                    <div><b>Age / Gender:</b> {EditDetails.gender}</div>
                                    <hr /> */}



                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="text" onChange={(e)=>onRequisitioner(e.target.value)} value={Requisitioner}  placeholder="" />
                                            <span style={ErrorRequisitioner} class="help-inline">Requisitioner</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="text" onChange={(e)=>onSpecimenType(e.target.value)} value={SpecimenType} placeholder="" />
                                            <span style={ErrorSpecimenType} class="help-inline">Specimen Type</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="text" onChange={(e)=>onAccessNo(e.target.value)} value={AccessNo} placeholder="" />
                                            <span style={ErrorAccessNo} class="help-inline">Accession No.</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="text" onChange={(e)=>onLabProNo(e.target.value)} value={LabProNo} placeholder="" />
                                            <span style={ErrorLabProNo} class="help-inline">Laboratory Processing No.</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className='col-sm-6'>
                                        <div onClick={()=> onPaLocBTN('OPD')} style={ErrorPaLoc}>
                                          <label>
                                            {
                                              onOPD ?
                                                <CircleIcon />
                                              :
                                                <RadioButtonUncheckedIcon />
                                            } <b>OPD</b>
                                          </label>
                                        </div>
                                        <div onClick={()=> onPaLocBTN('TRAVEL')} style={ErrorPaLoc}>
                                          <label>
                                            {
                                              onTRAVEL ?
                                                <CircleIcon />
                                              :
                                                <RadioButtonUncheckedIcon />
                                            } <b>TRAVEL</b>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-6'>
                                        <div onClick={()=> onPaLocBTN('COMPANY')} style={ErrorPaLoc}>
                                          <label>
                                            {
                                              onCOMPANY ?
                                                <CircleIcon />
                                              :
                                                <RadioButtonUncheckedIcon />
                                            } <b>COMPANY</b>
                                          </label>
                                        </div>
                                        <div onClick={()=> onPaLocBTN('INPATIENT')} style={ErrorPaLoc}>
                                          <label>
                                            {
                                              onINPATIENT ?
                                                <CircleIcon />
                                              :
                                                <RadioButtonUncheckedIcon />
                                            } <b>INPATIENT</b>
                                          </label>
                                        </div>
                                      </div>
                                      
                                      {
                                        patientLocation(PaLoc, PaLocDesc)
                                      }
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="datetime-local" onChange={(e)=>onCollection(e.target.value)} value={Collection} placeholder="" style={{height:38, textTransform:"uppercase"}} />
                                            <span class="help-inline">Date and Time of Collection (MM/DD/YYYY Hr:Min)</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="datetime-local" onChange={(e)=>onSpecimenReceipt(e.target.value)} value={SpecimenReceipt} placeholder="" style={{height:38, textTransform:"uppercase"}} />
                                            <span class="help-inline">Date and Time Specimen Receipt (MM/DD/YYYY Hr:Min)</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="datetime-local" onChange={(e)=>onResultReleased(e.target.value)} value={ResultReleased} placeholder="" style={{height:38, textTransform:"uppercase"}} />
                                            <span class="help-inline">Date and Time Result Released (MM/DD/YYYY Hr:Min)</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="datetime-local" onChange={(e)=>onResultForwarded(e.target.value)} value={ResultForwarded} placeholder="" style={{height:38, textTransform:"uppercase"}} />
                                            <span class="help-inline">Date and Time Result Forwarded (MM/DD/YYYY Hr:Min)</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-xs-12">
                                        <div className=' well' style={{textAlign:"center"}}>
                                          <div style={{textAlign:"center", fontFamily:"timesneroman"}}>
                                            <b>SARS-COV2 RT-PCR TEST RESULT</b>
                                          </div>

                                          <div className='row' style={{padding:5, marginBottom:0}}>
                                            <div className='col-sm-4'>
                                              <div style={ErrorPaLoc} onClick={()=> onResultCOVBTN('POSITIVE FOR SARS-COV-2 RNA')}>
                                                <label>
                                                  

                                                {
                                                    onPOSITIVERNA ?           
                                                      <Button variant="contained" size="large" color="error" style={{color:"white"}}>
                                                        POSITIVE SARS-COV-2 RNA
                                                      </Button>
                                                    :          
                                                    <Button style={{color:"red"}}>
                                                      POSITIVE SARS-COV-2 RNA
                                                    </Button>
                                                  } 
                                                </label>
                                              </div>
                                            </div>
                                            <div className='col-sm-4'>
                                              <div onClick={()=> onResultCOVBTN('NEGATIVE FOR SARS-COV-2 RNA')}>
                                                <label>

                                                  {
                                                    onNEGATIVERNA ?          
                                                    <Button variant="contained" size="large" color="success">
                                                      NEGATIVE SARS-COV-2 RNA
                                                    </Button>
                                                    :
                                                    <Button>
                                                      NEGATIVE SARS-COV-2 RNA
                                                    </Button>
                                                  } 
                                                </label>
                                              </div>
                                            </div>
                                            <div className='col-sm-4'>
                                              <div onClick={()=> onResultCOVBTN('PENDING')}>
                                                <label>

                                                  {
                                                    onPENDINGRNA ?         
                                                    <Button variant="contained" size="large">
                                                      PENDING
                                                    </Button>
                                                    :
                                                    <Button>
                                                      PENDING
                                                    </Button>
                                                  } 
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              {MedicalDirector}
                                              {/* <select onChange={(e)=>onMedicalDirector(e.target.value)} value={MedicalDirectorId} style={{height:38}}>
                                                <option value="" selected disabled>Select...</option>
                                                {
                                                  loopMedicalDirector.map(({id, fullname})=>{
                                                    return (<option value={id}>{fullname}</option>)
                                                  })
                                                }
                                              </select> */}
                                            </div>
                                            <span class="help-inline">Medical Director's Office</span>
                                            {/* <div style={ErrorMedicalDirector}>Medical Director's Office</div> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">                                          
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              <select onChange={(e)=>onEncodedBy(e.target.value)} value={EncodedBy} style={{height:38}}>
                                                <option value="" selected="" disabled="">Select...</option>
                                                {
                                                  loopEncodedBy.map(({id, fullname})=>{
                                                    return (<option value={id}>{fullname}</option>)
                                                  })
                                                }
                                              </select>
                                            </div>
                                            <span class="help-inline">Encoded By</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              {VerifiedBy}
                                            </div>
                                            <span class="help-inline">Verified By</span>
                                            {/* <div style={ErrorMedicalDirector}>Medical Director's Office</div> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              <select onChange={(e)=>onValidatedBy(e.target.value)} value={ValidatedBy} style={{height:38}}>
                                                <option value="" selected disabled>Select...</option>
                                                {
                                                  loopValidatedBy.map(({id, fullname})=>{
                                                    return (<option value={id}>{fullname}</option>)
                                                  })
                                                }
                                              </select>
                                            </div>
                                            <span class="help-inline">Validated By</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              <select onChange={(e)=>onPerformedBy(e.target.value)} value={PerformedBy} style={{height:38}}>
                                                <option value="" selected disabled>Select...</option>
                                                <option value={"NONE"}>---NONE---</option>
                                                {
                                                  loopPerformedBy.map(({id, fullname})=>{
                                                    return (<option value={id}>{fullname}</option>)
                                                  })
                                                }
                                              </select>
                                            </div>
                                            <span class="help-inline">1. Performed By</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              <select onChange={(e)=>onPerformedByTwo(e.target.value)} value={PerformedByTwo} style={{height:38}}>
                                                <option value="" selected disabled>Select...</option>
                                                <option value={"NONE"}>---NONE---</option>
                                                {
                                                  loopPerformedBy.map(({id, fullname})=>{
                                                    return (<option value={id}>{fullname}</option>)
                                                  })
                                                }
                                              </select>
                                            </div>
                                            <span class="help-inline">2. Performed By</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              <select onChange={(e)=>onPerformedByThree(e.target.value)} value={PerformedByThree} style={{height:38}}>
                                                <option value="" selected disabled>Select...</option>
                                                <option value={"NONE"}>---NONE---</option>
                                                {
                                                  loopPerformedBy.map(({id, fullname})=>{
                                                    return (<option value={id}>{fullname}</option>)
                                                  })
                                                }
                                              </select>
                                            </div>
                                            <span class="help-inline">3. Performed By</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        
                                      </div>
                                    </div>
































                                    
                                  </div>
                              </div>
                              <div className="panel widget" style={{height:700}}>
                                <div class="panel-body" style={{borderRadius:10}}>
                                  <a href={"/admin/swabbed/result/"+EditDetails.resno+"/"+swabid} className="btn menu-icon vd_bd-red vd_red">Back</a>&nbsp;
                                  <button onClick={()=>UpdateResult()} className="btn menu-icon vd_bd-green vd_green">Update Result</button>&nbsp;
                                  <a href={"/admin/appointment/details/edit/"+EditDetails.id} className="btn menu-icon vd_bd-blue vd_blue">Edit Information</a>
                                  {/* /admin/appointment/details/edit/:id */}
                                  {/* <div style={{width:"100%"}}>
                                    <SignaturePad ref={signPad} canvasProps={{width: 500, height: 200, backgroundColor:'rgba(0,0,0,0)', className: 'sigCanvas'}} />
                                  </div>
                                  <button onClick={()=>ClearSign()} >Clear</button>
                                  <button onClick={()=>saveSign()} >Capture</button> */}
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                    {/* row */} 
                  </div>
                  {/* .vd_content-section */} 
                </div>
                {/* .vd_content */} 
              </div>
              {/* .vd_container */} 
            </div>
            {/* .vd_content-wrapper */} 
            {/* Middle Content End */} 
          </div>
          {/* .container */} 
        </div>
        {/* .content */}
        {/* Footer Start */}
        {/* <footer className="footer-1" id="footer">      
          <div className="vd_bottom ">
            <div className="container">
              <div className="row">
                <div className=" col-xs-12">
                  <div className="copyright">
                    Copyright ©{Copyright} STGCInc
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
        {/* Footer END */}
      </div>
      {/* .vd_body END  */}
      <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
      {/*
    <a class="back-top" href="#" id="back-top"> <i class="icon-chevron-up icon-white"> </i> </a> */}
      {/* Javascript =============================================== */} 
      {/* Placed at the end of the document so the pages load faster */} 
      {/*[if lt IE 9]>
      
    <![endif]*/}
    </div>
    
  )
}

export default AdminEditEncodeResult
