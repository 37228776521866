import axios from 'axios'
// import QRCode from 'qrcode.react'
import QRCode from 'qrcode'
// import * as React from 'react';
import {React, useEffect, useMemo, useRef, useState } from 'react'
import Header from './Extra/Header'
import Navbar from './Extra/Navbar'
import image from './46964720_931525337041328_7708105959918272512_n.jpg';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Link, useHistory } from 'react-router-dom'
import './../App.css';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

import SignaturePad from 'react-signature-canvas'
import moment from 'moment'


import {
    Box,
    Stack, 
    Chip, 
    Radio, 
    RadioGroup, 
    FormControlLabel, 
    FormControl, 
    FormLabel, 
    TextField 
} from '@mui/material';
import swal from 'sweetalert'
import ENV from "./ENV";
import { format } from 'date-fns'
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';







const AdminEncodeResult = () => {
  const isPosition = sessionStorage.getItem("isPosition")
  let signPad = useRef();
  const history = useHistory()
  const {id} = useParams();

  if(isPosition === 'SECRETARY' || isPosition === 'SWABBER' || isPosition === 'BDC' || isPosition === 'INFO'){
    history.push('/admin/appointment')
  } else {

  }


  
  const ddate = new Date()
  const Copyright  = format(ddate, 'Y');



    const [EditDetails, setEditDetails] = useState('')

    const [status, setStatus] = useState('')
    const [OnDate, setOnDate] = useState('')
    const [OnTime, setOnTime] = useState('')
    // const [OnTemp, setTemp] = useState('')
    const [DataAccount, setDataAccount] = useState([])
    

    useEffect(()=>{
        DisplayData();
        MedicalDirectorDisplay();
        EncodedByDisplay()
        // VerifiedByDisplay()
        ValidatedByDisplay()
        PerformedByDisplay()
        DisplayAccount()
        MedicalDirectorData()
        VerifiedByData()
    },[])


    const [loopMedicalDirector, setloopMedicalDirector] = useState([])
    const MedicalDirectorDisplay = () => {
      axios.post(ENV.DOMAIN+'php/getAccounts.php?MedicalDirector')
      .then((res)=>{
          setloopMedicalDirector(res.data)
      }).catch((err)=>{
          console.log(err)
      })
    }
    const [loopEncodedBy, setloopEncodedBy] = useState([])
    const EncodedByDisplay = () => {
      axios.post(ENV.DOMAIN+'php/getAccounts.php?EncodedBy')
      .then((res)=>{
          setloopEncodedBy(res.data)
      }).catch((err)=>{
          console.log(err)
      })
    }
    // const [loopVerifiedBy, setloopVerifiedBy] = useState([])
    // const VerifiedByDisplay = () => {
    //   axios.post(ENV.DOMAIN+'php/getAccounts.php?VerifiedBy')
    //   .then((res)=>{
    //       setloopVerifiedBy(res.data)
    //   }).catch((err)=>{
    //       console.log(err)
    //   })
    // }
    const [loopValidatedBy, setloopValidatedBy] = useState([])
    const ValidatedByDisplay = () => {
      axios.post(ENV.DOMAIN+'php/getAccounts.php?ValidatedBy')
      .then((res)=>{
          setloopValidatedBy(res.data)
      }).catch((err)=>{
          console.log(err)
      })
    }
    const [loopPerformedBy, setloopPerformedBy] = useState([])
    const PerformedByDisplay = () => {
      axios.post(ENV.DOMAIN+'php/getAccounts.php?PerformedBy')
      .then((res)=>{
          setloopPerformedBy(res.data)
      }).catch((err)=>{
          console.log(err)
      })
    }
    
    const DisplayAccount = () => {
        axios.post(ENV.DOMAIN+'php/fetchAccounts.php')
        .then((res)=>{
            setDataAccount(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const DisplayData = () => {
        axios.get(ENV.DOMAIN+'php/getSwabbedPending.php?id='+id)
        .then((res)=>{
            setEditDetails(res.data[0])
            onPaLocBTN(res.data[0].purpose)
            setPaLocDesc(res.data[0].PurposeDescription)

            
            setTextOPD(res.data[0].OPD)
            setTextTRAVEL(res.data[0].TRAVEL)
            setTextCOMPANY(res.data[0].COMPANY)
            setTextINPATIENT(res.data[0].INPATIENT)
            // console.log(res.data[0].purpose)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const onBack = (value) => {
        history.push('/bqmadminappoint')
    }

    const [open, setOpen] = useState(false);
    const [ShowQRCode, setQRCode] = useState(false);
    const [base64, setBase64] = useState(false);

    // console.log(ShowQRCode)

    const onSaveResult = async () => {
      if(status!=''){
        QRCode.toDataURL('http://bqmhospital.com?qr=3123235423&auth=1&token='+Date.now())
        .then(async(data)  => {
          var formData = new FormData();
          formData.append('id', id);
          formData.append('timespan', Date.now());
          formData.append('qrcode', data);
          formData.append('status', status);
          formData.append('fullname', EditDetails.fullname);
          formData.append('contact', EditDetails.contact);
          formData.append('email', EditDetails.email);
          formData.append('address', EditDetails.address);
          formData.append('birthdate', EditDetails.birthdate);
          formData.append('date_sched', EditDetails.date_sched);
          formData.append('time_sched', EditDetails.time_sched);
          // formData.append('body_temp', OnTemp);
          await axios({
            method:'post',
            url:  ENV.DOMAIN+'php/saveResult.php',
            data: formData
          }).then((res)=>{
            if (res.data === 'success') {
              swal({
                title: "Done!",
                text: "Successfull Save",
                icon: "success",
                timer: 2000,
                button: false
              }).then(()=>{
                history.goBack()
              })
            }
          }).catch((err)=>{
              console.log(err)
          })
        })
        // setBase64('http://bqmhospital.com?qr=3123235423&auth=1&token='+Date.now())
      }
        // axios.get('http://bqmh.goldtigerclub.com/php/getAppointment.php?id='+id)
        // .then((res)=>{
        // }).catch((err)=>{
        //     console.log(err)
        // })
    }
    const dataDecease = [
        {
            "id" : 1,
            "name" : "Negative",
        },
        {
            "id" : 2,
            "name" : "Positive for Covid-19"
        },
        {
            "id" : 3,
            "name" : "Positive for Omicron"
        },
    ]

    // let signData = '';
    const [signData, setSignData] = useState();
    const ClearSign = () => {
      signPad.current.clear();
    }
    const saveSign = () => {
      const data = signPad.current.toDataURL();
      setSignData(data);
      signPad.current.clear();
    }
    const ShowSign = () => {
      signPad.current.toDataURL(signData);
    }

    const [TestCOV, setTestCOV] = useState('')
    const [ColorPosiCOV, setColorPosiCOV] = useState({color:"red"})
    const [ColorNegaCOV, setColorNegaCOV] = useState({color:"#000"})
    const [ColorInvaCOV, setColorInvaCOV] = useState({color:"#000"})
    const [ColorSelectedCOV, setColorSelectedCOV] = useState({color:"#000"})
    const setResultCOV = (ResultCOV) => {
      if(ResultCOV === 'POSITIVE FOR SARS-COV-2 RNA'){
        setColorPosiCOV({color:"red"});
        setColorSelectedCOV({color:"red"});
      } else {
        setColorNegaCOV({color:"#000"});
        setColorInvaCOV({color:"#000"});
        setColorSelectedCOV({color:"#000"});
      }
      setTestCOV(ResultCOV)
    }

    // const [ResultCOV, setResultCOV] = useState('')
    // const ddate = new Date()
    const MM = format(ddate, 'MM');
    const DD = format(ddate, 'dd');
    const YY = format(ddate, 'Y');
    const HH = format(ddate, 'HH');
    const MN = format(ddate, 'mm');

    const [Requisitioner, setRequisitioner] = useState('NONE');
    const [SpecimenType, setSpecimenType] = useState('OPS & NPS');
    const [AccessNo, setAccessNo] = useState(format(new Date(), 'MM-yy-'));
    const [LabProNo, setLabProNo] = useState('MOLLAB');
    const [PaLoc, setPaLoc] = useState(''); 
    const [PaLocDesc, setPaLocDesc] = useState(''); 
    const [Collection, setCollection] = useState(YY+'-'+MM+'-'+DD+'T'+HH+':'+MN); 
    const [SpecimenReceipt, setSpecimenReceipt] = useState(YY+'-'+MM+'-'+DD+'T'+HH+':'+MN); 
    const [ResultReleased, setResultReleased] = useState(''); 
    const [ResultForwarded, setResultForwarded] = useState(''); 
    const [MedicalDirectorId, setMedicalDirectorId] = useState(''); 
    const [MedicalDirector, setMedicalDirector] = useState(''); 
    const [VerifiedById, setVerifiedById] = useState(''); 
    const [VerifiedBy, setVerifiedBy] = useState(''); 
    const [EncodedBy, setEncodedBy] = useState(''); 
    const [PerformedBy, setPerformedBy] = useState(''); 
    const [PerformedByTwo, setPerformedByTwo] = useState(''); 
    const [PerformedByThree, setPerformedByThree] = useState(''); 
    const [ValidatedBy, setValidatedBy] = useState(''); 


    const [TextOPD, setTextOPD] = useState('');
    const [TextTRAVEL, setTextTRAVEL] = useState('');
    const [TextCOMPANY, setTextCOMPANY] = useState('');
    const [TextINPATIENT, setTextINPATIENT] = useState('');

    
    const [ErrorResultCOVColor, setErrorResultCOVColor] = useState({textAlign:"center", border:""}); 
    const [ErrorRequisitioner, setErrorRequisitioner] = useState({color:"#000"}); 
    const [ErrorSpecimenType, setErrorSpecimenType] = useState({color:"#000"}); 
    const [ErrorAccessNo, setErrorAccessNo] = useState({color:"#000"}); 
    const [ErrorLabProNo, setErrorLabProNo] = useState({color:"#000"}); 
    const [ErrorPaLoc, setErrorPaLoc] = useState({color:"#000"}); 
    const [ErrorCollection, setErrorCollection] = useState({color:"#000"}); 
    const [ErrorSpecimenReceipt, setErrorSpecimenReceipt] = useState({color:"#000"}); 
    const [ErrorResultReleased, setErrorResultReleased] = useState({color:"#000"}); 
    const [ErrorResultForwarded, setErrorResultForwarded] = useState({color:"#000"}); 
    const [ErrorMedicalDirector, setErrorMedicalDirector] = useState({width:300, textAlign:"center", color:"#000"}); 
    const [ErrorEncodedBy, setErrorEncodedBy] = useState({color:"#999"}); 
    const [ErrorPerformedBy, setErrorPerformedBy] = useState({color:"#999"}); 
    const [ErrorPerformedByTwo, setErrorPerformedByTwo] = useState({color:"#999"}); 
    const [ErrorPerformedByThree, setErrorPerformedByThree] = useState({color:"#999"}); 
    const [ErrorVerifiedBy, setErrorVerifiedBy] = useState({color:"#999"}); 
    const [ErrorValidatedBy, setErrorValidatedBy] = useState({color:"#999"}); 



    const [onPOSITIVERNA, setPOSITIVERNA] = useState(false);
    const [onNEGATIVERNA, setNEGATIVERNA] = useState(false);
    const [onPENDINGRNA, setPENDINGRNA] = useState(false);

    const [onOPD, setOPD] = useState(false);
    const [onTRAVEL, setTRAVEL] = useState(false);
    const [onCOMPANY, setCOMPANY] = useState(false);
    const [onINPATIENT, setINPATIENT] = useState(false);




    const [TextAreaPaLoc, setTextAreaPaLoc] = useState(false); 
    // console.log(EditDetails.id)
    const [ErrorTextOPD, setErrorTextOPD] = useState({height: 38, width:361, border: '1px solid #d5d5d5' }); 
    const [ErrorTextTRAVEL, setErrorTextTRAVEL] = useState({height: 38, width:361, border: '1px solid #d5d5d5' }); 
    const [ErrorTextCOMPANY, setErrorTextCOMPANY] = useState({height: 38, width:361, border: '1px solid #d5d5d5' }); 
    const [ErrorTextINPATIENT, setErrorTextINPATIENT] = useState({height: 38, width:361, border: '1px solid #d5d5d5' }); 


    const SaveResult = async () =>{
      var formData = new FormData();
      formData.append('id', EditDetails.id);
      formData.append('resid', EditDetails.resid);
      formData.append('Requisitioner', Requisitioner)
      formData.append('SpecimenType', SpecimenType)
      formData.append('AccessNo', AccessNo)
      formData.append('LabProNo', LabProNo)
      formData.append('TextOPD', TextOPD)
      formData.append('TextTRAVEL', TextTRAVEL)
      formData.append('TextCOMPANY', TextCOMPANY)
      formData.append('TextINPATIENT', TextINPATIENT)
      formData.append('PaLoc', PaLoc)
      formData.append('PaLocDesc', PaLocDesc)
      formData.append('Collection', Collection)
      formData.append('SpecimenReceipt', SpecimenReceipt)
      formData.append('ResultReleased', ResultReleased)
      formData.append('ResultForwarded', ResultForwarded)
      formData.append('MedicalDirector', MedicalDirectorId)
      formData.append('EncodedBy', EncodedBy)
      formData.append('PerformedBy', PerformedBy ? PerformedBy : 'NONE')
      formData.append('PerformedByTwo', PerformedByTwo ? PerformedByTwo : 'NONE')
      formData.append('PerformedByThree', PerformedByThree ? PerformedByThree : 'NONE')
      formData.append('VerifiedBy', VerifiedById)
      formData.append('ValidatedBy', ValidatedBy)
      formData.append('TestCOV', TestCOV)

      if(PaLoc == 'OPD')
      {
        var toProposeDesc = true;
        // if(TextOPD != ''){
        //   var toProposeDesc = true;
        //   setErrorTextOPD({height: 38, width:361, border: '1px solid #d5d5d5' })
        // } else {
        //   var toProposeDesc = false;
        //   setErrorTextOPD({height: 38, width:361, border: '1px solid red' })
        // }
      } else if(PaLoc == 'TRAVEL')
      {
        if(TextTRAVEL != ''){
          var toProposeDesc = true;
          setErrorTextTRAVEL({height: 38, width:361, border: '1px solid #d5d5d5' })
        } else {
          var toProposeDesc = true;
          // var toProposeDesc = false;
          setErrorTextTRAVEL({height: 38, width:361, border: '1px solid #d5d5d5' })
          // setErrorTextTRAVEL({height: 38, width:361, border: '1px solid red' })
        }
      } else if(PaLoc == 'COMPANY')
      {
        if(TextCOMPANY != ''){
          var toProposeDesc = true;
          setErrorTextCOMPANY({height: 38, width:361, border: '1px solid #d5d5d5' })
        } else {
          var toProposeDesc = true;
          // var toProposeDesc = false;
          setErrorTextCOMPANY({height: 38, width:361, border: '1px solid #d5d5d5' })
          // setErrorTextCOMPANY({height: 38, width:361, border: '1px solid red' })
        }
      } else if(PaLoc == 'INPATIENT')
      {
        if(TextINPATIENT != ''){
          var toProposeDesc = true;
          setErrorTextINPATIENT({height: 38, width:361, border: '1px solid #d5d5d5' })
        } else {
          var toProposeDesc = true;
          // var toProposeDesc = false;
          setErrorTextINPATIENT({height: 38, width:361, border: '1px solid #d5d5d5' })
          // setErrorTextINPATIENT({height: 38, width:361, border: '1px solid red' })
        }
      }




      if(Requisitioner!='' && SpecimenType!='' && AccessNo!='' 
      && LabProNo!='' && Collection!='' 
      && SpecimenReceipt!='' && TestCOV!='' 
      && MedicalDirectorId!='' && EncodedBy!='' && VerifiedBy!='' && ValidatedBy!='' && toProposeDesc){
        

        await axios({
          method:'post',
          url:ENV.DOMAIN+'php/saveResult.php',
          data:formData
        })
        .then((res)=>{
          if (res.data === 'success') {
            swal({
                title: "",
                text: "Successfuly Saved",
                icon: "success",
                timer: 2000,
                button: false,
            }).then(() => {
              history.push('/admin/pending/result');
            });
          } else {

          }
          // console.log(res.data)
        }).catch((err)=>{
          console.log(err)
        })
      } else {
        if(Requisitioner ===''){
          setErrorRequisitioner({color:"red"})
        }
        if(SpecimenType ===''){
          setErrorSpecimenType({color:"red"})
        }
        if(AccessNo ===''){
          setErrorAccessNo({color:"red"})
        }
        if(LabProNo ===''){
          setErrorLabProNo({color:"red"})
        }
        if(PaLoc ===''){
          setErrorPaLoc({color:"red"})
        }
        if(Collection ===''){
          setErrorCollection({color:"red"})
        }
        if(SpecimenReceipt ===''){
          setErrorSpecimenReceipt({color:"red"})
        }
        if(MedicalDirector ===''){
          setErrorMedicalDirector({color:"red"})
        }
        if(EncodedBy ===''){
          setErrorEncodedBy({color:"red"})
        }
        if(VerifiedBy ===''){
          setErrorVerifiedBy({color:"red"})
        }
        if(ValidatedBy ===''){
          setErrorValidatedBy({color:"red"})
        }
        if(TestCOV ===''){
          setErrorResultCOVColor({textAlign:"center", border:"1px solid red"})
        }
        swal({
            title: "",
            text: "Incomplete",
            icon: "error",
            timer: 2000,
            button: false,
        });
      }

    }

    const paclocDisplay = () => {
      const PaLocDiy = <div>[<input type="radio" name="PLocation" onChange={()=> setPaLoc('OPD')} />] <b>OPD -</b></div>;
      if(PaLocDiy === 'OPD') {
        PaLocDiy += <div>[<input type="radio" name="PLocation" onChange={()=> setPaLoc('OPD')} selected="" />] <b>OPD -</b></div>;
      }


      return (
        <>
          <div className='col-sm-6'>
            {/* {PaLocDiy()} */}
            <div>[<input type="radio" name="PLocation" onChange={()=> setPaLoc('REFERRAL')} />] <b>REFERRAL -</b></div>
          </div>
          <div className='col-sm-6'>
            <div>[<input type="radio" name="PLocation" onChange={()=> setPaLoc('COMPANY')} />] <b>COMPANY -</b></div>
            <div>[<input type="radio" name="PLocation" onChange={()=> setPaLoc('INPATIENT')} />] <b>INPATIENT -</b></div>
          </div>
        </>
      )
    }

    const SuffixDisplay = (value) => {
      if(value != 'None') {
        return ', '+EditDetails.suffix;
      } else {
        return '';
      }
    }



    const onSpecimenType = (value) => {
      if(value != '')
      {
        setSpecimenType(value)
        setErrorSpecimenType({color:"#000"})
      } else {
        setSpecimenType('')
        setErrorSpecimenType({color:"red"})
      }
    }
    const onRequisitioner = (value) => {
      if(value != '')
      {
        setRequisitioner(value)
        setErrorRequisitioner({color:"#000"})
      } else {
        setRequisitioner('')
        setErrorRequisitioner({color:"red"})
      }
    }
    const onAccessNo = (value) => {
      if(value != '')
      {
        setAccessNo(value)
        setErrorAccessNo({color:"#000"})
      } else {
        setAccessNo('')
        setErrorAccessNo({color:"red"})
      }
    }
    const onLabProNo = (value) => {
      if(value != '')
      {
        setLabProNo(value)
        setErrorLabProNo({color:"#000"})
      } else {
        setLabProNo('')
        setErrorLabProNo({color:"red"})
      }
    }
    const onPaLoc = (value) => {
      if(value != '')
      {
        setPaLoc(value)
        setErrorPaLoc({color:"#000"})
      } else {
        setPaLoc('')
        setErrorPaLoc({color:"red"})
      }
      setTextAreaPaLoc(true)
    }
    const onCollection = (value) => {
      if(value != '')
      {
        setCollection(value)
        setErrorCollection({color:"#000"})
      } else {
        setCollection('')
        setErrorCollection({color:"red"})
      }
    }
    const onSpecimenReceipt = (value) => {
      if(value != '')
      {
        setSpecimenReceipt(value)
        setErrorSpecimenReceipt({color:"#000"})
      } else {
        setSpecimenReceipt('')
        setErrorSpecimenReceipt({color:"red"})
      }
    }
    const onResultReleased = (value) => {
      if(value != '')
      {
        setResultReleased(value)
        // setErrorResultReleased({color:"#000"})
      } else {
        setResultReleased('')
        // setErrorResultReleased({color:"red"})
      }
    }
    const onResultForwarded = (value) => {
      if(value != '')
      {
        setResultForwarded(value)
        // setErrorResultForwarded({color:"#000"})
      } else {
        setResultForwarded('')
        // setErrorResultForwarded({color:"red"})
      }
    }
    const onMedicalDirector = (value) => {
      if(value != '')
      {
        setMedicalDirectorId(value)
        setErrorMedicalDirector({width:300, textAlign:"center", color:"#000"})
      } else {
        setMedicalDirectorId('')
        setErrorMedicalDirector({width:300, textAlign:"center", color:"red"})
      }
    }

    const onEncodedBy = (value) => {
      if(value != '')
      {
        setEncodedBy(value)
      }
    }
    const onPerformedBy = (value) => {
      if(value != '')
      {
        setPerformedBy(value)
      }
    }
    const onPerformedByTwo = (value) => {
      if(value != '')
      {
        setPerformedByTwo(value)
      }
    }
    const onPerformedByThree = (value) => {
      if(value != '')
      {
        setPerformedByThree(value)
      }
    }
    const onVerifiedBy = (value) => {
      if(value != '')
      {
        setVerifiedBy(value)
      }
    }
    const onValidatedBy = (value) => {
      if(value != '')
      {
        setValidatedBy(value)
      }
    }
    
    
    
    const ShowPositiveRes = (value) => {
      if (value === 'POSITIVE FOR SARS-COV-2 RNA') {
        return (<div style={{ color: "red" }}>POSITIVE FOR SARS-COV-2 RNA</div>)
      } else if (value === 'NEGATIVE FOR SARS-COV-2 RNA') {
        return (<div>NEGATIVE FOR SARS-COV-2 RNA</div>)
      } else {
        return (<div>INVALID</div>)
      }
    }
    


    const onPaLocBTN = (value) => {
      if(value === 'OPD') {
        setOPD(true)
        setTRAVEL(false)
        setCOMPANY(false)
        setINPATIENT(false)
        setTextAreaPaLoc(true)
      } else 
      if(value === 'TRAVEL') {
        setOPD(false)
        setTRAVEL(true)
        setCOMPANY(false)
        setINPATIENT(false)
        setTextAreaPaLoc(true)
      } else 
      if(value === 'COMPANY') {
        setOPD(false)
        setTRAVEL(false)
        setCOMPANY(true)
        setINPATIENT(false)
        setTextAreaPaLoc(true)
      } else 
      if(value === 'INPATIENT') {
        setOPD(false)
        setTRAVEL(false)
        setCOMPANY(false)
        setINPATIENT(true)
        setTextAreaPaLoc(true)
      }
      setPaLoc(value)
    }



    const onResultCOVBTN = (value) => {
      setResultCOV(value)
      // console.log(value)
      if(value === 'POSITIVE FOR SARS-COV-2 RNA') {
        setPOSITIVERNA(true)
        setNEGATIVERNA(false)
        setPENDINGRNA(false)
      } else 
      if(value === 'NEGATIVE FOR SARS-COV-2 RNA') {
        setPOSITIVERNA(false)
        setNEGATIVERNA(true)
        setPENDINGRNA(false)
      } else
      if(value === 'PENDING') {
        setPOSITIVERNA(false)
        setNEGATIVERNA(false)
        setPENDINGRNA(true)
      }
    }
    


    // const { height, width } = window;
    console.log(window.innerWidth, window.innerHeight)
    const AutoSizeWidthRight = () => {
      if(window.innerWidth == '1280'){
        return 'col-sm-1'
      } else if(window.innerWidth == '1366'){
        return 'col-sm-2'
      } else {
        return 'col-sm-3'
      }
    }
    const AutoSizeWidthCenter = () => {
      if(window.innerWidth == '1280'){
        return 'col-sm-10'
      } else if(window.innerWidth == '1366'){
        return 'col-sm-8'
      } else {
        return 'col-sm-6'
      }
    }




    const patientLocation = (value, desc) => {
      const date = desc;
      const dateFormat = 'DD-MM-YYYY';
      const toDateFormat = moment(new Date(date)).format(dateFormat);
      // alert(moment(toDateFormat, dateFormat, true).isValid());
      // let verify = true;
      if(moment(toDateFormat, dateFormat, true).isValid()){
        var verify = true;
      } else {
        var verify = false;
      }
      
      if(value === 'OPD') {
        return <div class="col-xs-12">
                <input type="text" onChange={(e)=>setTextOPD(e.target.value)}  placeholder="OPD" value={TextOPD} style={ErrorTextOPD} /> 
                <br/>
                <span class="help-inline" style={{color: "rgb(0, 0, 0)"}}>OPD - Purpose</span>
              </div>;
      } else if(value === 'TRAVEL') {
        return <div class="col-xs-12">
                <input type="datetime-local" onChange={(e)=>setTextTRAVEL(e.target.value)}  placeholder="TRAVEL" value={TextTRAVEL} style={ErrorTextTRAVEL} /> 
                <br/>
                <span class="help-inline" style={{color: "rgb(0, 0, 0)"}}>TRAVEL - Purpose</span>
              </div>;
      } else if(value === 'COMPANY') {
        return <div class="col-xs-12">
                <input type="text" onChange={(e)=>setTextCOMPANY(e.target.value)}  placeholder="COMPANY" value={TextCOMPANY} style={ErrorTextCOMPANY} /> 
                <br/>
                <span class="help-inline" style={{color: "rgb(0, 0, 0)"}}>COMPANY - Purpose</span>
              </div>;
      } else if(value === 'INPATIENT') {
        return <div class="col-xs-12">
                <input type="text" onChange={(e)=>setTextINPATIENT(e.target.value)}  placeholder="INPATIENT" value={TextINPATIENT} style={ErrorTextINPATIENT} /> 
                  <br/>
                  <span class="help-inline" style={{color: "rgb(0, 0, 0)"}}>INPATIENT - Purpose</span>
              </div>;
      }
    }




    // ////////////////////////
    const MedicalDirectorData = () => {
      axios.get(ENV.DOMAIN+'php/getAccounts.php?MedicalDirector')
      .then((res)=>{
        setMedicalDirectorId(res.data[0].id)
        setMedicalDirector(res.data[0].fullname)
      }).catch((err)=>{
          console.log(err)
      })
    }
    const VerifiedByData = () => {
      axios.get(ENV.DOMAIN+'php/getAccounts.php?VerifiedBy')
      .then((res)=>{
        setVerifiedById(res.data[0].id)
        setVerifiedBy(res.data[0].fullname)
      }).catch((err)=>{
          console.log(err)
      })
    }




  return (
    <div id="pages" className="full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975" data-active="pages " data-smooth-scrolling={1}>     
      <div className="vd_body">
        {/* Header Start */}
        <Header />
        {/* Header Ends */} 
        <div className="content">
          <div className="container">
            <Navbar isPosition={isPosition} />    
              
            {/* Middle Content Start */}
            <div className="vd_content-wrapper" style={{minHeight: 1059}}>
              <div className="vd_container" style={{minheight: 1059}}>
                <div className="vd_content clearfix">
                  <div className="vd_head-section clearfix">
                    <div className="vd_panel-header">
                      
                    </div>
                  </div>
                  <div className="vd_title-section clearfix">
                    <div className="vd_panel-header no-subtitle">
                        <h1>Encode {EditDetails.fullname ? '/ '+EditDetails.fullname : ''}</h1>
                    </div>
                  </div>
                  <div className="vd_content-section clearfix">
                    <div className="row"> 
                        {/* <div className='col-sm-4'></div> */}
                        {/* <div className='col-sm-4'></div> */}
                        <div className='col-sm-3'></div>
                        <div className='col-sm-6'>
                          <div className="vd_register-page">
                              <div className="panel widget" style={{minHeight:500}}>
                                  <div className="panel-body" style={{borderRadius:10, padding: 0, paddingTop:15}}>
                                  <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="text" onChange={(e)=>onRequisitioner(e.target.value)} value={Requisitioner}  placeholder="" />
                                            <span style={ErrorRequisitioner}className="help-inline">Requisitioner</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="text" onChange={(e)=>onSpecimenType(e.target.value)} value={SpecimenType} placeholder="" />
                                            <span style={ErrorSpecimenType} className="help-inline">Specimen Type</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="text" onChange={(e)=>onAccessNo(e.target.value)} value={AccessNo} placeholder="" />
                                            <span style={ErrorAccessNo} className="help-inline">Accession No.</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="text" onChange={(e)=>onLabProNo(e.target.value)} value={LabProNo ? LabProNo : EditDetails.LabProNo} placeholder="" />
                                            <span style={ErrorLabProNo} className="help-inline">Laboratory Processing No.</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className='col-sm-6'>
                                        <div onClick={()=> onPaLocBTN('OPD')} style={ErrorPaLoc}>
                                          <label>
                                            {
                                              onOPD ?
                                                <CircleIcon />
                                              :
                                                <RadioButtonUncheckedIcon />
                                            } <b>OPD</b>
                                          </label>
                                        </div>
                                        <div onClick={()=> onPaLocBTN('TRAVEL')} style={ErrorPaLoc}>
                                          <label>
                                            {
                                              onTRAVEL ?
                                                <CircleIcon />
                                              :
                                                <RadioButtonUncheckedIcon />
                                            } <b>TRAVEL</b>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-6'>
                                        <div onClick={()=> onPaLocBTN('COMPANY')} style={ErrorPaLoc}>
                                          <label>
                                            {
                                              onCOMPANY ?
                                                <CircleIcon />
                                              :
                                                <RadioButtonUncheckedIcon />
                                            } <b>COMPANY</b>
                                          </label>
                                        </div>
                                        <div onClick={()=> onPaLocBTN('INPATIENT')} style={ErrorPaLoc}>
                                          <label>
                                            {
                                              onINPATIENT ?
                                                <CircleIcon />
                                              :
                                                <RadioButtonUncheckedIcon />
                                            } <b>INPATIENT</b>
                                          </label>
                                        </div>
                                      </div>
                                      
                                      {
                                        patientLocation(PaLoc, PaLocDesc)
                                      }
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="datetime-local" onChange={(e)=>onCollection(e.target.value)} value={Collection ? Collection : EditDetails.Collection} placeholder="" style={{height:38, textTransform:"uppercase"}} />
                                            <span style={ErrorCollection} className="help-inline">Date and Time of Collection (MM/DD/YYYY Hr:Min)</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="datetime-local" onChange={(e)=>onSpecimenReceipt(e.target.value)} value={SpecimenReceipt ? SpecimenReceipt : EditDetails.SpecimenReceipt} placeholder="" style={{height:38, textTransform:"uppercase"}} />
                                            <span style={ErrorSpecimenReceipt} className="help-inline">Date and Time Specimen Receipt (MM/DD/YYYY Hr:Min)</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="datetime-local" onChange={(e)=>onResultReleased(e.target.value)} value={ResultReleased ? ResultReleased : EditDetails.ResultReleased} placeholder="" style={{height:38, textTransform:"uppercase"}} />
                                            <span style={ErrorResultReleased} className="help-inline">Date and Time Result Released (MM/DD/YYYY Hr:Min)</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <input type="datetime-local" onChange={(e)=>onResultForwarded(e.target.value)} value={ResultForwarded ? ResultForwarded : EditDetails.ResultForwarded} placeholder="" style={{height:38, textTransform:"uppercase"}} />
                                            <span style={ErrorResultForwarded} className="help-inline">Date and Time Result Forwarded (MM/DD/YYYY Hr:Min)</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-xs-12">
                                        <div className=' well' style={ErrorResultCOVColor}>
                                          <div style={{textAlign:"center", fontFamily:"timesneroman"}}>
                                            <b>SARS-COV2 RT-PCR TEST RESULT</b>
                                          </div>

                                          <div className='row' style={{padding:5, marginBottom:0}}>
                                            <div className='col-sm-4'>
                                              <div style={ErrorPaLoc} onClick={()=> onResultCOVBTN('POSITIVE FOR SARS-COV-2 RNA')}>
                                                <label>
                                                  

                                                {
                                                    onPOSITIVERNA ?           
                                                      <Button variant="contained" size="large" color="error" style={{color:"white"}}>
                                                        POSITIVE SARS-COV-2 RNA
                                                      </Button>
                                                    :          
                                                    <Button style={{color:"red"}}>
                                                      POSITIVE SARS-COV-2 RNA
                                                    </Button>
                                                  } 
                                                </label>
                                              </div>
                                            </div>
                                            <div className='col-sm-4'>
                                              <div onClick={()=> onResultCOVBTN('NEGATIVE FOR SARS-COV-2 RNA')}>
                                                <label>

                                                  {
                                                    onNEGATIVERNA ?          
                                                    <Button variant="contained" size="large" color="success">
                                                      NEGATIVE SARS-COV-2 RNA
                                                    </Button>
                                                    :
                                                    <Button>
                                                      NEGATIVE SARS-COV-2 RNA
                                                    </Button>
                                                  } 
                                                </label>
                                              </div>
                                            </div>
                                            <div className='col-sm-4'>
                                              <div onClick={()=> onResultCOVBTN('PENDING')}>
                                                <label>

                                                  {
                                                    onPENDINGRNA ?         
                                                    <Button variant="contained" size="large">
                                                      PENDING
                                                    </Button>
                                                    :
                                                    <Button>
                                                      PENDING
                                                    </Button>
                                                  } 
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              {MedicalDirector}
                                              {/* <select onChange={(e)=>onMedicalDirector(e.target.value)} value={MedicalDirectorId} style={{height:38}}>
                                                <option value="" selected disabled>Select...</option>
                                                {
                                                  loopMedicalDirector.map(({id, fullname})=>{
                                                    return (<option value={id}>{fullname}</option>)
                                                  })
                                                }
                                              </select> */}
                                            </div>
                                            <span className="help-inline">Medical Director's Office</span>
                                            {/* <div style={ErrorMedicalDirector}>Medical Director's Office</div> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">                                          
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              <select  onChange={(e)=>onEncodedBy(e.target.value)} value={EncodedBy ? EncodedBy : EditDetails.EncodedBy} style={{height:38}}>
                                                <option value="" selected disabled>Select...</option>
                                                {
                                                  loopEncodedBy.map(({id, fullname})=>{
                                                    return (<option value={id}>{fullname}</option>)
                                                  })
                                                }
                                              </select>
                                            </div>
                                            <span style={ErrorEncodedBy} className="help-inline">Encoded By</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              {VerifiedBy}
                                            </div>
                                            <span className="help-inline">Verified By</span>
                                            {/* <div style={ErrorMedicalDirector}>Medical Director's Office</div> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              <select onChange={(e)=>onValidatedBy(e.target.value)} value={ValidatedBy ? ValidatedBy : EditDetails.ValidatedBy} style={{height:38}}>
                                                <option value="" selected disabled>Select...</option>
                                                {
                                                  loopValidatedBy.map(({id, fullname})=>{
                                                    return (<option value={id}>{fullname}</option>)
                                                  })
                                                }
                                              </select>
                                            </div>
                                            <span style={ErrorValidatedBy} className="help-inline">Validated By</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              <select onChange={(e)=>onPerformedBy(e.target.value)} value={PerformedBy ? PerformedBy : EditDetails.PerformedBy} style={{height:38}}>
                                                <option value="" selected disabled>Select...</option>
                                                <option value={0}>---NONE---</option>
                                                {
                                                  loopPerformedBy.map(({id, fullname})=>{
                                                    return (<option value={id}>{fullname}</option>)
                                                  })
                                                }
                                              </select>
                                            </div>
                                            <span style={ErrorPerformedBy} className="help-inline">1. Performed By</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              <select onChange={(e)=>onPerformedByTwo(e.target.value)} value={PerformedByTwo ? PerformedByTwo : EditDetails.PerformedByTwo} style={{height:38}}>
                                                <option value="" selected disabled>Select...</option>
                                                <option value={0}>---NONE---</option>
                                                {
                                                  loopPerformedBy.map(({id, fullname})=>{
                                                    return (<option value={id}>{fullname}</option>)
                                                  })
                                                }
                                              </select>
                                            </div>
                                            <span style={ErrorPerformedByTwo} className="help-inline">2. Performed By</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        
                                      </div>
                                    </div>
                                    
                                    <div className="form-group">
                                      <div className="col-sm-6 controls">
                                        <div className="row mgbt-xs-0">
                                          <div className="col-xs-12">
                                            <div style={{paddingTop:20, width:'100%'}}>
                                              <select onChange={(e)=>onPerformedByThree(e.target.value)} value={PerformedByThree ? PerformedByThree : EditDetails.PerformedByThree} style={{height:38}}>
                                                <option value="" selected disabled>Select...</option>
                                                <option value={0}>---NONE---</option>
                                                {
                                                  loopPerformedBy.map(({id, fullname})=>{
                                                    return (<option value={id}>{fullname}</option>)
                                                  })
                                                }
                                              </select>
                                            </div>
                                            <span style={ErrorPerformedByThree} className="help-inline">3. Performed By</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 controls">
                                        
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <div className="panel widget" style={{height:700}}>
                                <div class="panel-body" style={{borderRadius:10}}>
                                  <a href={"/admin/pending/result"} className="btn menu-icon vd_bd-red vd_red">Back</a>&nbsp;
                                  <button onClick={()=>SaveResult()} className="btn menu-icon vd_bd-green vd_green">Save Result</button>&nbsp;
                                  <a href={"/admin/appointment/details/edit/"+EditDetails.resid} className="btn menu-icon vd_bd-blue vd_blue">Edit Information</a>
                                  {/* /admin/appointment/details/edit/:id */}
                                  {/* <div style={{width:"100%"}}>
                                    <SignaturePad ref={signPad} canvasProps={{width: 500, height: 200, backgroundColor:'rgba(0,0,0,0)', className: 'sigCanvas'}} />
                                  </div>
                                  <button onClick={()=>ClearSign()} >Clear</button>
                                  <button onClick={()=>saveSign()} >Capture</button> */}
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                    {/* row */} 
                  </div>
                  {/* .vd_content-section */} 
                </div>
                {/* .vd_content */} 
              </div>
              {/* .vd_container */} 
            </div>
            {/* .vd_content-wrapper */} 
            {/* Middle Content End */} 
          </div>
          {/* .container */} 
        </div>
        {/* .content */}
        {/* Footer Start */}
        {/* <footer className="footer-1" id="footer">      
          <div className="vd_bottom ">
            <div className="container">
              <div className="row">
                <div className=" col-xs-12">
                  <div className="copyright">
                    Copyright ©{Copyright} STGCInc
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
        {/* Footer END */}
      </div>
      {/* .vd_body END  */}
      <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
      {/*
    <a class="back-top" href="#" id="back-top"> <i class="icon-chevron-up icon-white"> </i> </a> */}
      {/* Javascript =============================================== */} 
      {/* Placed at the end of the document so the pages load faster */} 
      {/*[if lt IE 9]>
      
    <![endif]*/}
    </div>
    
  )
}

export default AdminEncodeResult
