import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';  
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ENV from '../ENV';
import axios from "axios";
import swal from 'sweetalert';
import { format } from 'date-fns'
import { styled, Box, borderRadius } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';




const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 300,
  height: 200,
  bgcolor: '#fff',
  // border: '2px solid #000',
  p: 1,
  // px: 4,
  // pb: 3,
};

const ReservationTable = ({isPosition, isFullname, isUsername, HeaderTable, tableList,  setSearch, onView, onEdit, onDelete, search, onSorting, ShowAllData, Today, LastDayData}) => {

    const [FromDate, setFromDate] = useState(''); //format(new Date(), 'yyyy-mm-dd')
    const [ToDate, setToDate] = useState('');

    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc");

    const onSortingChange = (field) => {
        const order =  field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc';
        setSortingField(field)
        setSortingOrder(order)
        onSorting(field, order)
    }

    const actionBTN = (item) => {
        if (isPosition === 'ADMIN') {
            return (
                <td className="menu-action">
                    <a href={"/admin/appointment/details/"+item.id} data-original-title="view" data-toggle="tooltip" data-placement="top" class="btn menu-icon vd_bd-grey vd_grey"> View </a>
                    <a onClick={()=>onDelete(item)} data-original-title="Cancel" data-toggle="tooltip" data-placement="top" class="btn menu-icon  vd_bd-red vd_red"> Cancel </a>
                </td>
            )
        } else {
            if(isPosition!='BDC' && isPosition!='INFO' && isPosition!='SWABBER'){
                return (
                    <td className="menu-action">
                        <a href={"/admin/appointment/details/"+item.id} data-original-title="view" data-toggle="tooltip" data-placement="top" class="btn menu-icon vd_bd-grey vd_grey"> View </a>
                    </td>
                )
            } else {
                return '';
            }
        }
    }
    

    const [SwabberName,  setSwabberName] = useState('');
    const SwabberGenerate = (e) => {
        e.preventDefault();
        swal({
            title: "",
            text: "Generating Excel",
            icon: "success",
            timer: 2000,
            button: false,
        }).then(() => {
            handleClose()
            window.location.assign(ENV.DOMAIN+"php/exportExcelApp.php?export&FromDate="+FromDate+"&ToDate="+ToDate+"&GeneratedBy="+SwabberName+"&isPosition="+isPosition);
        });  
    }

    const ExportExcel = async () => {
        if (FromDate!=''&& ToDate!='') {
            var formData = new FormData();
            formData.append('FromDate', FromDate);
            formData.append('ToDate', ToDate);
            await axios({
                method:'post',
                url:ENV.DOMAIN+'php/dateRangeVerification.php',
                data: formData
            })
            .then(async(res)=>{
                var formDatas = new FormData();
                formDatas.append('FromDate', FromDate);
                formDatas.append('ToDate', ToDate);
                if(res.data == 'valid'){  
                    await axios({
                        method:'post',
                        url:ENV.DOMAIN+'php/exportExcelVerification.php',
                        data: formDatas
                    })
                    .then((ress)=>{
                        if(ress.data == 'success'){
                            if(isPosition=='SWABBER'){
                                handleOpen()
                            } else {
                                swal({
                                    title: "",
                                    text: "Generating Excel",
                                    icon: "success",
                                    timer: 2000,
                                    button: false,
                                }).then(() => {
                                    window.location.assign(ENV.DOMAIN+"php/exportExcelApp.php?export&FromDate="+FromDate+"&ToDate="+ToDate+"&GeneratedBy="+isFullname+"&isPosition="+isPosition);
                                });  
                            } 
                        } else if(ress.data == 'no data'){
                            swal({
                                text: "Data Excel Exporting no record! ",
                                icon: "error",
                                timer: 3000,
                                button: false,
                            }).then(() => {
                                setFromDate('')
                                setToDate('')
                            });
                        }
                    });         
                } else {
                    swal({
                        title: "Export Date Invalid",
                        text: "Invalid",
                        icon: "error",
                        timer: 2000,
                        button: false,
                    }).then(() => {
                        setFromDate('')
                        setToDate('')
                    });
                }
            }).catch((err)=>{
                
            })
        } else {
            swal({
                title: "",
                text: "Please Select Date",
                icon: "error",
                timer: 2000,
                button: false,
            }).then(() => {

            });
        }





        // if (FromDate!=''&& ToDate!='') {
        //     window.location.assign(ENV.DOMAIN+"php/exportExcelApp.php?export&FromDate="+FromDate+"&ToDate="+ToDate);
        // } else {
        //     swal({
        //         title: "",
        //         text: "Unsuccessfully",
        //         icon: "error",
        //         timer: 2000,
        //         button: false,
        //     }).then(() => {

        //     });
        // }



        // href={ENV.DOMAIN+"php/exportExcelApp.php?export&FromDate="+FromDate+"&ToDate="+ToDate}
    }
    // window.location.assign("/teste"); 
    const pad = (number, length) => {
        var str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    }

    const disabledDates = () => {
        var today, dd, mm, yyyy;
        today = new Date();
        dd=pad(today.getDate()+1, 2);
        mm=pad(today.getMonth()+1, 2);
        yyyy=today.getFullYear();
        return yyyy+'-'+mm+'-'+dd;
    }

    // console.log(disabledDates())




    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const DateRangeExport = (isPosition) => {
        if(isPosition!='BDC' && isPosition!='INFO'){
            return (
                <div className="col-sm-6">
                    <span>From: <input type="date" onChange={(e)=>setFromDate(e.target.value)} value={FromDate} placeholder='From' style={{height:29, borderRadius:5, borderWidth:1, borderColor:"#c1c1c1", outline:"none", width:185, marginLeft:5}}/></span>
                    <span style={{marginLeft:5}}> To: <input type="date" onChange={(e)=>setToDate(e.target.value)} value={ToDate} placeholder='To' style={{height:29, borderRadius:5, borderWidth:1, borderColor:"#c1c1c1", outline:"none", width:185, marginLeft:5}}/></span>
                    <a style={{marginLeft:5}} onClick={ExportExcel} className="btn btn-info btn-xs">Export excel</a>
                </div>
            )
        } else {
            return null;
        }
    }

    var num = 1;
    
  return (
    <div>
        <div style={{padding:10, flexDirection:"row"}}>


                <StyledModal
                    aria-labelledby="unstyled-modal-title"
                    aria-describedby="unstyled-modal-description"
                    open={open}
                    onClose={handleClose}
                    BackdropComponent={Backdrop}
                >
                    <Box sx={style}>
                        <form className="form-horizontal" onSubmit={SwabberGenerate} id="register-form">
                            <div className="form-group">
                                <div className="col-md-12 text-center">
                                    <label>Enter Name</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-12">
                                    <input type={"text"} onChange={(e)=>setSwabberName(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-12">
                                    <button type={"submit"} className="btn btn-primary btn-block" >Generate</button>
                                </div>
                            </div>
                        </form>
                    </Box>
              </StyledModal>
            <div className="col-sm-2">
                <b>Search:</b> 
                <input onChange={(e)=>{
                setSearch(e.target.value)
                }}
                value={search}
                style={{borderRadius:5, borderWidth:1, borderColor:"#c1c1c1", outline:"none", width:185}} />
            </div>
              <div className="col-sm-6">
                  <div className="form-group">
                      <button className="btn btn-info btn-xs" onClick={()=>ShowAllData()} >Show All</button> &nbsp;
                      <button onClick={()=>Today()} className="btn btn-info btn-xs" >Today</button> &nbsp;
                      <button onClick={()=>LastDayData()} className="btn btn-info btn-xs" >Show Last 3Day/s</button>
                  </div>
              </div>
            {DateRangeExport(isPosition)}

        </div>
        <div className=' table-responsive'>
            <table id="emp" className='table table-hover dataTable no-footer'>
                <thead>
                    <tr>
                        {
                            HeaderTable.map(({name, width, field, sortable})=>{
                                return(
                                    <th style={{width:width, cursor:"pointer"}} onClick={()=>sortable ? onSortingChange(field) : null}>
                                        {name}
                                        {
                                            sortingField && sortingField === field && (
                                                <>{sortingOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</>
                                            )
                                        }
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        tableList.map((item)=>{
                            return(
                                // onClick={(e)=>alert(item.resnumber)} style={{cursor:"pointer"}}
                            <tr>
                                <td>{num++}</td>
                                <td style={{width:100, textAlign:"left"}}>{item.resnumber}</td>
                                <td>{item.purpose}</td>
                                <td> {item.lastname ? item.lastname+', ' : ''} {item.firstname} {item.middlename}</td>
                                <td>{item.contact}</td>
                                <td>{item.email}</td>
                                <td>{item.gender}</td>
                                <td>{item.ScheduleSwabbing}</td>
                                <td>{item.SwabbingTime}</td>
                                {/* <td>{item.date_registered}</td> */}
                                {actionBTN(item)}
                                
                            </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default ReservationTable
