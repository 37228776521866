import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ENV from '../ENV';

const Ultrasound = () => {
    
    const [data, setData] = useState([]);

    const DisplayData = async () => {
        // var formData = new FormData();
        // formData.append('username', username);
        // formData.append('password', password);
        await axios({
          method:'get',
          url: ENV.DOMAIN + 'php/ExcelFileUpload.php?FetchExcel&dept=ULTRASOUND',
          // url:'http://bqmh.goldtigerclub.com/php/adminLogin.php',
        })
        .then((res)=>{
          if(res.data){
            // sessionStorage.setItem('isLoggedin', 'true')
            // history.push('./bqmadminhome')
            // console.log(res.data)
            setData(res.data)
            // console.log(res.data)
          }
          // console.log(res.data)
        }).catch((err)=>{
          console.log(err)
        })
      }

      useEffect(() => {
        DisplayData()
      }, []);




    const TableRow = (item) => {
        for (let index = 0; index < item.length; index++) {
            const element = index;
            console.log(element)
            // return<td>{item[index]!='NULL'?item[index]:<div className="text-center">---</div>}</td>
        }
    }



    //   let i = 0;
    return (
        <>
            <div className='table-responsive hidden-xs' id='tablescroll' style={{overflowY:"scroll", height:'100vh'}}>
                <table className="table table-striped table-hover">
                    <thead style={{backgroundColor: 'rgb(82, 4, 8)', color:"#fff", top:0, position:"sticky"}}>
                        <tr>
                            <th style={{ width: 200, textTransform: "uppercase" }}>ITEMDESC</th>
                            <th style={{ width: 200, textTransform: "uppercase" }}>OUTPATIENT</th>
                            <th style={{ width: 200, textTransform: "uppercase" }}>CASH TRANSACTION</th>
                            <th style={{ width: 200, textTransform: "uppercase" }}>WARD</th>
                            <th style={{ width: 200, textTransform: "uppercase" }}>SEMI-PRIVATE</th>
                            <th style={{ width: 200, textTransform: "uppercase" }}>PRIVATE</th>
                            <th style={{ width: 200, textTransform: "uppercase" }}>ICU</th>
                            <th style={{ width: 200, textTransform: "uppercase" }}>SUITE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td><b>{item.itemdesc != 'NULL' ? item.itemdesc : <div className="text-center">---</div>}</b></td>
                                        <td>{item.outpatient != 'NULL' ? item.outpatient : <div className="text-center">---</div>}</td>
                                        <td>{item.cashtransaction != 'NULL' ? item.cashtransaction : <div className="text-center">---</div>}</td>
                                        <td>{item.ward != 'NULL' ? item.ward : <div className="text-center">---</div>}</td>
                                        <td>{item.semiprivate != 'NULL' ? item.semiprivate : <div className="text-center">---</div>}</td>
                                        <td>{item.private != 'NULL' ? item.private : <div className="text-center">---</div>}</td>
                                        <td>{item.icu != 'NULL' ? item.icu : <div className="text-center">---</div>}</td>
                                        <td>{item.suite != 'NULL' ? item.suite : <div className="text-center">---</div>}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className='table-responsive visible-xs' style={{overflowY:"auto", height:'70vh', padding:5}}>
                        {
                            data.map((item, i) => {
                                return (
                                    <div className="panel-body mgbt-xs-5" style={{backgroundColor:"#fff", borderRadius:5}}>
                                        <div className="row">
                                            <div className="col-xs-12">
                                                <h4 className="vd_red mgbt-xs-0">{item.itemdesc != 'NULL' ? item.itemdesc : <div className="text-center">---</div>}</h4>
                                                <div className="vd_grey" style={{fontSize:10}}>OUTPATIENT <span className='pull-right'>{item.outpatient != 'NULL' ? item.outpatient : <span className="text-center">---</span>}</span></div>
                                                <div className="vd_grey" style={{fontSize:10}}>CASH TRANSACTION <span className='pull-right'>{item.cashtransaction != 'NULL' ? item.cashtransaction : <span className="text-center">---</span>}</span></div>
                                                <div className="vd_grey" style={{fontSize:10}}>WARD <span className='pull-right'>{item.ward != 'NULL' ? item.ward : <span className="text-center">---</span>}</span></div>
                                                <div className="vd_grey" style={{fontSize:10}}>SEMI-PRIVATE <span className='pull-right'>{item.semiprivate != 'NULL' ? item.semiprivate : <span className="text-center">---</span>}</span></div>
                                                <div className="vd_grey" style={{fontSize:10}}>PRIVATE <span className='pull-right'>{item.private != 'NULL' ? item.private : <span className="text-center">---</span>}</span></div>
                                                <div className="vd_grey" style={{fontSize:10}}>ICU <span className='pull-right'>{item.icu != 'NULL' ? item.icu : <span className="text-center">---</span>}</span></div>
                                                <div className="vd_grey" style={{fontSize:10}}>SUITE <span className='pull-right'>{item.suite != 'NULL' ? item.suite : <span className="text-center">---</span>}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

            </div>
        </>
    );
}

export default Ultrasound;
