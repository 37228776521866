import React, { createRef, useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import Pdf from "react-to-pdf";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";
import { renderToString } from "react-dom/server";
import image from "./46964720_931525337041328_7708105959918272512_n.jpg";
import ENV from "./ENV";

import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import RestorePageIcon from "@mui/icons-material/RestorePage";

// import qrcode from './QRCode/1645933738874.png';
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Header from "./Extra/Header";
import Navbar from "./Extra/Navbar";
import ReactToPrint, { useReactToPrint } from "react-to-print";
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { Base64 } from 'js-base64';




const AdminPricing = () => {
    const isPosition = sessionStorage.getItem("isPosition")
    const isUserID = sessionStorage.getItem("isUserID")

    const history = useHistory()

    // console.log(history.location.pathname)

    if (isPosition === 'SWABBER') {
        history.push('/admin/appointment')
    } else {

    }



    const [AddAccount, setAddAccount] = useState(false);
    const [EditAccount, setEditAccount] = useState(false);
    const [EditAccountInfo, setEditAccountInfo] = useState(false);

    const [Id, setId] = useState();
    const [Fullname, setFullname] = useState('');
    const [Age, setAge] = useState('');
    const [Gender, setGender] = useState('');
    const [Position, setPosition] = useState('');
    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const [TitleName, setTitleName] = useState('');
    const [Department, setDepartment] = useState('');
    const [LicNo, setLicNo] = useState('');
    const [SignatureImage, setSignatureImage] = useState(null)
    // const [Placement, setPlacement] = useState('')



    const [MD, setMD] = useState(false);
    const [VERBY, setVERBY] = useState(false);
    const [ENCBY, setENCBY] = useState(false);
    const [VALBY, setVALBY] = useState(false);
    const [PERBY, setPERBY] = useState(false);

    const [DataAccount, setDataAccount] = useState([]);
    const [ByDepartment, setByDepartment] = useState([]);
    const [HistoryDepartmental, setHistoryDepartmental] = useState([]);
    const [fetchDataDepartment, setfetchDataDepartment] = useState([]);

    useEffect(() => {
        fetchByDepartment()
        fetchAccounts()
    }, []);


    const fetchAccounts = async () => {
        await axios({
            method: 'post',
            url: ENV.DOMAIN + 'php/fetchAccounts.php',
        })
            .then((res) => {
                setDataAccount(res.data)
                // fetchState(EditDetails.countryid)
                //   console.log(res.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    const fetchByDepartment = async () => {
        await axios({
            method: 'post',
            url: ENV.DOMAIN + 'php/ExcelFileUpload.php?getDepartmental',
        })
            .then((res) => {
                setByDepartment(res.data)
                // fetchState(EditDetails.countryid)
                //   console.log(res.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    const fetchByHistoryDeparmentalUpload = async () => {
        await axios({
            method: 'post',
            url: ENV.DOMAIN + 'php/ExcelFileUpload.php?getpricing_history',
        })
            .then((res) => {
                setHistoryDepartmental(res.data)
                // fetchState(EditDetails.countryid)
                //   console.log(res.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    const ExportExcelFormat = async (name) => {

        swal({
            title: "",
            text: "Export Excel Filename " + (name == 'LAB' ? 'LABORATORY' : name),
            icon: "info",
            // timer: 2000,
            buttons: ["Cancel", "Download File"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal({
                    title: (name == 'LAB' ? 'LABORATORY' : name),
                    text: "Generating Done!",
                }).then(() => {
                    window.location.assign(ENV.DOMAIN + 'php/ExcelFileUpload.php?ExportExcel&dept=' + name);
                    fetchAccounts()
                    setEditAccount(false)
                });
            } else {
                fetchAccounts()
                setEditAccount(false)
                // swal("Your imaginary file is safe!");
            }
        });

    }

    const [NameDepartment, setNameDepartment] = useState('');

    const fetchDepartment = async (name) => {
        setNameDepartment(name);
        await axios({
            method: 'GET',
            url: ENV.DOMAIN + 'php/ExcelFileUpload.php?FetchExcel&dept=' + name,
        })
            .then((res) => {
                setfetchDataDepartment(res.data)
                // fetchState(EditDetails.countryid)
                // console.log(res.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    const [fetchRoomRates, setfetchRoomRates] = useState([]);
    const [fetchMedicalPackages, setfetchMedicalPackages] = useState([]);

    const fetchRoomRate = async (name) => {
        setNameDepartment(name);
        setfetchDataDepartment([])
        var formData = new FormData();
        formData.append("room_rate", 1);
        await axios({
            method: 'post',
            url: ENV.DOMAIN + "php/room_rates.php",
            data: formData,
        })
            .then((res) => {
                setfetchRoomRates(res.data)
                // fetchState(EditDetails.countryid)
                // console.log(res.data)
            }).catch((err) => {
                console.log(err)
            })
    }
    const fetchMedicalPackage = async (name) => {
        setNameDepartment(name);
        setfetchDataDepartment([])
        var formData = new FormData();
        formData.append("medical_packages", 1);
        await axios({
            method: 'POST',
            url: ENV.DOMAIN + "php/medical_packages.php",
            data: formData,
        })
            .then((res) => {
                setfetchMedicalPackages(res.data)
                // fetchState(EditDetails.countryid)
                console.log(res.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    const BtnSaveAccount = async () => {
        var formData = new FormData();
        formData.append('id', Id);
        formData.append('fullname', Fullname.toUpperCase());
        // formData.append('age', Age);
        // formData.append('gender', Gender);
        formData.append('position', Position);
        formData.append('username', Username);
        formData.append('password', Password);
        formData.append('TitleName', TitleName.toUpperCase());
        formData.append('department', Department);
        formData.append('LicNo', LicNo);
        formData.append('DOMAIN', ENV.DOMAIN + 'php/signature/');
        formData.append('SignatureImage', SignatureImage);
        // placement
        formData.append('MD', MD);
        formData.append('VERBY', VERBY);
        formData.append('ENCBY', ENCBY);
        formData.append('VALBY', VALBY);
        formData.append('PERBY', PERBY);

        if (Fullname != '' && Position != '' && Username != '' && Password != '') {
            await axios({
                method: 'post',
                url: ENV.DOMAIN + 'php/AddAccounts.php',
                data: formData
            })
                .then((res) => {
                    console.log(res.data)
                    if (res.data === 'success') {
                        swal({
                            title: "",
                            text: "Successfully Saved",
                            icon: "success",
                            timer: 2000,
                            button: false,
                        }).then(() => {
                            fetchAccounts()
                            setEditAccount(false)
                            setAddAccount(false);
                            setLicNo('');
                            setSignatureImage(null);
                        });
                    } else {
                        swal({
                            title: "",
                            text: "Unable to Save",
                            icon: "error",
                            timer: 2000,
                            button: false,
                        }).then(() => {
                            fetchAccounts()
                            setAddAccount(true);
                            setEditAccount(false)
                            setLicNo('');
                            setSignatureImage(null);
                        });
                    }
                }).catch((err) => {
                    console.log(err)
                })
        } else {
            swal({
                title: "",
                text: "Please Complete Details",
                icon: "error",
                timer: 2000,
                button: false,
            })
        }
    }

    const setUpdate = async () => {
        console.log(SignatureImage)
        var formData = new FormData();
        formData.append('id', Id);
        formData.append('fullname', Fullname.toUpperCase());
        // formData.append('age', Age);
        // formData.append('gender', Gender);
        formData.append('position', Position);
        formData.append('username', Username);
        formData.append('password', Password);
        formData.append('TitleName', TitleName.toUpperCase());
        formData.append('department', Department);
        formData.append('LicNo', LicNo);
        formData.append('DOMAIN', ENV.DOMAIN + 'php/signature/');
        formData.append('SignatureImage', SignatureImage);
        // placement
        formData.append('MD', MD);
        formData.append('VERBY', VERBY);
        formData.append('ENCBY', ENCBY);
        formData.append('VALBY', VALBY);
        formData.append('PERBY', PERBY);
        await axios({
            method: 'post',
            url: ENV.DOMAIN + 'php/UpdateAccounts.php',
            data: formData
        }).then((res) => {
            // console.log(res.data)
            if (res.data === 'success') {
                swal({
                    title: "",
                    text: "Successfully Updated",
                    icon: "success",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    fetchAccounts()
                    setEditAccount(false)
                    setLicNo('');
                    setSignatureImage(null);
                });
            } else {

            }
        }).catch((err) => {
            console.log(err)
        })
    }


    const DeleteUser = async ({ id, fullname }) => {
        swal({
            title: "Are you sure delete this user",
            text: fullname,
            icon: "info",
            buttons: true,
        }).then(async (isdelete) => {
            if (isdelete) {
                var formData = new FormData();
                formData.append('id', id);
                await axios({
                    method: 'post',
                    url: ENV.DOMAIN + 'php/deleteAccounts.php',
                    data: formData
                }).then((res) => {
                    if (res.data === 'success') {
                        swal({
                            title: "",
                            text: "Successfully Deleted",
                            icon: "success",
                            timer: 2000,
                            button: false,
                        }).then(() => {
                            fetchAccounts()
                            setEditAccount(false)
                        });
                    } else {

                    }
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                console.log(2)
            }
        });
    }




    const onAddAccounts = () => {
        setAddAccount(true)
        setFullname('');
        setAge('');
        setGender('');
        setPosition('');
        setUsername('');
        setTitleName('');
        setLicNo('');
        // setPlacement('');
        setSignatureImage(null);
    }

    const [openUploadExcelForm, setOpenUploadExcelForm] = useState(false);
    const [openExportExcelPanel, setOpenExportExcelPanel] = useState(false);
    const [openHistoryExcelPanel, setOpenHistoryExcelPanel] = useState(false);

    const onUploadExcelFile = () => {
        setOpenUploadExcelForm(true)
        fetchByDepartment()
        fetchDepartment()
    }
    const onExportExcelFile = () => {
        setOpenExportExcelPanel(true)
        fetchByDepartment()
        fetchDepartment()
    }

    const onHistoryExcelFile = () => {
        setOpenHistoryExcelPanel(true);
        fetchByHistoryDeparmentalUpload()
    }

    const ShowBtn = () => {
        if (isPosition === 'ADMIN') {
            if (AddAccount) {
                return (
                    <>
                        <button onClick={() => BtnSaveAccount()} className="btn btn-success btn-xs" type="button">Save User</button>&nbsp;
                        <button onClick={() => setAddAccount(false)} className="btn btn-warning btn-xs" type="button">Cancel</button>
                    </>
                )
            }
            else if (openUploadExcelForm) {
                return (
                    <>
                        <button onClick={() => setOpenUploadExcelForm(false)} className="btn btn-warning btn-xs" type="button">Cancel</button>
                    </>
                )
            } else if (openExportExcelPanel) {
                return (
                    <>
                        <button onClick={() => setOpenExportExcelPanel(false)} className="btn btn-warning btn-xs" type="button">Cancel</button>
                    </>
                )
            } else if (openHistoryExcelPanel) {
                return (
                    <>
                        <button onClick={() => setOpenHistoryExcelPanel(false)} className="btn btn-warning btn-xs" type="button">Cancel</button>
                    </>
                )
            } else {
                if (EditAccount) {
                    return (
                        <>
                            <button onClick={() => setUpdate()} className="btn btn-primary btn-xs" type="button">Update User</button>&nbsp;
                            <button onClick={() => setEditAccount(false)} className="btn btn-warning btn-xs" type="button">Cancel</button>
                        </>
                    )
                } else {
                    return (
                        <>
                            {
                                sessionStorage.getItem('passcode_status') == 'passcode_success' ?
                                    <>
                                        <button onClick={() => onUploadExcelFile()} className="btn btn-primary btn-ms" type="button">Upload Excel File</button>&nbsp;
                                        <button onClick={() => onExportExcelFile()} className="btn btn-default btn-ms" type="button">Export Excel File</button>&nbsp;
                                        <button onClick={() => onHistoryExcelFile()} className="btn btn-default btn-ms" type="button">History</button>&nbsp;
                                    </>
                                :
                                <>
                                {
                                    isPosition === 'ADMIN' ?
                                    <>
                                        <button onClick={() => onUploadExcelFile()} className="btn btn-primary btn-ms" type="button">Upload Excel File</button>&nbsp;
                                        <button onClick={() => onExportExcelFile()} className="btn btn-default btn-ms" type="button">Export Excel File</button>&nbsp;
                                        <button onClick={() => onHistoryExcelFile()} className="btn btn-default btn-ms" type="button">History</button>&nbsp;
                                    </>
                                    :
                                    null
                                }
                                </>
                            }
                            {/* <button onClick={() => onAddAccounts()} className="btn btn-default btn-xs" type="button">Add User</button> */}
                        </>
                    )
                }
            }
        } else {
            if(history.location.pathname === '/upload'){
                if (AddAccount) {
                    return (
                        <>
                            <button onClick={() => BtnSaveAccount()} className="btn btn-success btn-xs" type="button">Save User</button>&nbsp;
                            <button onClick={() => setAddAccount(false)} className="btn btn-warning btn-xs" type="button">Cancel</button>
                        </>
                    )
                }
                else if (openUploadExcelForm) {
                    return (
                        <>
                            <button onClick={() => setOpenUploadExcelForm(false)} className="btn btn-warning btn-xs" type="button">Cancel</button>
                        </>
                    )
                } else if (openExportExcelPanel) {
                    return (
                        <>
                            <button onClick={() => setOpenExportExcelPanel(false)} className="btn btn-warning btn-xs" type="button">Cancel</button>
                        </>
                    )
                } else if (openHistoryExcelPanel) {
                    return (
                        <>
                            <button onClick={() => setOpenHistoryExcelPanel(false)} className="btn btn-warning btn-xs" type="button">Cancel</button>
                        </>
                    )
                } else {
                    if (EditAccount) {
                        return (
                            <>
                                <button onClick={() => setUpdate()} className="btn btn-primary btn-xs" type="button">Update User</button>&nbsp;
                                <button onClick={() => setEditAccount(false)} className="btn btn-warning btn-xs" type="button">Cancel</button>
                            </>
                        )
                    } else {
                        return (
                            <>
                            {
                                sessionStorage.getItem('passcode_status') == 'passcode_success' ?
                                    <>
                                        <button onClick={() => onUploadExcelFile()} className="btn btn-primary btn-ms" type="button">Upload Excel File</button>&nbsp;
                                        <button onClick={() => onExportExcelFile()} className="btn btn-default btn-ms" type="button">Export Excel File</button>&nbsp;
                                        <button onClick={() => onHistoryExcelFile()} className="btn btn-default btn-ms" type="button">History</button>&nbsp;
                                    </>
                                :
                                <></>
                            }
                                {/* <button onClick={() => onAddAccounts()} className="btn btn-default btn-xs" type="button">Add User</button> */}
                            </>
                        )
                    }
                }
            } else {

            }
        }
    }


    const TableActionHeader = () => {
        if (isPosition === 'ADMIN') {
            return (
                <th style={{ width: 150 }}></th>
            )
        } else {
            return (<th></th>)
        }
    }

    const decryptPassword = (text) => {
        var decode = Base64.decode(text);
        return decode;
    }

    const EditUser = (item) => {
        setId(item.id);
        setFullname(item.fullname);
        setAge(item.age);
        setGender(item.gender);
        setPosition(item.position);
        setUsername(item.username);
        setPassword(item.password);
        setTitleName(item.title);
        setLicNo(item.lic_no);
        setDepartment(item.department)
        setSignatureImage(item.signature_image);
        Placement(item)
        setHidePassword(false)
        setTypePassword('password')
        setEditAccount(true)
    }

    // const [Medical_Director, setMedical_Director] = useState('');
    // const [Verified_By, setVerified_By] = useState('');
    // const [Encoded_By, setEncoded_By] = useState('');
    // const [Validated_By, setValidated_By] = useState('');
    // const [Performed_By, setPerformed_By] = useState('');

    const Placement = (item) => {

        if (item.Medical_Director === 'true') {
            setMD(true)
        } else {
            setMD(false)
        }
        if (item.Verified_By === 'true') {
            setVERBY(true)
        } else {
            setVERBY(false)
        }
        if (item.Encoded_By === 'true') {
            setENCBY(true)
        } else {
            setENCBY(false)
        }
        if (item.Validated_By === 'true') {
            setVALBY(true)
        } else {
            setVALBY(false)
        }
        if (item.Performed_By === 'true') {
            setPERBY(true)
        } else {
            setPERBY(false)
        }
    }

    const TableActionBtn = (item) => {
        let btn = '';
        if (isPosition === 'ADMIN') {
            if (item.id == 1) {
                return (
                    <td className="menu-action">

                    </td>
                )
            } else {

                // if (item.fullname == 'MARIE ANTONETTE O. LUCASAN' || item.fullname == 'ROBBIE PATRICK C. MAGLAYA, RMT') {
                //     btn = <>
                //         <a onClick={()=>EditUser(item)} data-original-title="view" data-toggle="tooltip" data-placement="top" class="btn menu-icon vd_bd-grey vd_grey" > Edit </a>
                //     </>;
                // } else {
                //     btn = <>
                //         <a onClick={()=>EditUser(item)} data-original-title="view" data-toggle="tooltip" data-placement="top" class="btn menu-icon vd_bd-grey vd_grey" > Edit </a>
                //         <a onClick={()=>DeleteUser(item)} data-original-title="view" data-toggle="tooltip" data-placement="top" class="btn menu-icon vd_bd-red vd_red" > Delete </a>
                //     </>;
                // }
                return (
                    <td className="menu-action">
                        <a onClick={() => EditUser(item)} data-original-title="view" data-toggle="tooltip" data-placement="top" class="btn menu-icon vd_bd-grey vd_grey btn-xs" > Edit </a>
                        <a onClick={() => DeleteUser(item)} data-original-title="view" data-toggle="tooltip" data-placement="top" class="btn menu-icon vd_bd-red vd_red btn-xs" > Delete </a>
                    </td>
                )
            }
        } else {
            return <a onClick={() => EditUser(item)} data-original-title="view" data-toggle="tooltip" data-placement="top" class="btn menu-icon vd_bd-grey vd_grey btn-xs" > Edit </a>;
        }
    }



    const changeHandlerImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            let files = event.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = (e) => {
                setSignatureImage(e.target.result)
                console.log(e.target.result)
            }
        }
    }

    const LoopPlacement = (item) => {

        // const placement = '';
        if (item.Medical_Director === 'true') {
            return 'Medical Director'
        } else {
            return ''
        }
        // if(item.Verified_By === 'true'){
        //     placement += 'Verified By'
        // } else {
        //     setVERBY(false)
        // }
        // if(item.Encoded_By === 'true'){
        //     setENCBY(true)
        // } else {
        //     setENCBY(false)
        // }
        // if(item.Validated_By === 'true'){
        //     setVALBY(true)
        // } else {
        //     setVALBY(false)
        // }
        // if(item.Performed_By === 'true'){
        //     setPERBY(true)
        // } else {
        //     setPERBY(false)
        // }
        // return '';
    }


    const Extras = (value) => {
        if (value == 'BDC') {
            return null;
        } else if (value == 'INFO') {
            return null;
        } else if (value == 'SWABBER') {
            return null;
        } else if (value == 'SECRETARY') {
            return null;
        } else {
            return (
                <>
                    <div className="form-group">
                        <label className="col-sm-3 control-label">Department </label>
                        <div className="col-sm-9 controls" style={{ padding: 5 }}>
                            <input onChange={(e) => setDepartment(e.target.value)} value={Department} placeholder="Department" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-3 control-label">Title </label>
                        <div className="col-sm-9 controls" style={{ padding: 5 }}>
                            <input onChange={(e) => setTitleName(e.target.value)} value={TitleName} placeholder="Title" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-3 control-label">License. No. </label>
                        <div className="col-sm-9 controls" style={{ padding: 5 }}>
                            <input onChange={(e) => setLicNo(e.target.value)} value={LicNo} placeholder="License. No." style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-3 control-label">Signature Image. </label>
                        <div className="col-sm-9 controls" style={{ padding: 5 }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label style={{ padding: 5, borderRadius: 5, border: '1px solid rgb(221, 221, 221)', width: 300, margin: '0px auto', textAlign: 'center' }}>
                                        <input type="file" placeholder="" style={{ display: "none", width: 200 }} className="required" onChange={(e) => changeHandlerImage(e)} />
                                        <img src={SignatureImage} id="image-btn" style={{ width: '100%' }} /><img id="imgpreview" style={{ width: '100%' }} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-3 control-label">Placement</label>
                        <div className="col-sm-9 controls" style={{ padding: 5 }}>
                            <div className="">
                                {
                                    MD ?
                                        <span onClick={() => setMD(false)}>
                                            <CheckBoxIcon style={{ fontSize: 20 }} />
                                            <label> Medical Director </label>
                                        </span>
                                        :
                                        <span onClick={() => setMD(true)}>
                                            <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />
                                            <label> Medical Director </label>
                                        </span>
                                }
                            </div>
                            <div className="">
                                {
                                    VERBY ?
                                        <span onClick={() => setVERBY(false)}>
                                            <CheckBoxIcon style={{ fontSize: 20 }} />
                                            <label> Verified By </label>
                                        </span>
                                        :
                                        <span onClick={() => setVERBY(true)}>
                                            <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />
                                            <label> Verified By </label>
                                        </span>
                                }
                            </div>
                            <div className="">
                                {
                                    ENCBY ?
                                        <span onClick={() => setENCBY(false)}>
                                            <CheckBoxIcon style={{ fontSize: 20 }} />
                                            <label> Encoded By </label>
                                        </span>
                                        :
                                        <span onClick={() => setENCBY(true)}>
                                            <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />
                                            <label> Encoded By </label>
                                        </span>
                                }
                            </div>
                            <div className="">
                                {
                                    VALBY ?
                                        <span onClick={() => setVALBY(false)}>
                                            <CheckBoxIcon style={{ fontSize: 20 }} />
                                            <label> Validated By </label>
                                        </span>
                                        :
                                        <span onClick={() => setVALBY(true)}>
                                            <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />
                                            <label> Validated By </label>
                                        </span>
                                }
                            </div>
                            <div className="">
                                {
                                    PERBY ?
                                        <span onClick={() => setPERBY(false)}>
                                            <CheckBoxIcon style={{ fontSize: 20 }} />
                                            <label> Performed By </label>
                                        </span>
                                        :
                                        <span onClick={() => setPERBY(true)}>
                                            <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />
                                            <label> Performed By </label>
                                        </span>
                                }
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }


    const onSelectPosition = (value, isUserID) => {
        return (
            <select onChange={(e) => setPosition(e.target.value)} value={Position} style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }}>
                <option value={''}>Select</option>
                <option value={'LABORATORY'}>LABORATORY</option>
                <option value={'X-RAY'}>X-RAY</option>
                <option value={'CT-SCAN'}>CT-SCAN</option>
                <option value={'ULTRASOUND'}>ULTRASOUND</option>
            </select>
        )
    }

    const [HidePassword, setHidePassword] = useState(false)
    const [TypePassword, setTypePassword] = useState('password')

    const ShowPassword = (value) => {
        if (value) {
            setHidePassword(value)
            setTypePassword('')
        } else {
            setHidePassword(value)
            setTypePassword('password')
        }
    }

    const DisplayShowPassword = (status, isPosition, Password) => {
        if (Password != '') {
            if (isPosition === 'ADMIN') {
                if (status) {
                    return (<a class="help-inline" onClick={() => ShowPassword(false)} style={{ cursor: 'pointer' }}>Hide Password</a>)
                } else {
                    return (<a class="help-inline" onClick={() => ShowPassword(true)} style={{ cursor: 'pointer' }}>Show Password</a>)
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }



    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileMedicalPackages, setSelectedFileMedicalPackages] = useState();
    const [selectedFileRoomRates, setSelectedFileRoomRates] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isSelectedRoomPackages, setIsSelectedRoomPackages] = useState(false);
    const [isSelectedRoomRates, setIsSelectedRoomRates] = useState(false);

    const changeHandler = (event) => {
        if (event != undefined) {
            if (/[^.]+$/.exec(event.target.files[0].name)[0] === 'xlsx' || /[^.]+$/.exec(event.target.files[0].name)[0] === 'xls' || /[^.]+$/.exec(event.target.files[0].name)[0] === 'csv' || /[^.]+$/.exec(event.target.files[0].name)[0] === 'zip') {
                console.log((/[.]/.exec(event.target.files[0].name)) ? /[^.]+$/.exec(event.target.files[0].name)[0] : null)
                setSelectedFile(event.target.files[0]);
                setIsSelected(true);
            } else {
                alert('error')
                setSelectedFile([]);
                setIsSelected(false);

            }
        } else {
            setSelectedFile([]);
            setIsSelected(false);
        }
    };

    const changeHandlerroomrate = (event) => {
        if (event != undefined) {
            if (/[^.]+$/.exec(event.target.files[0].name)[0] === 'xlsx' || /[^.]+$/.exec(event.target.files[0].name)[0] === 'xls' || /[^.]+$/.exec(event.target.files[0].name)[0] === 'csv' || /[^.]+$/.exec(event.target.files[0].name)[0] === 'zip') {
                console.log((/[.]/.exec(event.target.files[0].name)) ? /[^.]+$/.exec(event.target.files[0].name)[0] : null)
                setSelectedFileRoomRates(event.target.files[0]);
                setIsSelectedRoomRates(true);
            } else {
                alert('error')
                setSelectedFileRoomRates([]);
                setIsSelectedRoomRates(false);

            }
        } else {
            setSelectedFileRoomRates([]);
            setIsSelectedRoomRates(false);
        }
    };

    const changeHandlermedicalpackages = (event) => {
        if (event != undefined) {
            if (/[^.]+$/.exec(event.target.files[0].name)[0] === 'xlsx' || /[^.]+$/.exec(event.target.files[0].name)[0] === 'xls' || /[^.]+$/.exec(event.target.files[0].name)[0] === 'csv' || /[^.]+$/.exec(event.target.files[0].name)[0] === 'zip') {
                console.log((/[.]/.exec(event.target.files[0].name)) ? /[^.]+$/.exec(event.target.files[0].name)[0] : null)
                setSelectedFileMedicalPackages(event.target.files[0]);
                setIsSelectedRoomPackages(true);
            } else {
                alert('error')
                setSelectedFileMedicalPackages([]);
                setIsSelectedRoomPackages(false);

            }
        } else {
            setSelectedFileMedicalPackages([]);
            setIsSelectedRoomPackages(false);
        }
    };

    const handleSubmission = async () => {
        var formData = new FormData();
        formData.append('ExcelUpload', 1);
        formData.append('File', selectedFile);
        await axios({
            method: 'post',
            url: ENV.DOMAIN + 'php/ExcelFileUpload.php',
            data: formData
        }).then((res) => {
            console.log(res.data);
            if (res.data === 'success') {
                swal({
                    title: "",
                    text: "Successfully Uploaded",
                    icon: "success",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    setOpenUploadExcelForm(false)
                    setOpenExportExcelPanel(false)
                    setOpenHistoryExcelPanel(false)
                    fetchByDepartment();
                    // fetchAccounts()
                    // setEditAccount(false)
                });
            } else {

            }
        }).catch((err) => {
            console.log(err)
        })
    };

    const handleSubmissionmedicalpackages = async () => {
        var formData = new FormData();
        formData.append('upload_medical_packages', 1);
        formData.append('File', selectedFile);
        await axios({
            method: 'post',
            url: ENV.DOMAIN + 'php/medical_packages.php',
            data: formData
        }).then((res) => {
            console.log(res.data);
            if (res.data === 'success') {
                swal({
                    title: "",
                    text: "Successfully Uploaded",
                    icon: "success",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    setOpenUploadExcelForm(false)
                    setOpenExportExcelPanel(false)
                    setOpenHistoryExcelPanel(false)
                    fetchByDepartment();
                    // fetchAccounts()
                    // setEditAccount(false)
                });
            } else {

            }
        }).catch((err) => {
            console.log(err)
        })
    };

    const handleSubmissionroomrates = async () => {
        var formData = new FormData();
        formData.append('upload_room_rate', 1);
        formData.append('File', selectedFileRoomRates);
        await axios({
            method: 'post',
            url: ENV.DOMAIN + 'php/room_rates.php',
            data: formData
        }).then((res) => {
            console.log(res.data);
            // if (res.data === 'success') {
                // swal({
                //     title: "",
                //     text: "Successfully Uploaded",
                //     icon: "success",
                //     timer: 2000,
                //     button: false,
                // }).then(() => {
                    // setOpenUploadExcelForm(false)
                    // setOpenExportExcelPanel(false)
                    // setOpenHistoryExcelPanel(false)
                    // fetchByDepartment();
                    // fetchAccounts()
                    // setEditAccount(false)
                // });
            // } else {

            // }
        }).catch((err) => {
            console.log(err)
        })
    };
    // 

    // console.log(selectedFile)

    const [passcodetext,setpasscodetext]=useState('')
    const confirmpasscodebtn = async () => {
        var formData = new FormData();
        formData.append('passcode', 1);
        formData.append('passcodetext', passcodetext);
        await axios({
            method: 'post',
            url: ENV.DOMAIN + 'php/passcode.php',
            data: formData
        }).then((res) => {
            if(res.data[0].status == 'success'){
                swal({
                    text: "Successfully Entered",
                }).then(() => {
                    sessionStorage.setItem('passcode_status', 'passcode_success');
                    window.location.assign('/upload');
                });
            } else {
                sessionStorage.setItem('passcode_status', 'passcode_denied');
            }
        });
    }

    const AccountDisplay = () => {
        if (history.location.pathname === '/upload') {
            // console.log(history.location.pathname)
            if(sessionStorage.getItem('passcode_status') == 'passcode_success'){
                if (AddAccount) {
                    return (
                        <>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-6">
                                <div className="panel widget" style={{ minHeight: 700 }}>
                                    <div className="panel-body" style={{ borderradius: 10 }}>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">CATEGORY</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                {onSelectPosition(isPosition, isUserID)}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">ITEMDESC</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">OUTPATIENT</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">CASH TRANSACTION</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">WARD</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">SEMI PRIVATE</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">PRIVATE</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">SUITE</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">HMO</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
    
                                        {/* <div className="form-group">
                                            <label className="col-sm-3 control-label">Position</label>
                                            <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                {onSelectPosition(isPosition, isUserID)}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Username</label>
                                            <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                <input onChange={(e) => setUsername(e.target.value)} type="" placeholder="Username" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Password</label>
                                            <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                <input onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        {Extras(Position)} */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3"></div>
    
                        </>
                    )
                } else if (openUploadExcelForm) {
                    return (
                        <>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-6">
                                <div className="panel widget" style={{ minHeight: 700 }}>
                                    <div className="panel-body" style={{ borderradius: 10 }}>
                                        <div><b>DEPARTMENTAL ITEM PRICE LIST</b></div>
                                        <div>
                                            <input type="file" name="file" onChange={changeHandler} />
                                            {isSelected ? (
                                                <div>
                                                    <p>Filename: {selectedFile.name}</p>
                                                    {/* <p>Filetype: {selectedFile.type}</p> */}
                                                    <p>Size in bytes: {selectedFile.size}</p>
                                                    <p>
                                                        lastModifiedDate:{' '}
                                                        {selectedFile.lastModifiedDate.toLocaleDateString()}
                                                    </p>
                                                </div>
                                            ) : (
                                                <p>Select a file to show details</p>
                                            )}
                                            {isSelected ? (
                                                <div>
                                                    <button onClick={handleSubmission}>Submit</button>
                                                </div>
                                            ) : (null)
                                            }
                                        </div>
    
                                    </div>
                                    <div className="panel-body" style={{ borderradius: 10 }}>
                                        <div><b>MEDICAL PACKAGES</b></div>
                                        <div>
                                            <input type="file" name="file" onChange={changeHandlermedicalpackages} />
                                            {isSelectedRoomPackages ? (
                                                <div>
                                                    <p>Filename: {selectedFileMedicalPackages.name}</p>
                                                    {/* <p>Filetype: {selectedFileMedicalPackages.type}</p> */}
                                                    <p>Size in bytes: {selectedFileMedicalPackages.size}</p>
                                                    <p>
                                                        lastModifiedDate:{' '}
                                                        {selectedFile.lastModifiedDate.toLocaleDateString()}
                                                    </p>
                                                </div>
                                            ) : (
                                                <p>Select a file to show details</p>
                                            )}
                                            {isSelectedRoomPackages ? (
                                                <div>
                                                    <button onClick={handleSubmissionmedicalpackages}>Submit</button>
                                                </div>
                                            ) : (null)
                                            }
                                        </div>
    
                                    </div>
                                    <div className="panel-body" style={{ borderradius: 10 }}>
                                        <div><b>ROOM RATES</b></div>
                                        <div>
                                            <input type="file" name="file" onChange={changeHandlerroomrate} />
                                            {isSelectedRoomRates ? (
                                                <div>
                                                    <p>Filename: {selectedFileRoomRates.name}</p>
                                                    {/* <p>Filetype: {selectedFileRoomRates.type}</p> */}
                                                    <p>Size in bytes: {selectedFileRoomRates.size}</p>
                                                    <p>
                                                        lastModifiedDate:{' '}
                                                        {selectedFileRoomRates.lastModifiedDate.toLocaleDateString()}
                                                    </p>
                                                </div>
                                            ) : (
                                                <p>Select a file to show details</p>
                                            )}
                                            {isSelectedRoomRates ? (
                                                <div>
                                                    <button onClick={handleSubmissionroomrates}>Submit</button>
                                                </div>
                                            ) : (null)
                                            }
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3"></div>
                        </>
                    )
                } else if (openExportExcelPanel) {
                    return (
                        <>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-6">
                                <div className="panel widget" style={{ minHeight: 700 }}>
                                    <div className="panel-body" style={{ borderradius: 10 }}>
    
    
                                        <div>
                                            <div style={{ fontSize: 15, fontWeight: '700' }}>Export Excel Format</div>
                                            <div style={{ display: "flex", gap: 5, paddingTop: 10, flexDirection: "row", flexWrap: "wrap" }}>
                                                {
                                                    ByDepartment.map((item) => {
                                                        return (
                                                            <button className="btn btn-ms btn-default" onClick={() => ExportExcelFormat(item.department)}>{item.department == 'LAB' ? 'LABORATORY' : item.department} ({item.count})</button>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3"></div>
                        </>
                    )
                } else if (openHistoryExcelPanel) {
                    return (
                        <>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-6">
                                <div className="panel widget" style={{ minHeight: 700 }}>
                                    <div className="panel-body" style={{ borderradius: 10 }}>
    
    
                                        <div>
                                            <div style={{ fontSize: 15, fontWeight: '700' }}>Last Uploaded</div>
                                            <div style={{ display: "flex", gap: 5, paddingTop: 10, flexDirection: "row", flexWrap: "wrap" }}>
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        {
                                                            HistoryDepartmental.map((item) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{item.departmental}</td>
                                                                        <td>{item.date_time}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3"></div>
                        </>
                    )
                } else {
    
                    if (EditAccount) {
                        return (
                            <>
                                <div className="col-sm-3"></div>
                                <div className="col-sm-6">
                                    <div className="panel widget" style={{ minHeight: 700 }}>
                                        <div className="panel-body" style={{ borderradius: 10 }}>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Fullname</label>
                                                <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                    <input type="" onChange={(e) => setFullname(e.target.value)} value={Fullname} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Position</label>
                                                <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                    {onSelectPosition(isPosition, isUserID)}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Username</label>
                                                <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                    <input type="" onChange={(e) => setUsername(e.target.value)} value={Username} placeholder="Username" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Password</label>
                                                <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                    <input type={TypePassword} onChange={(e) => setPassword(e.target.value)} value={Password} placeholder="Password" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                                    {DisplayShowPassword(HidePassword, isPosition, Password)}
                                                </div>
                                            </div>
                                            {Extras(Position)}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
    
                                </div>
                            </>
                        )
                    } else {
                        const PositionDisplay = (item) => {
                            if (item.position === 'ADMIN') {
                                return 'ADMINISTRATOR';
                            } else {
                                return item.position;
                            }
                        }
                        return (
                            <div className="col-sm-12">
                                <div className="panel widget">
                                    <div className="panel-body table-responsive" style={{ padding: 0 }}>
                                        <div style={{padding:10}}>
                                            <div style={{fontWeight:"bold"}}>DEPARTMENTAL ITEM PRICE LIST</div>
                                            <div style={{ display: "flex", gap: 5, paddingTop: 5 }}>
                                                {
                                                    ByDepartment.map((item) => {
                                                        return (
                                                            <button className="btn btn-ms btn-default" onClick={() => fetchDepartment(item.department)}>{item.department == 'LAB' ? 'LABORATORY' : item.department} ({item.count})</button>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <hr />
                                        <div style={{padding:10}}>
                                            {/* <div>ROOM RATES</div> */}
                                            <div style={{ display: "flex", gap: 5 }}>
                                                <button class="btn btn-ms btn-default" onClick={() => fetchRoomRate('ROOM RATES')}>ROOM RATES</button>
                                            </div>
                                        </div>
                                        <div style={{padding:10}}>
                                            {/* <div>MEDICAL PACKAGES</div> */}
                                            <div style={{ display: "flex", gap: 5 }}>
                                                <button class="btn btn-ms btn-default" onClick={() => fetchMedicalPackage('MEDICAL PACKAGES')}>MEDICAL PACKAGES</button>
                                            </div>
                                        </div>
                                        <div style={{ fontSize: 18, padding: 15, fontWeight: "800" }}>{NameDepartment == "LAB" ? 'LABORATORT' : NameDepartment}</div>
                                        {
                                            fetchDataDepartment.length > 0 ?
                                                <table className="table table-bordered table-hover">
                                                    <thead className="vd_bg-green vd_white">
                                                        <tr>
                                                            <th>#</th>
                                                            <th style={{ width: 300 }}>Item Desc</th>
                                                            {/* <th>Gender</th> */}
                                                            <th>Out Patient</th>
                                                            <th>Cash Transaction</th>
                                                            <th>Ward</th>
                                                            <th>Semi Private</th>
                                                            <th>Private</th>
                                                            <th>ICU</th>
                                                            <th>Suite</th>
                                                            {/* <th>Suite</th> */}
                                                            {/* {TableActionHeader()} */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            fetchDataDepartment.map((item, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{(item.num)}</td>
                                                                        <td>{item.itemdesc}</td>
                                                                        <td>{item.outpatient}</td>
                                                                        <td>{item.cashtransaction == 'NULL' ? '---' : item.cashtransaction}</td>
                                                                        <td>{item.ward}</td>
                                                                        <td>{item.semiprivate}</td>
                                                                        <td>{item.private}</td>
                                                                        <td>{item.icu}</td>
                                                                        <td>{item.suite}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <></>
                                        }
                                        {
                                            fetchRoomRates.length > 0 ?
                                            <table className="table table-bordered table-hover">
                                                <thead className="vd_bg-green vd_white">
                                                    <tr>
                                                        {/* <th>#</th> */}
                                                        <th style={{ width: 300 }}>FLOOR NAME</th>
                                                        <th>NURSE STATION</th>
                                                        <th>ROOM NO.</th>
                                                        <th>ROOM CLASS</th>
                                                        <th>PRICE SCHEME</th>
                                                        <th>ROOM RATES</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        fetchRoomRates.map((item, i) => {
                                                            return (
                                                                <tr>
                                                                    {/* <td>{(item.num)}</td> */}
                                                                    <td>{item.floor_name}</td>
                                                                    <td>{item.nurse_station}</td>
                                                                    <td>{item.room_no}</td>
                                                                    <td>{item.room_class}</td>
                                                                    <td>{item.price_scheme}</td>
                                                                    <td>{item.room_rates}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
            } else {                
                return (
                    <>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                            <div className="vd_register-page">
                                <div className="panel widget">
                                    <div className="panel-body">
                                        <label>Enter Passcode</label>
                                        <input type="password" onChange={(e)=>setpasscodetext(e.target.value)} />
                                        <div style={{padding:"10px 0px"}}>
                                            <button onClick={()=>confirmpasscodebtn()} className="btn btn-info btn-xs">Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        } else {
            if (isPosition === 'ADMIN') {
                if (AddAccount) {
                    return (
                        <>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-6">
                                <div className="panel widget" style={{ minHeight: 700 }}>
                                    <div className="panel-body" style={{ borderradius: 10 }}>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">CATEGORY</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                {onSelectPosition(isPosition, isUserID)}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">ITEMDESC</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">OUTPATIENT</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">CASH TRANSACTION</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">WARD</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">SEMI PRIVATE</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">PRIVATE</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">SUITE</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-4 control-label">HMO</label>
                                            <div className="col-sm-8 controls" style={{ padding: 5 }}>
                                                <input type="" onChange={(e) => setFullname(e.target.value)} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
    
                                        {/* <div className="form-group">
                                            <label className="col-sm-3 control-label">Position</label>
                                            <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                {onSelectPosition(isPosition, isUserID)}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Username</label>
                                            <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                <input onChange={(e) => setUsername(e.target.value)} type="" placeholder="Username" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Password</label>
                                            <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                <input onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                            </div>
                                        </div>
                                        {Extras(Position)} */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3"></div>
    
                        </>
                    )
                } else if (openUploadExcelForm) {
                    return (
                        <>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-6">
                                <div className="panel widget" style={{ minHeight: 700 }}>
                                    <div className="panel-body" style={{ borderradius: 10 }}>
    
    
                                    <div>
                                            <input type="file" name="file" onChange={changeHandler} />
                                            {isSelected ? (
                                                <div>
                                                    <p>Filename: {selectedFile.name}</p>
                                                    {/* <p>Filetype: {selectedFile.type}</p> */}
                                                    <p>Size in bytes: {selectedFile.size}</p>
                                                    <p>
                                                        lastModifiedDate:{' '}
                                                        {selectedFile.lastModifiedDate.toLocaleDateString()}
                                                    </p>
                                                </div>
                                            ) : (
                                                <p>Select a file to show details</p>
                                            )}
                                            {isSelected ? (
                                                <div>
                                                    <button onClick={handleSubmission}>Submit</button>
                                                </div>
                                            ) : (null)
                                            }
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3"></div>
                        </>
                    )
                } else if (openExportExcelPanel) {
                    return (
                        <>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-6">
                                <div className="panel widget" style={{ minHeight: 700 }}>
                                    <div className="panel-body" style={{ borderradius: 10 }}>
    
    
                                        <div>
                                            <div style={{ fontSize: 15, fontWeight: '700' }}>Export Excel Format</div>
                                            <div style={{ display: "flex", gap: 5, paddingTop: 10, flexDirection: "row", flexWrap: "wrap" }}>
                                                {
                                                    ByDepartment.map((item) => {
                                                        return (
                                                            <button className="btn btn-ms btn-default" onClick={() => ExportExcelFormat(item.department)}>{item.department == 'LAB' ? 'LABORATORY' : item.department} ({item.count})</button>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3"></div>
                        </>
                    )
                } else {
    
                    if (EditAccount) {
                        return (
                            <>
                                <div className="col-sm-3"></div>
                                <div className="col-sm-6">
                                    <div className="panel widget" style={{ minHeight: 700 }}>
                                        <div className="panel-body" style={{ borderradius: 10 }}>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Fullname</label>
                                                <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                    <input type="" onChange={(e) => setFullname(e.target.value)} value={Fullname} placeholder="Fullname" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Position</label>
                                                <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                    {onSelectPosition(isPosition, isUserID)}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Username</label>
                                                <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                    <input type="" onChange={(e) => setUsername(e.target.value)} value={Username} placeholder="Username" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-sm-3 control-label">Password</label>
                                                <div className="col-sm-9 controls" style={{ padding: 5 }}>
                                                    <input type={TypePassword} onChange={(e) => setPassword(e.target.value)} value={Password} placeholder="Password" style={{ border: 'none', border: '0.5px solid #ddd', width: "100%", borderRadius: 5, height: 35, outline: "none" }} />
                                                    {DisplayShowPassword(HidePassword, isPosition, Password)}
                                                </div>
                                            </div>
                                            {Extras(Position)}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
    
                                </div>
                            </>
                        )
                    } else {
                        const PositionDisplay = (item) => {
                            if (item.position === 'ADMIN') {
                                return 'ADMINISTRATOR';
                            } else {
                                return item.position;
                            }
                        }
                        return (
                            <div className="col-sm-12">
                                <div className="panel widget">
    
    
    
    
                                    <div className="panel-body table-responsive" style={{ padding: 0 }}>
                                        <div style={{ display: "flex", gap: 5, padding: 15 }}>
                                            {
                                                ByDepartment.map((item) => {
                                                    return (
                                                        <button className="btn btn-ms btn-default" onClick={() => fetchDepartment(item.department)}>{item.department == 'LAB' ? 'LABORATORY' : item.department} ({item.count})</button>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div style={{ fontSize: 18, padding: 15, fontWeight: "800" }}>{NameDepartment == "LAB" ? 'LABORATORT' : NameDepartment}</div>
                                        {
                                            fetchDataDepartment.length > 0 ?
                                                <table className="table table-bordered table-hover">
                                                    <thead className="vd_bg-green vd_white">
                                                        <tr>
                                                            <th>#</th>
                                                            <th style={{ width: 300 }}>Item Desc</th>
                                                            {/* <th>Gender</th> */}
                                                            <th>Out Patient</th>
                                                            <th>Cash Transaction</th>
                                                            <th>Ward</th>
                                                            <th>Semi Private</th>
                                                            <th>Private</th>
                                                            <th>ICU</th>
                                                            <th>Suite</th>
                                                            {/* <th>Suite</th> */}
                                                            {/* {TableActionHeader()} */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            fetchDataDepartment.map((item, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{(item.num)}</td>
                                                                        <td>{item.itemdesc}</td>
                                                                        <td>{item.outpatient}</td>
                                                                        <td>{item.cashtransaction == 'NULL' ? '---' : item.cashtransaction}</td>
                                                                        <td>{item.ward}</td>
                                                                        <td>{item.semiprivate}</td>
                                                                        <td>{item.private}</td>
                                                                        <td>{item.icu}</td>
                                                                        <td>{item.suite}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
            } else {
                return (
                    <>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                            <div className="vd_register-page">
                                <div className="panel widget">
                                    <div className="panel-body">
                                        <h1 className="font-semibold text-center" style={{ fontSize: 30 }}>"Restricted"</h1>
                                        <h1 className="font-semibold text-center" style={{ fontSize: 25 }}>For Administrator Only</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        }
    }







    const vd_container = (isPosition) => {
        let status;
        if (isPosition === 'BDC' || isPosition === 'INFO' || isPosition === 'SWABBER' || isPosition === 'STAFF') {
            status = '';
        } else {
            status = history.location.pathname == '/upload' ? '' :'vd_container';
        }
        return status;
    }


    return (
        <>
            <div id="pages" className="full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975" data-active="pages " data-smooth-scrolling={1}>
                <div className="vd_body">
                    {/* Header Start */}
                    <Header />
                    <div className="content">
                        <div className="container">
                            {
                                history.location.pathname == '/upload' ?
                                null
                                :
                                <Navbar isPosition={isPosition} />
                            }
                            {/* Middle Content Start */}
                            <div className={history.location.pathname == '/upload' ? '' :"vd_content-wrapper"} style={{ minHeight: 1059 }}>
                                <div className={vd_container(isPosition)} style={{ height: 1059 }}>
                                    <div className="vd_content clearfix">
                                        <div className="vd_head-section clearfix">
                                            <div className="vd_panel-header">
                                                <div
                                                    className="vd_panel-menu hidden-sm hidden-xs"
                                                    data-intro="<strong>Expand Control</strong><br/>To expand content page horizontally, vertically, or Both. If you just need one button just simply remove the other button code."
                                                    data-step={5}
                                                    data-position="left">

                                                </div>
                                            </div>
                                        </div>
                                        <div className="vd_title-section clearfix">
                                            <div className="vd_panel-header no-subtitle">
                                                <h1>DEPARTMENTAL ITEM PRICE LIST</h1>
                                                <div className="vd_panel-menu  hidden-xs">
                                                    {ShowBtn()}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="vd_content-section clearfix">
                                            <div className="row">
                                                {AccountDisplay()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminPricing;
