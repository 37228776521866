import axios from 'axios'
// import QRCode from 'qrcode.react'
// import QRCode from 'qrcode'
import QRCode from "qrcode.react";
// import * as React from 'react';
import Pdf from "react-to-pdf";
import { createRef, React, useEffect, useMemo, useRef, useState } from 'react'
import Header from './Extra/Header'
import Navbar from './Extra/Navbar'
import image from './46964720_931525337041328_7708105959918272512_n.jpg';
// import sig from './signature.PNG';
import sig from './Van_Binh_signature.png';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Link, useHistory } from 'react-router-dom'
import ENV from "./ENV";
import './../App.css';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import RestorePageIcon from "@mui/icons-material/RestorePage";

import SignaturePad from 'react-signature-canvas'

import {
  Box,
  Stack,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField
} from '@mui/material';
import swal from 'sweetalert'
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL2 = 'http://bqmh.goldtigerclub.com/';
// const API_URL = 'http://localhost/BQMH/client/php/';
// const API_URL2 = 'http://localhost/BQMH/client/';







const RenderResult = () => {
  let signPad = useRef();
  const history = useHistory()
  const { id } = useParams();
  const printRef = useRef();
  let componentRef = useRef();
  const ref = createRef();

  const [EditDetails, setEditDetails] = useState(false)


  const [status, setStatus] = useState('')
  const [OnDate, setOnDate] = useState('')
  const [OnTime, setOnTime] = useState('')
  // const [OnTemp, setTemp] = useState('')
  const [DataDecease, setDataDecease] = useState([])

  const [OpenPending, setOpenPending] = useState(false)




  useEffect(() => {
    DisplayData();
  }, [])

  const DisplayData = () => {
    axios.get(ENV.DOMAIN + 'php/showResult.php?id=' + id)
      .then((res) => {
        if (res.data[0].status === 'pending') {
          setOpenPending(false)
        } else {
          setOpenPending(true)
        }
        console.log(res.data);
        setEditDetails(res.data[0])
      }).catch((err) => {
        console.log(err)
      })
  }



  // let signData = '';
  const [signData, setSignData] = useState(sig);
  const ClearSign = () => {
    signPad.current.clear();
  }
  const saveSign = () => {
    const data = signPad.current.toDataURL();
    setSignData(data);
    signPad.current.clear();
  }
  const ShowSign = () => {
    signPad.current.toDataURL(signData);
  }

  const [LabProNo, setLabProNo] = useState('');
  const [AccesNo, setAccesNo] = useState('');
  console.log(AccesNo);

  // const [ResultCOV, setResultCOV] = useState('')

  // console.log(EditDetails.qrcode)

  const ShowPositiveRes = (value) => {
    if (value === 'POSITIVE FOR SARS-COV-2 RNA') {
      return (<div style={{ color: "red" }}>POSITIVE FOR SARS-COV-2 RNA</div>)
    } else if (value === 'NEGATIVE FOR SARS-COV-2 RNA') {
      return (<div>NEGATIVE FOR SARS-COV-2 RNA</div>)
    } else {
      return (<div>INVALID</div>)
    }
  }



  const DisplayOPD = (value) => {
    if(value === 'OPD') {
      return (<div>[<input type="radio" name="PLocation" checked />] <b>OPD -</b></div>)
    } else {
      return (<div>[<input type="radio" name="PLocation" />] <b>OPD -</b></div>)
    }
  }
  const DisplayREFERRAL = (value) => {
    if(value === 'REFERRAL') {
      return (<div>[<input type="radio" name="PLocation" checked />] <b>REFERRAL -</b></div>)
    } else {
      return (<div>[<input type="radio" name="PLocation" />] <b>REFERRAL -</b></div>)
    }
  }
  const DisplayCOMPANY = (value) => {
    if(value === 'COMPANY') {
      return (<div>[<input type="radio" name="PLocation" checked />] <b>COMPANY -</b></div>)
    } else {
      return (<div>[<input type="radio" name="PLocation" />] <b>COMPANY -</b></div>)
    }
  }
  const DisplayINPATIENT = (value) => {
    if(value === 'INPATIENT') {
      return (<div>[<input type="radio" name="PLocation" checked />] <b>INPATIENT -</b></div>)
    } else {
      return (<div>[<input type="radio" name="PLocation" />] <b>INPATIENT -</b></div>)
    }
  }



  return (
    <div id="pages" className="full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975" data-active="pages " data-smooth-scrolling={1}>
      <div className="vd_body">
        {/* Header Start */}
        {/* <Header /> */}
        {/* Header Ends */}
        <div className="content">
          <div className="container">
            {/* <Navbar />     */}

            {/* Middle Content Start */}
            <div className="vd_content-wrapper" style={{ minHeight: 1059 }}>
              <div className="" style={{ minheight: 1059 }}>
                <div className="vd_content clearfix">

                  <div className="vd_content-section clearfix">
                    <div className="row">
                      {/* <div className='col-sm-4'></div> */}
                      <div className='col-sm-3'></div>
                      <div className='col-sm-6'>
                        {
                          OpenPending ?

                          <>
                            {/* <img src={ENV.DOMAIN+'resultImage/'+EditDetails.image_url} /> */}

                            <iframe src={ENV.DOMAIN+"php/printPDF.php?id="+EditDetails.qrcode} style={{width:'100%', height:1313, overflowX:"hidden"}}></iframe>
                          </>
                            :
                            <>
                              <div className="vd_register-page">
                                <div className="panel widget" style={{ minHeight: 1059 }}>
                                  <div className="panel-body" style={{borderRadius:10}}>
                                    <div className="login-icon" style={{textAlign:"center"}}>
                                      <img src={image} style={{width:200}} alt="logo" />
                                    </div>
                                    <h2 className="font-semibold text-center" style={{ fontSize: 40 }}>Pending Result</h2>
                                    <div className="form-group">
                                      <div className="col-md-12">
                                        <h4 className="text-center">
                                          <b>{EditDetails.firstname} {EditDetails.middlename} {EditDetails.lastname}</b>
                                        </h4>
                                        <h5 className="font-semibold text-center" style={{ fontSize: 20 }}>Reservation</h5>
                                        <h2 className="font-semibold text-center" style={{ fontSize: 20 }}><b>{EditDetails.resno}</b></h2>
                                        <div className="text-center"><p>Registered: {EditDetails.date_registered}</p></div>
                                        <div className="text-center">
                                          <QRCode value={ENV.DOMAIN+"renderresult/" + EditDetails.qrcode} style={{ width: 80, height: 80 }} />
                                        </div>
                                        {/* <p className="text-center"> Please <a href="index.php">click here</a> to go back to our home page or use the search form below</p> */}
                                      </div>
                                    </div>
                                    <div id="vd_login-error" className="alert alert-danger hidden"><i className="fa fa-exclamation-circle fa-fw" /> Please fill the necessary field </div>
                                  </div>
                                </div>
                              </div>
                            </>
                        }
                      </div>
                      <div className='col-sm-2'>
                        {
                          OpenPending ?
                          <></>
                          // <Pdf targetRef={printRef} filename={Date.now() + ".pdf"} x={7} y={1} scale={0.81}>
                          //   {({ toPdf }) => (
                          //     <button onClick={() => toPdf()} className="btn btn-primary">
                          //       <PictureAsPdfIcon /> Download as PDF
                          //     </button>
                          //   )}
                          // </Pdf>
                          :
                            <>
                            </>
                        }
                        {/* <div className="panel widget" style={{height:700}}>
                            <div class="panel-body" style={{borderRadius:10}}>
                              <button >Save Result</button>
                              <div style={{width:"100%"}}>
                                <SignaturePad ref={signPad} canvasProps={{width: 500, height: 200, backgroundColor:'rgba(0,0,0,0)', className: 'sigCanvas'}} />
                              </div>
                              <button onClick={()=>ClearSign()} >Clear</button>
                              <button onClick={()=>saveSign()} >Capture</button>
                            </div>
                          </div> */}
                      </div>
                    </div>
                    {/* row */}
                  </div>
                  {/* .vd_content-section */}
                </div>
                {/* .vd_content */}
              </div>
              {/* .vd_container */}
            </div>
            {/* .vd_content-wrapper */}
            {/* Middle Content End */}
          </div>
          {/* .container */}
        </div>
        {/* .content */}
        {/* Footer Start */}
        <footer className="footer-1" id="footer">
          <div className="vd_bottom ">
            <div className="container">
              <div className="row">
                <div className=" col-xs-12">
                  <div className="copyright">
                    Copyright ©2014 Venmond Inc. All Rights Reserved
                  </div>
                </div>
              </div>{/* row */}
            </div>{/* container */}
          </div>
        </footer>
        {/* Footer END */}
      </div>
      {/* .vd_body END  */}
      <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
      {/*
    <a class="back-top" href="#" id="back-top"> <i class="icon-chevron-up icon-white"> </i> </a> */}
      {/* Javascript =============================================== */}
      {/* Placed at the end of the document so the pages load faster */}
      {/*[if lt IE 9]>
      
    <![endif]*/}
    </div>

  )
}

export default RenderResult
