import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import {
    useParams,
    useHistory
} from "react-router-dom";
import validator from 'validator'
import image from './logo-no-background-001.png';
import COUNTRY from './world_countries/Country';
import STATES from './world_countries/States';
import ENV from './ENV';
import QRCode from "qrcode.react";
import QRCodes from 'qrcode'
import swal from 'sweetalert';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import html2canvas from "html2canvas";
import { styled, Box, borderRadius } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import MenuIcon from '@mui/icons-material/Menu';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { format } from 'date-fns'



import LaboratoryTable from './Departmental/Laboratory';
import CTScan from './Departmental/CTScan';
import OperatingRoomTable from './Departmental/OperatingRoom';
import UltrasoundTable from './Departmental/Ultrasound';
import Xray from './Departmental/Xray';
import Pulmonary from './Departmental/Pulmonary';
import { useMemo } from 'react';
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';
// 1110963309746630



const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
    width: 600,
    height: 700,
    bgcolor: '#fff',
    // border: '2px solid #000',
    p: 1,
    // px: 4,
    // pb: 3,
};









const Homepage_medicalpackages = () => {

    const history = useHistory();

    const [packages, setPackages] = useState([])

    useMemo(async () => {
        var formData = new FormData();
        formData.append("medical_packages", null);
        await axios({
            method: "post",
            url: ENV.DOMAIN + "php/medical_packages.php",
            data: formData,
        })
            .then((res) => {
                setPackages(res.data)
            });
    }, [ENV])


    return (
        <div>
            <div>
                {/*<![endif]*/}{/* Specific Page Data */}{/* End of Data */}{/* Mirrored from www.venmond.com/demo/vendroid/layouts-simple.php by HTTrack Website Copier/3.x [XR&CO'2014], Fri, 06 Jan 2023 21:43:15 GMT */}{/* Added by HTTrack */}<meta httpEquiv="content-type" content="text/html;charset=UTF-8" />{/* /Added by HTTrack */}
                <meta charSet="utf-8" />
                <title>BQMHospital</title>
                <meta name="keywords" content="BQMHospital" />
                <meta name="description" content="BQMHospital" />
                <meta name="author" content="BQMHospital" />
                {/* Set the viewport width to device width for mobile */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                {/* Fav and touch icons */}
                <link rel="apple-touch-icon-precomposed" sizes="144x144" href="img/ico/apple-touch-icon-144-precomposed.html" />
                <link rel="apple-touch-icon-precomposed" sizes="114x114" href="img/ico/apple-touch-icon-114-precomposed.png" />
                <link rel="apple-touch-icon-precomposed" sizes="72x72" href="img/ico/apple-touch-icon-72-precomposed.png" />
                <link rel="apple-touch-icon-precomposed" href="img/ico/apple-touch-icon-57-precomposed.png" />
                <link rel="shortcut icon" href="img/ico/favicon.png" />
                {/* CSS */}
                {/* Bootstrap & FontAwesome & Entypo CSS */}
                <link href="css/bootstrap.min.css" rel="stylesheet" type="text/css" />
                <link href="css/font-awesome.min.css" rel="stylesheet" type="text/css" />
                {/*[if IE 7]><link type="text/css" rel="stylesheet" href="css/font-awesome-ie7.min.css"><![endif]*/}
                <link href="css/font-entypo.css" rel="stylesheet" type="text/css" />
                {/* Fonts CSS */}
                <link href="css/fonts.css" rel="stylesheet" type="text/css" />
                {/* Plugin CSS */}
                <link href="plugins/jquery-ui/jquery-ui.custom.min.css" rel="stylesheet" type="text/css" />
                <link href="plugins/prettyPhoto-plugin/css/prettyPhoto.css" rel="stylesheet" type="text/css" />
                <link href="plugins/isotope/css/isotope.css" rel="stylesheet" type="text/css" />
                <link href="plugins/pnotify/css/jquery.pnotify.css" media="screen" rel="stylesheet" type="text/css" />
                <link href="plugins/google-code-prettify/prettify.css" rel="stylesheet" type="text/css" />
                <link href="plugins/mCustomScrollbar/jquery.mCustomScrollbar.css" rel="stylesheet" type="text/css" />
                <link href="plugins/tagsInput/jquery.tagsinput.css" rel="stylesheet" type="text/css" />
                <link href="plugins/bootstrap-switch/bootstrap-switch.css" rel="stylesheet" type="text/css" />
                <link href="plugins/daterangepicker/daterangepicker-bs3.css" rel="stylesheet" type="text/css" />
                <link href="plugins/bootstrap-timepicker/bootstrap-timepicker.min.css" rel="stylesheet" type="text/css" />
                <link href="plugins/colorpicker/css/colorpicker.css" rel="stylesheet" type="text/css" />
                {/* Specific CSS */}
                {/* Theme CSS */}
                <link href="css/theme.min.css" rel="stylesheet" type="text/css" />
                {/*[if IE]> <link href="css/ie.css" rel="stylesheet" > <![endif]*/}
                <link href="css/chrome.css" rel="stylesheet" type="text/chrome" /> {/* chrome only css */}
                {/* Responsive CSS */}
                <link href="css/theme-responsive.min.css" rel="stylesheet" type="text/css" />
                {/* for specific page in style css */}
                {/* for specific page responsive in style css */}
                {/* Custom CSS */}
                <link href="custom/custom.css" rel="stylesheet" type="text/css" />
                {/* Head SCRIPTS */}
                {/* HTML5 shim and Respond.js IE8 support of HTML5 elements and media queries */}
                {/*[if lt IE 9]>
  
 
<![endif]*/}
                <div className="vd_body">
                    {/* Header Start */}
                    <header className="header-2" id="header">
                        <div className="vd_top-menu-wrapper" style={{ background: "#520408" }}>
                            <div className="container">
                                <div className="vd_top-nav vd_nav-width  ">
                                    <div className="vd_panel-header">
                                        <div className="logo">
                                            <a href="index.php">
                                                {/* <img alt="logo" src="img/logo-white.png" /> */}
                                            </a>
                                        </div>
                                        {/* logo */}
                                        <div className="vd_panel-menu left-pos visible-sm visible-xs">
                                            {/* <span className="menu" data-action="toggle-navbar-left">
                                                <MenuIcon style={{color:"#ddd", fontSize:15, marginTop:6}} />
                                            </span> */}
                                        </div>

                                        <div className="vd_panel-menu visible-sm visible-xs">
                                            <span className="menu visible-xs" data-action="submenu">
                                                {/* <i className="fa fa-bars" /> */}
                                                <WidgetsIcon style={{color:"#ddd", fontSize:15, marginTop:6}} />
                                            </span>
                                        </div>
                                        {/* vd_panel-menu */}
                                    </div>
                                    {/* vd_panel-header */}
                                </div>
                                <div className="vd_container">
                                    <div className="row">
                                        <div className="col-sm-8 col-xs-12">
                                            <div className="vd_mega-menu-wrapper text-center">
                                                <div className="vd_mega-menu">
                                                    <ul className="mega-ul nav">
                                                        <li className="mega-li">
                                                            <a href="/" className="mega-link" data-waypoint="services">
                                                                <span className="menu-text">HOME</span>
                                                            </a>
                                                        </li>
                                                        <li className="hover-trigger mega-li">
                                                            <a href="#" className="mega-link" data-action="click-trigger">
                                                                <span className="menu-text">SERVICES</span>
                                                            </a>
                                                            <div className="vd_mega-menu-content  width-xs-2  right-xs hover-target no-top" data-action="click-target" style={{ display: 'none' }}>
                                                                <div className="child-menu">
                                                                    <div className="content-list content-menu">
                                                                        <ul className="list-wrapper">
                                                                            <li> <a style={{ cursor: "pointer" }} onClick={() => history.push('/form')}> APPOINTMENT FOR RT-PCR TEST</a> </li>
                                                                            <li> <a href="/services/pricing"> DEPARTMENTAL ITEM PRICE LIST</a> </li>
                                                                            <li>
                                                                                <a href="/services/room/rates">ROOM RATES</a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="/services/medical/packages">MEDICAL PACKAGES</a>
                                                                            </li>
                                                                            {/* <li> <a href="/services/pricing"> DEPARTMENTAL ITEM PRIVATE LIST</a> </li> */}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="mega-li">
                                                            <a href="/services/contact" className="mega-link" data-waypoint="services">
                                                                <span className="menu-text">CONTACT</span>
                                                            </a>
                                                        </li>
                                                        <li className="mega-li">
                                                            <a href="/services/about" className="mega-link" data-waypoint="services">
                                                                <span className="menu-text">ABOUT</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    {/* Head menu search form ends */}                       </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* container */}
                        </div>
                        {/* vd_primary-menu-wrapper */}
                    </header>
                    {/* Header Ends */}
                    <div className="">
                        <div className="">
                            {/* Middle Content Start */}
                            <div className="" style={{ minHeight: 1000 }}>
                                <div className="" style={{ minHeight: 1000 }}>
                                    <div className="vd_content clearfix">
                                        {/* vd_head-section */}
                                        <div className="vd_title-section clearfix">
                                            <div className="vd_panel-header visible-xs">
                                                <h4>MEDICAL PACKAGES</h4>
                                                {/* <small className="subtitle">Simple Layouts with Minimal Button</small>  */}
                                            </div>
                                            <div className="vd_panel-header hidden-xs">
                                                <h1>MEDICAL PACKAGES</h1>
                                                {/* <small className="subtitle">Simple Layouts with Minimal Button</small>  */}
                                            </div>
                                        </div>
                                        {/* vd_title-section */}
                                        <div className="vd_content-section clearfix hidden-xs" style={{minHeight: '100vh', padding:1}}>
                                            <table className='table table-bordered table-hover'>
                                                <thead style={{backgroundColor:"#520408", color:"#fff"}}>
                                                    <tr>
                                                        <th>PACKAGES ID</th>
                                                        <th>DESCRIPTION</th>
                                                        <th>DOCTORS PROFESSIONAL FEE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        packages.map((item, key)=>{
                                                            return (
                                                                <tr>
                                                                    <td>{item.package_id}</td>
                                                                    <td>{item.description}</td>
                                                                    <td>{item.professional_fee}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="vd_content-section clearfix visible-xs" style={{minHeight: '100vh', padding:null}}>
                                            
                                            <div style={{width:"100%", display:"flex", flexDirection:"column", gap:5}}>
                                                    {
                                                        packages.map((item, key)=>{
                                                            return (
                                                                <div style={{width:"100%", backgroundColor:"#fff", borderRadius:10, border:"1px solid #a6a6a6"}}>
                                                                    <div style={{padding:5, fontWeight:"bold", borderBottom:"1px solid #ddd", backgroundColor:"#3c3b54", color:"#fff", borderTopLeftRadius:10, borderTopRightRadius:10}}>{item.package_id}</div>
                                                                    <div style={{padding:5, fontWeight:"bold", borderBottom:"1px solid #ddd"}}>{item.description}</div>
                                                                    <div style={{padding:5}}>
                                                                        <small style={{fontSize:9, color:"#520408"}}>DOCTORS PROFESSIONAL FEE</small>
                                                                        <div>{item.professional_fee}</div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                            </div>
                                            
                                        </div>
                                        {/* .vd_content-section */}
                                    </div>
                                    {/* .vd_content */}
                                </div>
                                {/* .vd_container */}
                            </div>
                            {/* .vd_content-wrapper */}
                            {/* Middle Content End */}
                        </div>
                        {/* .container */}
                    </div>
                    {/* .content */}
                    {/* Footer Start */}
                    {/* <footer className="footer-2" id="footer">
                        <div className="vd_bottom ">
                            <div className="container">
                                <div className="row">
                                    <div className=" col-xs-12">
                                        <div className="copyright text-center">
                                            Copyright ©2014 Venmond Inc. All Rights Reserved
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer> */}
                    {/* Footer END */}
                </div>
                {/* .vd_body END  */}
                <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
                {/*
<a class="back-top" href="#" id="back-top"> <i class="icon-chevron-up icon-white"> </i> </a> */}
                {/* Javascript =============================================== */}
                {/* Placed at the end of the document so the pages load faster */}
                {/*[if lt IE 9]>

<![endif]*/}
            </div>



        </div>

    )
}




export default Homepage_medicalpackages
