import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import Header from './Extra/Header'
import Navbar from './Extra/Navbar'
import swal from 'sweetalert'
import ENV from "./ENV";
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';

const AdminEditAppointment = () => {
  const isPosition = sessionStorage.getItem("isPosition")

  const history = useHistory()
  const { id } = useParams();
  if(isPosition === 'SECRETARY' || isPosition === 'SWABBER' || isPosition === 'BDC' || isPosition === 'INFO'){
    history.push('/admin/appointment')
  } else {

  }









  // console.log(id)

  const [EditDetails, setEditDetails] = useState('')



  ///////////////////////////////////

  const [errorFirstname, setErrorFirstname] = useState(null)
  const [errorMiddlename, setErrorMiddlename] = useState(null)
  const [errorLastname, setErrorLastname] = useState(null)
  const [errorBirthday, setErrorBirthday] = useState(null)
  const [errorAge, setErrorAge] = useState({ height: 38 })
  const [errorContact, setErrorContact] = useState(null)
  const [errorEmail, setErrorEmail] = useState(null)
  const [errorCountry, setErrorCountry] = useState(null)
  const [errorProvince, setErrorProvince] = useState(null)
  const [errorCity, setErrorCity] = useState(null)
  const [errorOccupation, setErrorOccupation] = useState(null)
  const [errorGender, setErrorGender] = useState(null)
  const [errorPurpose, setErrorPurpose] = useState(null)
  const [errorValidity, setErrorValidity] = useState(null)
  const [errorPurposeDescription, setErrorPurposeDescription] = useState({ resize: "none" })


  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [lastname, setLastname] = useState('');
  const [suffix, setSuffix] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [gender, setGender] = useState('');
  const [validity, setValidity] = useState('');
  const [Purpose, setPurpose] = useState('');
  const [birthday, setBirthday] = useState('');
  const [age, setAge] = useState('');
  const [civilstatus, setCivilStatus] = useState('');


  const [TextOPD, setTextOPD] = useState('');
  const [TextTRAVEL, setTextTRAVEL] = useState('');
  const [TextCOMPANY, setTextCOMPANY] = useState('');
  const [TextINPATIENT, setTextINPATIENT] = useState('');


  const [Symptoms, setSymptoms] = useState('')
  const [ScheduleSwabbing, setScheduleSwabbing] = useState('')
  const [SwabbingTime, setSwabbingTime] = useState('')
  const [SymptomsShowTextField, setSymptomsShowTextField] = useState(false)
  const [SymptomsDescription, setSymptomsDescription] = useState('')
  const [PurposeDescription, setPurposeDescription] = useState('')
  const [Occupation, setOccupation] = useState('')


  
  const [imageDisplayOne, setimageDisplayOne] = useState(null)
  const [imageDisplayTwo, setimageDisplayTwo] = useState(null)
  // console.log(address)

  const [EditDetailsShow, setEditDetailsShow] = useState(false)
  const [onOption, setOption] = useState('');

  const countryid = EditDetails.countryid;
  const provinceid = EditDetails.provinceid;
  const cityid = EditDetails.cityid;

  useEffect(() => {
    DisplayData();
    fetchCountries();
    fetchState(countryid ? countryid : 0);
    fetchCities();
    fetchLocalProvince()
  }, [countryid])



  const [GetCountry, setGetCountry] = useState(0);
  const [GetState, setGetState] = useState(0);
  const [GetCity, setGetCity] = useState(0);

  const [callState, setState] = useState([]);
  
  const [callCities, setCities] = useState([]);
  const [dataCountries, setdataCountries] = useState([]);
  const fetchCountries = async () => {
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchCountries.php',
    })
      .then((res) => {
        setdataCountries(res.data)
        // fetchState(EditDetails.countryid)
        // console.log(EditDetails.countryid)
      }).catch((err) => {
        console.log(err)
      })
  }

  const fetchState = async (e) => {
    setCountry(e)
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchCountriesState.php?id=' + e,
    })
      .then((res) => {
        if (res.data.length > 0) {
          setState(res.data)
        }
      }).catch((err) => {
        console.log(err)
      })
  }

  const fetchCities = async (e) => {
    setProvince(e);
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchCountriesStateCities.php',
    })
      .then((res) => {
        setCities(res.data)
      }).catch((err) => {
        console.log(err)
      })
  }




  const [errorCountries, setErrorCountries] = useState({ height: 38 });
  const [errorState, setErrorState] = useState({ height: 38 });

  const [onLocalProvince, setLocalProvince] = useState([]);
  const [onLocalProvinceValue, setLocalProvinceValue] = useState('');
  const [onLocalCity, setLocalCity] = useState([]);
  const [onLocalCityValue, setLocalCityValue] = useState('');
  const [onLocalBarangay, setLocalBarangay] = useState([]);
  const [onLocalBarangayValue, setLocalBarangayValue] = useState('');






  const DisplayData = () => {
    axios.get(ENV.DOMAIN + 'php/getAppointment.php?id=' + id)
      .then((res) => {

        setFirstname(res.data[0].firstname)
        setMiddlename(res.data[0].middlename)
        setLastname(res.data[0].lastname)
        setSuffix(res.data[0].suffix)
        setCivilStatus(res.data[0].civil_status)
        setContact(res.data[0].contact)
        setBirthday(res.data[0].birthday)
        setAge(res.data[0].age)
        setGender(res.data[0].gender)
        setEmail(res.data[0].email)
        setAddress(res.data[0].address)

            
        setTextOPD(res.data[0].OPD)
        setTextTRAVEL(res.data[0].TRAVEL)
        setTextCOMPANY(res.data[0].COMPANY)
        setTextINPATIENT(res.data[0].INPATIENT)
        
        setEditDetails(res.data[0])
        setOption(res.data[0].inter_local)
        setProvince(res.data[0].provinceid)
        setCity(res.data[0].cityid)
        onFindCity(res.data[0].provinceid)
        onFindBarangay(res.data[0].cityid);


        setLocalCityValue(res.data[0].cityid)
        setLocalProvinceValue(res.data[0].provinceid)
        setLocalBarangayValue(res.data[0].barangayid);
        setPurpose(res.data[0].purpose);
        setSymptoms(res.data[0].Symptoms)
        onSymptoms(res.data[0].Symptoms)
        setOccupation(res.data[0].Occupation);
        // console.log(res.data[0].SymptomsShowTextField);
        setSymptomsDescription(res.data[0].SymptomsDescription)
        setPurposeDescription(res.data[0].PurposeDescription)
        setScheduleSwabbing(res.data[0].ScheduleSwabbing)
        setSwabbingTime(res.data[0].SwabbingTime)
        setimageDisplayOne(ENV.DOMAIN+'php/images/'+res.data[0].imageDisplayOne)
      }).catch((err) => {
        console.log(err)
      })
  }
  // console.log(province)
  const onCancelEdit = (id) => {
    setEditDetailsShow(false);
    history.push('/bqmadminappoint')
  }
  const [ErrorTextOPD, setErrorTextOPD] = useState({height: 38, width:"100%", border: '1px solid #d5d5d5' }); 
  const [ErrorTextTRAVEL, setErrorTextTRAVEL] = useState({height: 38, width:361, border: '1px solid #d5d5d5' }); 
  const [ErrorTextCOMPANY, setErrorTextCOMPANY] = useState({height: 38, width:361, border: '1px solid #d5d5d5' }); 
  const [ErrorTextINPATIENT, setErrorTextINPATIENT] = useState({height: 38, width:361, border: '1px solid #d5d5d5' }); 

  const onUpdate = async () => {
    var formData = new FormData();

    formData.append('id', EditDetails.id);
    formData.append('resno', EditDetails.resno);
    formData.append('firstname', firstname);
    formData.append('middlename', middlename);
    formData.append('lastname', lastname);
    formData.append('suffix', suffix);
    formData.append('birthday', birthday);
    formData.append('age', age);
    formData.append('contact', contact);
    formData.append('email', email ? email : EditDetails.email);
    formData.append('inter_local', onOption);
    formData.append('country', country ? country : EditDetails.countryid);
    formData.append('province', province ? province : EditDetails.provinceid);
    formData.append('city', city ? city : EditDetails.cityid);
    formData.append('localProvinceValue', onLocalProvinceValue);
    formData.append('localCityValue', onLocalCityValue);
    formData.append('localBarangayValue', onLocalBarangayValue);
    formData.append('address', address ? address : EditDetails.address);
    formData.append('civilstatus', civilstatus ? civilstatus : EditDetails.civil_status);
    formData.append('Occupation', Occupation);
    formData.append('gender', gender ? gender : EditDetails.gender);
    formData.append('validity', validity ? validity : EditDetails.validity);
    formData.append('purpose', Purpose ? Purpose : EditDetails.purpose);
    formData.append('TextOPD', TextOPD)
    formData.append('TextTRAVEL', TextTRAVEL)
    formData.append('TextCOMPANY', TextCOMPANY)
    formData.append('TextINPATIENT', TextINPATIENT)
    formData.append('PurposeDescription', PurposeDescription);
    formData.append('Symptoms', Symptoms ? Symptoms : EditDetails.Symptoms);
    formData.append('SymptomsShowTextField', SymptomsShowTextField ? SymptomsShowTextField : EditDetails.SymptomsShowTextField);
    formData.append('SymptomsDescription', SymptomsDescription);
    formData.append('ScheduleSwabbing', ScheduleSwabbing);
    formData.append('SwabbingTime', SwabbingTime);
    formData.append('imageDisplayOne', imageDisplayOne);

    if(Purpose == 'OPD')
    {
      var toProposeDesc = true;
      // if(TextOPD != ''){
      //   var toProposeDesc = true;
      //   setErrorTextOPD({height: 38, width:487.66, border: '1px solid #d5d5d5' })
      // } else {
      //   var toProposeDesc = false;
      //   setErrorTextOPD({height: 38, width:487.66, border: '1px solid red' })
      // }
    } else if(Purpose == 'TRAVEL')
    {
      if(TextTRAVEL != ''){
        var toProposeDesc = true;
        setErrorTextTRAVEL({height: 38, width:487.66, border: '1px solid #d5d5d5' })
      } else {
        var toProposeDesc = false;
        setErrorTextTRAVEL({height: 38, width:487.66, border: '1px solid red' })
      }
    } else if(Purpose == 'COMPANY')
    {
      if(TextCOMPANY != ''){
        var toProposeDesc = true;
        setErrorTextCOMPANY({height: 38, width:487.66, border: '1px solid #d5d5d5' })
      } else {
        var toProposeDesc = false;
        setErrorTextCOMPANY({height: 38, width:487.66, border: '1px solid red' })
      }
    } else if(Purpose == 'INPATIENT')
    {
      if(TextINPATIENT != ''){
        var toProposeDesc = true;
        setErrorTextINPATIENT({height: 38, width:487.66, border: '1px solid #d5d5d5' })
      } else {
        var toProposeDesc = false;
        setErrorTextINPATIENT({height: 38, width:487.66, border: '1px solid red' })
      }
    }
    

    let Symptomss;
    if(Symptoms == 'With Symptoms') {
      if(SymptomsDescription != '') {
        Symptomss = true;
      } else {
        Symptomss = false;
        if (SymptomsDescription == '') {
          setErrorPurposeDescription({ resize: "none", border: '1px solid red' })
        }
      }
    } else {
      Symptomss = true;
    }

    let countryAdd;
    if(onOption == 'Local') {
      if(onLocalProvinceValue!=''){
        if(onLocalCityValue!=''){
          // if(onLocalBarangayValue!=''){
            countryAdd = true;
          // } else {
          //   countryAdd = false;
          // }
        } else {
          countryAdd = false;
        }
      } else {
        countryAdd = false;
      }
    } else {
      if(country!=''){
        if(province!=''){
          countryAdd = true;
        } else {
          countryAdd = false;
        }
      } else {
        countryAdd = false;
      }
    }

    if (firstname != '' && middlename != '' && lastname != '' && suffix != '' && birthday != '' && email != '' && Occupation != '' && age != ''  && gender != '' && Purpose != '' && Symptomss && countryAdd && toProposeDesc) {

      await axios({
        method: 'post',
        url: ENV.DOMAIN + 'php/updateAppointment.php',
        data: formData
      }).then((res) => {
        swal({
          title: "Done!",
          text: "Successfully Updated",
          icon: "success",
          timer: 2000,
          button: false
        }).then(() => {
          history.goBack();
        })
      }).catch((err) => {
        console.log(err)
      })
    } else {

      if (firstname == '') {
        setErrorFirstname({ border: '1px solid red' })
      }
      if (middlename == '') {
        setErrorMiddlename({ border: '1px solid red' })
      }
      if (lastname == '') {
        setErrorLastname({ border: '1px solid red' })
      }
      if (birthday != '') {
        setErrorBirthday({ height: 38, border: '1px solid red' })
      }
      if (contact == '') {
        setErrorContact({ border: '1px solid red' })
      }
      if (email == '') {
        setErrorEmail({ border: '1px solid red' })
      }
      if (country == '') {
        setErrorCountry()
      }
      if (province == '') {
        setErrorProvince()
      }
      if (city != '') {
        setErrorCity()
      }
      if( Occupation==''){
        setErrorOccupation({ border: '1px solid red' })
      }
      if (age == '') {
        setErrorAge({ height: 38, border: '1px solid red' })
      }
      if (gender == '') {
        setErrorGender({ border: '1px solid red' })
      }
      if (validity == '') {
        setErrorValidity()
      }
      if (Purpose == '') {
        setErrorPurpose()
      }
      swal({
        title: "",
        text: "Incomplete Details",
        icon: "error",
        timer: 2000,
        button: false
      })
    }
  }

  const onBack = (value) => {
    history.push('/bqmadminappoint')
  }

  const PurposeSelect = () => {
    if (EditDetails.purpose === 'Travel') {
      return 'Travel';
    } else if (EditDetails.purpose === 'Employment') {
      return 'Employment';
    } else {
      return 'Others';
    }
  }

  const selectOption = (e) => {
    setOption(e);

    setGetCountry('');
    setGetState('');
    setGetCity('');
    setLocalProvinceValue('');
    setLocalCityValue('');
    setLocalBarangayValue('');
  }




  const fetchLocalProvince = async () => {
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchLocalProvince.php',
    })
      .then((res) => {
        setLocalProvince(res.data)
      }).catch((err) => {

      })
  }


  const onFindCity = async (value) => {
    setLocalProvinceValue(value);
    setLocalBarangay([]);
    setLocalCity([])
    setLocalCityValue('')
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchLocalCity.php?provCode=' + value,
    })
      .then((res) => {
        setLocalCity(res.data)
      }).catch((err) => {

      })
  }

  const onFindBarangay = async (value) => {
    // setLocalCityId(value); 
    setLocalBarangay([]);
    setLocalCityValue(value)
    setLocalBarangayValue('')
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchLocalBarangay.php?citymunCode=' + value,
    })
      .then((res) => {
        setLocalBarangay(res.data)
      }).catch((err) => {

      })
  }

  const onBarangay = (value) => {
    setLocalBarangayValue('')
    setLocalBarangayValue(value)
    console.log(onOption, onLocalProvinceValue, onLocalCityValue, value)
  }










  const displayAddressSelection = (value) => {
    if (value === 'International') {
      return (
        <div className="form-group">
          <div className="col-md-4">
            <div className="label-wrapper">
              <label className="control-label">Countries <span className="vd_red">*</span></label>
            </div>
            <select style={errorCountries} onChange={(e) => fetchState(e.target.value)} value={country ? country : EditDetails.country}>
              <option value="" disabled selected>Select...</option>
              {
                dataCountries.map((data) => {
                  return (<option value={data.id}>{data.country}</option>)
                })
              }
            </select>
          </div>
          <div className="col-md-4">
            <div className="label-wrapper">
              <label className="control-label">Province / State <span className="vd_red">*</span></label>
            </div>
            <select style={errorState} onChange={(e) => fetchCities(e.target.value)} value={province ? province : EditDetails.province}>
              <option value="" disabled selected>Select...</option>
              {
                callState.map((data) => {
                  return (<option value={data.id}>{data.name}</option>)
                })
              }
            </select>
          </div>
          <div className="col-md-12">
            <div className="label-wrapper">
              <label className="control-label">Address (City / Street / Block / Lot No / Subd.)  <span className="vd_red">*</span></label>
            </div>
            <textarea style={{ resize: "none" }} onChange={(e) => setAddress(e.target.value)} value={address} placeholder="City / Street / Block / Lot No / Subd."></textarea>
          </div>
        </div>
      )
    } else {
      return (
        <>
          <div className="form-group">
            <div className="col-md-4">
              <div className="label-wrapper">
                <label className="control-label">Province / State <span className="vd_red">*</span></label>
              </div>
              <select style={{ height: 38 }} onChange={(e) => onFindCity(e.target.value)} value={onLocalProvinceValue ? onLocalProvinceValue : ''}>
                <option value="" disabled selected>Select...</option>
                {
                  onLocalProvince.map((data) => {
                    return (<option value={data.id}>{data.provDesc}</option>)
                  })
                }
              </select>
            </div>

            <div className="col-md-4">
              <div className="label-wrapper">
                <label className="control-label">Municipality / City <span className="vd_red">*</span></label>
              </div>
              <select style={{ height: 38 }} onChange={(e) => onFindBarangay(e.target.value)} value={onLocalCityValue ? onLocalCityValue : ''}>
                <option value="" disabled selected>Select...</option>
                {
                  onLocalCity.map((data) => {
                    return (<option value={data.id}>{data.citymunDesc}</option>)
                  })
                }
              </select>
            </div>

            <div className="col-md-4">
              <div className="label-wrapper">
                <label className="control-label">Barangay <span className="vd_red">*</span></label>
              </div>
              <select style={{ height: 38 }} onChange={(e) => onBarangay(e.target.value)} value={onLocalBarangayValue ? onLocalBarangayValue : ''}>
                <option value="" disabled selected>Select...</option>
                <option value="0">Out of List</option>
                {
                  onLocalBarangay.map((data) => {
                    return (<option value={data.id}>{data.brgyDesc}</option>)
                  })
                }
              </select>
            </div>
          </div>
          <div className="form-group">
            <div className="col-md-12">
              <div className="label-wrapper">
                <label className="control-label">Address (Street / Block / Lot No. / Subd.) <span className="vd_red">*</span></label>
              </div>
              <textarea style={{ resize: "none" }} onChange={(e) => setAddress(e.target.value)} value={address} placeholder="City / Street / Block / Lot No / Subd."></textarea>
            </div>
          </div>
        </>
      )
    }
  }


  const ActionPurpose = (purpose) => {
    // console.log(purpose)
    if(purpose == 'OPD'){
      return (
        <div className="col-md-6">
          <div className="label-wrapper">
            <label className="control-label">Pusrpose: Specify<span className="vd_red">*</span></label>
          </div>
          <input placeholder="" onChange={(e)=>setTextOPD(e.target.value)} value={TextOPD} style={ErrorTextOPD} />
        </div>
      )
    } else if(purpose == 'INPATIENT'){
      return (
        <div className="col-md-6">
          <div className="label-wrapper">
            <label className="control-label">Room Number<span className="vd_red">*</span></label>
          </div>
          <input type="text" onChange={(e)=>setTextINPATIENT(e.target.value)} value={TextINPATIENT} placeholder="Room Number" style={ErrorTextINPATIENT} autocomplete="off" />
        </div>
      )
    } else if(purpose == 'COMPANY'){
      return (
        <div className="col-md-6">
          <div className="label-wrapper">
            <label className="control-label">Company Name<span className="vd_red">*</span></label>
          </div>
          <input type="text" onChange={(e)=>setTextCOMPANY(e.target.value)} value={TextCOMPANY} placeholder="Company Name" style={ErrorTextCOMPANY} autocomplete="off" />
        </div>
      )
    } else if(purpose == 'TRAVEL'){
      return (
        <div className="col-md-6">
          <div className="label-wrapper">
            <label className="control-label">Date/Time of Flight<span className="vd_red">*</span></label>
          </div>
          <input type="datetime-local" onChange={(e)=>setTextTRAVEL(e.target.value)} value={TextTRAVEL} placeholder="Date/Time of Flight" style={ErrorTextTRAVEL} />
        </div>
      )
    }




    // if(value === 'Others'){
      // setPurpose(value)
    //   setDevPurpose(true);
    // } else {
    //   setPurpose(value)
    //   setDevPurpose(false);
    // }
  }

  const onSymptoms = (value) => {
    if (value === 'With Symptoms') {
      setSymptomsShowTextField(true)
    } else if (value === 'Without Symptoms') {
      setSymptomsShowTextField(false)
      setSymptomsDescription('')
    }
    setSymptoms(value)
  }

  const SymptomsInputField = (value) => {
    if(value === true) {
      return (
        <div className="form-group">
          {/* Symptoms */}
          <div className="col-md-12">
            <div className="label-wrapper">
              <label className="control-label">Symptoms <span className="vd_red">*</span></label>
            </div>
            <textarea placeholder="Symptoms" onChange={(e) => setSymptomsDescription(e.target.value)} value={SymptomsDescription} style={errorPurposeDescription}></textarea>
          </div>
        </div>
      )
    }
  }


	const changeHandlerImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
 
      reader.onload = (e) => {
        setimageDisplayOne(e.target.result)
        console.log(e.target.result)
      }
    }
	};
	const changeHandlerImageTwo = (event) => {
    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
 
      reader.onload = (e) => {
        setimageDisplayTwo(e.target.result)
        // console.log(e.target.result)
      }
    }
	};
  // console.log(SwabbingTime)


  const vd_container = (isPosition) => {
    if(isPosition === 'SWABBER' || isPosition === 'STAFF'){
        return '';
    } else {
        return 'vd_container';
    }
}

const pad = (number, length) => {
  var str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
}

const disabledDates = () => {
  var today, dd, mm, yyyy;
  today = new Date();
  dd = pad(today.getDate() + 0, 2);
  mm = pad(today.getMonth() + 1, 2);
  yyyy = today.getFullYear();
  return yyyy + '-' + mm + '-' + dd;
}

  return (
    <div id="pages" className="full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975" data-active="pages " data-smooth-scrolling={1}>
      <div className="vd_body">
        {/* Header Start */}
        <Header />
        {/* Header Ends */}
        <div className="content">
          <div className="container">
            <Navbar isPosition={isPosition} />

            {/* Middle Content Start */}
            <div className="vd_content-wrapper" style={{ minHeight: 2000 }}>
              <div className={vd_container(isPosition)} style={{ height: 2000 }}>
                <div className="vd_content clearfix">
                  <div className="vd_head-section clearfix">
                    <div className="vd_panel-header">
                      
                    </div>
                  </div>
                  <div className="vd_title-section clearfix">
                    <div className="vd_panel-header no-subtitle">
                      <h1>Edit Information</h1>
                    </div>
                  </div>
                  <div className="vd_content-section clearfix">
                    <div className="row">
                      <div className='col-sm-2'></div>
                      <div className='col-sm-8'>
                        <div className="vd_register-page">
                          <div className="panel widget" style={{ height: 2000 }}>
                            <div className="panel-body">
                              <form className="form-horizontal" action="functions/register-form.php" role="form" id="register-form">
                                <div className="form-group">
                                  <div className="col-md-4">
                                    <div className="label-wrapper">
                                      <label className="control-label">Firstname <span className="vd_red">*</span></label>
                                    </div>
                                    <input type="text" placeholder="" style={errorFirstname} className="required" required="" onChange={(e) => setFirstname(e.target.value)} value={firstname} />
                                  </div>

                                  <div className="col-md-3">
                                    <div className="label-wrapper">
                                      <label className="control-label">Middlename <span className="vd_red">*</span></label>
                                    </div>
                                    <input type="text" placeholder="" style={errorMiddlename} className="required" required="" value={middlename} onChange={(e) => setMiddlename(e.target.value)} />
                                  </div>

                                  <div className="col-md-3">
                                    <div className="label-wrapper">
                                      <label className="control-label">Lastname <span className="vd_red">*</span></label>
                                    </div>
                                    <input type="text" placeholder="" style={errorLastname} className="required" required="" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                                  </div>

                                  <div className="col-md-2">
                                    <div className="label-wrapper">
                                      <label className="control-label">Suffix <span className="vd_red">*</span></label>
                                    </div>
                                    <select style={{ height: 38 }} onChange={(e) => setSuffix(e.target.value)} value={suffix}>
                                      <option value="" disabled selected>Select...</option>
                                      <option value="None">None</option>
                                      <option value="Jr">Jr</option>
                                      <option value="Sr">Sr</option>
                                      <option value="II">II</option>
                                      <option value="III">III</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="col-md-4">
                                    <div className="label-wrapper">
                                      <label className="control-label">Civil Status <span className="vd_red">*</span></label>
                                    </div>
                                    <select style={{ height: 38 }} onChange={(e) => setCivilStatus(e.target.value)} value={civilstatus}>
                                      <option value="" disabled selected>Select...</option>
                                      <option value="Single">Single</option>
                                      <option value="Married">Married</option>
                                      <option value="Separated">Separated</option>
                                      <option value="Divorced">Divorced</option>
                                      <option value="Widowed">Widowed</option>
                                    </select>
                                    {/* {emailError} */}
                                  </div>
                                  <div className="col-md-4">
                                    <div className="label-wrapper">
                                      <label className="control-label">Contact <span className="vd_red">*</span></label>
                                    </div>
                                    <input type="text" placeholder="" style={errorContact} className="required" required="" value={contact} onChange={(e) => setContact(e.target.value)} />
                                  </div>

                                  <div className="col-md-4">
                                    <div className="label-wrapper">
                                      <label className="control-label">Email <span className="vd_red">*</span></label>
                                    </div>
                                    <input type="text" placeholder="" style={errorEmail} className="required" required="" value={email} onChange={(e) => setEmail(e.target.value)} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="col-md-4">
                                    <div className="label-wrapper">
                                      <label className="control-label">Birthday <span className="vd_red">*</span></label>
                                    </div>
                                    <input type="date" placeholder="" style={{ height: 38 }} className="required" required="" value={birthday ? birthday : EditDetails.birthday} onChange={(e) => setBirthday(e.target.value)} />
                                  </div>
                                  <div className="col-md-2">
                                    <div className="label-wrapper">
                                      <label className="control-label">Age <span className="vd_red">*</span></label>
                                    </div>
                                    <input type="number" placeholder="" min={0} style={errorAge} className="required" required="" value={age} onChange={(e) => setAge(e.target.value)} />
                                  </div>
                                  {/* <div className="col-md-2">
                                                  <div className="label-wrapper">
                                                      <label className="control-label">Age <span className="vd_red">*</span></label>
                                                  </div>
                                                  <input type="text" placeholder="" readOnly style={{height:38}} className="required" required="" value={birthday ? birthday : EditDetails.birthday} onChange={(e)=>setBirthday(e.target.value)} />
                                              </div> */}
                                  <div className="col-md-2">
                                    <div className="label-wrapper">
                                      <label className="control-label">Gender<span className="vd_red">*</span></label>
                                    </div>
                                    <select
                                      style={{ height: 38 }}
                                      value={gender ? gender : EditDetails.gender}
                                      onChange={(e) => setGender(e.target.value)}>
                                      <option value="" selected disabled>Select</option>
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                    </select>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="label-wrapper">
                                      <label className="control-label">Occupation <span className="vd_red">*</span></label>
                                    </div>
                                    <input type="text" placeholder="" style={errorOccupation} className="required" required="" value={Occupation} onChange={(e) => setOccupation(e.target.value)} />
                                  </div>
                                </div>





                                <div className="form-group">
                                  <div className="col-md-4">
                                    <div className="label-wrapper">
                                      <label className="control-label">International / Local <span className="vd_red">*</span></label>
                                    </div>
                                    <select style={{ height: 38 }} onChange={(e) => selectOption(e.target.value)} value={onOption}>
                                      <option value="Local">Local</option>
                                      <option value="International">International</option>
                                    </select>
                                  </div>
                                </div>
                                {displayAddressSelection(onOption ? onOption : EditDetails.inter_local)}
                                <div className="form-group">
                                  <div className="col-md-3">
                                    <div className="label-wrapper">
                                      <label className="control-label">Symptoms <span className="vd_red">*</span></label>
                                    </div>
                                    <select
                                      style={{ height: 38 }}
                                      onChange={(e) => onSymptoms(e.target.value)} value={Symptoms}>
                                      <option value="" disabled selected>Select...</option>
                                      <option value={"With Symptoms"}>With Symptoms</option>
                                      <option value={"Without Symptoms"}>Without Symptoms</option>
                                    </select>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="label-wrapper">
                                      <label className="control-label">Schedule for Swabbing <span className="vd_red">*</span></label>
                                    </div>
                                    <input type="date" placeholder="" className="required" required
                                      style={{ height: 38 }}
                                      value={ScheduleSwabbing}
                                      onChange={(e) => setScheduleSwabbing(e.target.value)} min={disabledDates()} />
                                  </div>
                                  <div className="col-md-3">
                                    <div className="label-wrapper">
                                      <label className="control-label">Swabbing Time <span className="vd_red">*</span></label>
                                    </div>
                                    <select style={{height:38}} onChange={(e)=>setSwabbingTime(e.target.value)} value={SwabbingTime}>
                                      <option value="">Select...</option>
                                      <option value="6:00AM-8:00AM">6:00AM-8:00AM</option>
                                      <option value="10:00AM-12:00NN">10:00AM-12:00NN</option>
                                      <option value="8:00PM-10:00PM">8:00PM-10:00PM</option>
                                    </select>
                                  </div>
                                </div>
                                {SymptomsInputField(SymptomsShowTextField)}
                                <div className="form-group">
                                  <div className="col-md-6">
                                    <div className="label-wrapper">
                                      <label className="control-label">Purpose<span className="vd_red">*</span></label>
                                    </div>
                                    <select
                                    style={{height:38}}
                                    value={Purpose}
                                    onChange={(e)=>setPurpose(e.target.value)}>
                                      <option value="" disabled selected>Select...</option>
                                      <option value="OPD">OPD</option>
                                      <option value="INPATIENT">INPATIENT</option>
                                      <option value="COMPANY">COMPANY</option>
                                      <option value="TRAVEL">TRAVEL</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group">
                                  {ActionPurpose(Purpose)}
                                </div>    
                                <div className="form-group">
                                  <div className="col-md-4">
                                    <label style={{ padding: 5, borderRadius: 5, border: '1px solid #ddd', width: 300, margin: '0 auto', textAlign:"center" }}>
                                      <img src={imageDisplayOne ? imageDisplayOne : ENV.DOMAIN+"php/images/"+EditDetails.imageDisplayOne} id="image-btn" style={{ width: '100%' }} />
                                      <input type="file" name="image-fileToUpload" style={{ display: 'none' }} id="imagepreview" onChange={(e)=>changeHandlerImage(e)} /> 
                                      <img id="imgpreview" style={{ width: '500%' }} />
                                    </label>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="col-md-4">
                                    <div><label>Date Reserved: </label> {EditDetails.date_reserve}</div>
                                  </div>
                                </div>  

                                <div className="form-group">
                                  <div className="col-md-6 width-xs-2 text-center mgbt-xs-5"><button onClick={() => onUpdate()} className="btn vd_bg-green vd_white width-100" type="button">Update</button></div>
                                  <div className="col-md-6 width-xs-2 text-center mgbt-xs-5"><button onClick={() => history.goBack()} className="btn vd_bg-red vd_white width-100" type="button">Cancel</button></div>
                                </div>
                              </form>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className='col-sm-3'></div>
                    </div>
                    {/* row */}
                  </div>
                  {/* .vd_content-section */}
                </div>
                {/* .vd_content */}
              </div>
              {/* .vd_container */}
            </div>
            {/* .vd_content-wrapper */}
            {/* Middle Content End */}
          </div>
          {/* .container */}
        </div>
        {/* .content */}
        {/* Footer Start */}
        {/* <footer className="footer-1" id="footer">      
          <div className="vd_bottom ">
            <div className="container">
              <div className="row">
                <div className=" col-xs-12">
                  <div className="copyright">
                    Copyright ©{Copyright} STGCInc
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
        {/* Footer END */}
      </div>
      {/* .vd_body END  */}
      <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
      {/*
    <a className="back-top" href="#" id="back-top"> <i className="icon-chevron-up icon-white"> </i> </a> */}
      {/* Javascript =============================================== */}
      {/* Placed at the end of the document so the pages load faster */}
      {/*[if lt IE 9]>
      
    <![endif]*/}
    </div>
  )
}

export default AdminEditAppointment
