import React from 'react'

const Results = () => {
  return (
    <div id="pages" className="full-layout no-nav-left no-nav-right  nav-top-fixed background-login     responsive remove-navbar login-layout   clearfix" data-active="pages "  data-smooth-scrolling="1"> 
<div className="vd_body">
  {/* Header Start */}
  {/* Header Ends */} 
  <div className="content">
    <div className="container"> 
      {/* Middle Content Start */}
      <div className="vd_content-wrapper">
        <div className="vd_container">
          <div className="vd_content clearfix">
            <div className="vd_content-section clearfix">
              <div className="vd_register-page">
                <div className="panel widget">
                  <div className="panel-body">
                    <h3 className="font-semibold text-center">Bacolod Queen of Mercy Hospital</h3>
                    <h1 className="font-semibold text-center" style={{fontSize: 52}}>Result</h1>
                  </div>
                </div>
                <div className='row'>
                    <div className='col-sm-3'></div>
                    <div className='col-sm-6'>
                    <div className="panel widget">
                        <div className="panel-body">
                            <form className="form-horizontal" id="login-form" action="#" role="form" noValidate="novalidate">
                            <div className="alert alert-danger vd_hidden">
                                <button type="button" className="close" data-dismiss="alert" aria-hidden="true"><i className="icon-cross" /></button>
                                <span className="vd_alert-icon"><i className="fa fa-exclamation-circle vd_red" /></span><strong>Oh snap!</strong> Change a few things up and try submitting again. </div>
                            <div className="alert alert-success vd_hidden">
                                <button type="button" className="close" data-dismiss="alert" aria-hidden="true"><i className="icon-cross" /></button>
                                <span className="vd_alert-icon"><i className="fa fa-check-circle vd_green" /></span><strong>Well done!</strong>. </div>                  
                            <div className="form-group  mgbt-xs-20">
                                <div className="col-md-12">
                                <div className="label-wrapper sr-only">
                                    <label className="control-label" htmlFor="email">Email</label>
                                </div>
                                <div className="label-wrapper">
                                    <label className="control-label sr-only" htmlFor="password">Password</label>
                                </div>
                                <div className="vd_input-wrapper" id="password-input-wrapper"> <span className="menu-icon"> <i className="fa fa-lock" /> </span>
                                    <input type="password" placeholder="Password" id="password" name="password" className="required" required />
                                </div>
                                </div>
                            </div>
                            <div id="vd_login-error" className="alert alert-danger hidden"><i className="fa fa-exclamation-circle fa-fw" /> Please fill the necessary field </div>
                            <div className="form-group">
                                <div className="col-md-12 text-center mgbt-xs-5">
                                <button className="btn vd_bg-green vd_white width-100" type="submit" id="login-submit">Login</button>
                                </div>
                                <div className="col-md-12">
                                <div className="row">
                                    <div className="col-xs-6">
                                    <div className="vd_checkbox">
                                        <input type="checkbox" id="checkbox-1" defaultValue={1} />
                                        <label htmlFor="checkbox-1"> Remember me</label>
                                    </div>
                                    </div>
                                    <div className="col-xs-6 text-right">
                                    <div className> <a href="pages-forget-password.php">Forget Password? </a> </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                    <div className='col-sm-3'></div>
                </div>
                {/* Panel Widget */}





                
              </div>
              {/* vd_login-page */} 
            </div>
            {/* .vd_content-section */} 
          </div>
          {/* .vd_content */} 
        </div>
        {/* .vd_container */} 
      </div>
      {/* .vd_content-wrapper */} 
      {/* Middle Content End */} 
    </div>
    {/* .container */} 
  </div>
  {/* .content */}
  {/* Footer Start */}
  <footer className="footer-2" id="footer">      
    <div className="vd_bottom ">
      <div className="container">
        <div className="row">
          <div className=" col-xs-12">
            <div className="copyright text-center">
              Copyright ©2014 Venmond Inc. All Rights Reserved 
            </div>
          </div>
        </div>{/* row */}
      </div>{/* container */}
    </div>
  </footer>
  {/* Footer END */}
</div>
    </div>
  )
}

export default Results
