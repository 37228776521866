import React from 'react'
//stgcinc888@gmail.com

const SUBSCRIPTION = '';

const DOMAIN = 'http://bqmhospital.com/';
// const DOMAIN = 'http://test.bqmhospital.com/';
// const DOMAIN = 'http://localhost/BQMH/';

const IMAGE = './46964720_931525337041328_7708105959918272512_n.jpg';

const COMPANY_NAME = 'BACOLOD QUEEN OF MERCY HOSPITAL';

const PDF_NOTE = ``;

const MEDICALDIRECTOR = "MARIE ANTONETTE O. LUCASAN";
const VERIFIEDBY = "ROBBIE PATRICK C. MAGLAYA";

const HomePage_Note = ` INTENDED USE: The Novel Coronavirus (2019-nCoV) Nucleic Acid Diagnostic Kit [PCR.-Fluorescence Probing] is used for
qualitative detection of ORF-lab and N-gene of Novel Coronavirus (2019-NCoVJ in nasopharyngeal swab, oropharyngeal swab
and alveolar lavage from suspected cases of coronavirus infection. Positlve results are indicative ofpresence of SARS-COV2 RNA,
clinical correlation with patienB' history and other diagnostic information is necessary to determine patient inf'ection status.
Positive results do not rule out bacterial infection or co-infection with other viruses. The agent detected may not lre the definitive
cause of disease. All positive resuits are repofted to appropriate local, regional, and national public health authorities as part of
the national pandemic response strategy. Negative results do not preclude SARS-CoV2 infecrion, and should not be used as sole
basis for patient management decisions. Negative results should be correlated with clinical obsersations, patient history, and
epide rniological in formation. `;


const TERMS_SERVICES = `
Declaration and Data Privacy Consent

By submitting i am agreeing that the information i have is true, correct, and r\\\n
complete. i understand that failure to answer any question or giving false answer can be
penalized in accordance with law. I voluntarily and freely consent to the collection and
sharing of the above personal information in relation to BQMH COVID-19 intenal protocols
and for the purpose of affecting control of the COVID-19 infection as required by R.A.
11469, Bayanihan to Heal as One Act.
`;







const ENV =  {
    DOMAIN,
    PDF_NOTE,
    HomePage_Note,
    IMAGE,
    COMPANY_NAME,
    TERMS_SERVICES,
    MEDICALDIRECTOR,
    VERIFIEDBY
}

export default ENV
