import { GoogleApiWrapper, Map } from 'google-maps-react';
import { Component } from 'react';

class Maps extends Component {
    render(){
        return (
            <div>
            <Map
                google = {this.props.google}
                style={{width:"100%", height:"100%"}}
                zoom={10}
                initialCenter = {
                    {
                        lat:10.690224118709653, 
                        lng:122.97154919714261
                    }
                }
            />
            </div>
        )
    }
}




export default GoogleApiWrapper({
    apiKey:"AIzaSyDGlPrR6uW5tYzl2bJMBG9M_GAdpRMgAPM"
})(Maps)
