import { BrowserRouter as Router, Route, Link, useRouteMatch, useParams, Switch } from "react-router-dom";

import Homepage from "./components/Homepage";
import HomeAbout from "./components/Homepage_about";
import HomeServices from "./components/Homepage_services";
import HomeRoomRates from "./components/Homepage_roomrates";
import HomeMedicalPackages from "./components/Homepage_medicalpackages";
import HomeContact from "./components/Homepage_contact";
import Results from "./components/Results";
import BQMAdmin from "./components/BQMAdmin";
import AdminHome from "./components/AdminHome";
import AdminReservation from "./components/AdminReservation";
import AdminTestingResult from "./components/AdminTestingResult";
import AdminReservationDetails from "./components/AdminReservationDetails";
import AdminEncodeResult from "./components/AdminEncodeResult";
import AdminEditEncodeResult from "./components/AdminEditEncodeResult";
import AdminEditAppointment from "./components/AdminEditAppointment";
import AdminSwabbedResult from "./components/AdminSwabbedResult";
import RenderPDF from "./components/RenderPDF";
import AdminViewSwabbedResult from "./components/AdminViewSwabbedResult";
import ExportReportsPDF from "./components/ExportReportsPDF";
import RenderResult from "./components/RenderResult";
import AdminAccounts from './components/AdminAccounts'
import AdminPricing from './components/AdminPricing'
import AppointmentForm from './components/AppointmentForm'


function App() {
    return (
        <Router>
            <Switch>
                <Route exact={true} path="/" component={Homepage}>
                    <Homepage />
                </Route>
                <Route exact={true} path="/services/pricing" component={HomeServices}>
                    <HomeServices />
                </Route>
                <Route exact={true} path="/services/contact" component={HomeContact}>
                    <HomeContact />
                </Route>
                <Route exact={true} path="/services/about" component={HomeAbout}>
                    <HomeAbout />
                </Route>
                <Route exact={true} path="/services/room/rates" component={HomeRoomRates}>
                    <HomeRoomRates />
                </Route>
                <Route exact={true} path="/services/medical/packages" component={HomeMedicalPackages}>
                    <HomeMedicalPackages />
                </Route>
                <Route exact={true} path="/form" component={AppointmentForm}>
                    <AppointmentForm />
                </Route>
                <Route exact={true} path="/result" component={Results}>
                    <Results />
                </Route>
                <Route exact={true} path="/users">
                    <div>users</div>
                </Route>

                {/* ////////////////// */}
                <Route exact={true} path="/admin" component={BQMAdmin}>
                    <BQMAdmin />
                </Route>
                <Route exact={true} path="/admin/home" component={AdminHome}>
                    <AdminHome />
                </Route>
                <Route exact={true} path="/admin/appointment" component={AdminReservation}>
                    <AdminReservation />
                </Route>
                <Route exact={true} path="/admin/appointment/details/edit/:id" component={AdminEditAppointment}>
                    <AdminEditAppointment />
                </Route>
                <Route exact={true} path="/admin/appointment/details/:id" component={AdminReservationDetails}>
                    <AdminReservationDetails />
                </Route>
                <Route exact={true} path="/admin/pending/result" component={AdminTestingResult}>
                    <AdminTestingResult />
                </Route>
                <Route exact={true} path="/admin/pending/result/encode/:id" component={AdminEncodeResult}>
                    <AdminEncodeResult />
                </Route>
                <Route exact={true} path="/admin/pending/result/encode/edit/:id/:swabid" component={AdminEditEncodeResult}>
                    <AdminEditEncodeResult />
                </Route>
                <Route exact={true} path="/admin/accounts" component={AdminAccounts}>
                    <AdminAccounts />
                </Route>
                <Route exact={true} path="/admin/pricing" component={AdminPricing}>
                    <AdminPricing />
                </Route>
                <Route exact={true} path="/upload" component={AdminPricing}>
                    <AdminPricing />
                </Route>
                <Route exact={true} path="/admin/swabbed/result" component={AdminSwabbedResult}>
                    <AdminSwabbedResult />
                </Route>
                <Route exact={true} path="/admin/swabbed/result/:id/:swabid" component={AdminViewSwabbedResult}>
                    <AdminViewSwabbedResult />
                </Route>
                <Route exact={true} path="/resulttest/pdf/:token" component={ExportReportsPDF}>
                    <ExportReportsPDF />
                </Route>
                <Route exact={true} path="/renderresult/:id" component={ExportReportsPDF}>
                    <RenderResult />
                </Route>
                {/* <Route exact={true} path="/pdf" component={PDFReader}>
                    <PDFReader />
                </Route> */}

                <Route exact={true} path="/renderPDF/:id" component={RenderPDF}>
                    <RenderPDF />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
