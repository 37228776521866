import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import image from './46964720_931525337041328_7708105959918272512_n.jpg';
import swal from "sweetalert";
import ENV from "./ENV";
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';

const BQMAdmin = () => {
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const isLoggedin = sessionStorage.getItem("isLoggedin");

    if (isLoggedin === "true") {
        history.push("./admin");
    }
    
    useEffect(() => {}, []);

    const onSubmit = async (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        await axios({
            method: "post",
            url: ENV.DOMAIN+"php/adminLogin.php",
            data: formData,
        })
            .then((res) => {
                if (res.data[0].status === "success") {
                    swal({
                        title: "Done!",
                        text: "Successfull Login",
                        icon: "success",
                        timer: 2000,
                        button: false,
                    }).then(() => {
                        sessionStorage.setItem("isFullname", res.data[0].fullname);
                        sessionStorage.setItem("isPosition", res.data[0].position);
                        sessionStorage.setItem("isUsername", res.data[0].username);
                        sessionStorage.setItem("isUserID", res.data[0].id);
                        sessionStorage.setItem("isLoggedin", true);
                        history.push("./admin/appointment");
                    });
                } else {
                    swal({
                        title: "Invalid",
                        text: "Wrong Username and Password",
                        icon: "error",
                        timer: 2000,
                        button: false,
                    }).then(() => {
                        setUsername("");
                        setPassword("");
                    });
                }
                console.log(res.data);
            })
            .catch((err) => {
                console.log('Network Error!');
            });
    };

    // const onSubmit_ = (event) => {
    //     event.preventDefault();
    //     alert();

    // }

    return (
        <div id="pages" className="full-layout no-nav-left no-nav-right  nav-top-fixed background-login     responsive remove-navbar login-layout   clearfix" data-active="pages " data-smooth-scrolling="1">
            <div className="vd_body">
                {/* Header Start */}
                {/* Header Ends */}
                <div className="content">
                    <div className="container">
                        {/* Middle Content Start */}
                        <div className="vd_content-wrapper">
                            <div className="vd_container">
                                <div className="vd_content clearfix">
                                    <div className="vd_content-section clearfix">
                                        <div className="vd_register-page">
                                            <div className="panel widget">
                                                <div className="panel-body">
                                                    <div className="text-center">
                                                        <img src={image} style={{width:200, borderRadius:"100%"}} />
                                                    </div>
                                                    {/* <h1 className="font-semibold text-center">Bacolod Queen of Mercy Hospital</h1> */}
                                                    <h3 className="font-semibold text-center">Administrator</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-2"></div>
                                                <div className="col-sm-8">
                                                    <div className="panel widget">
                                                        <div className="panel-body">
                                                            <form onSubmit={onSubmit}>
                                                                <div className="alert alert-danger vd_hidden">
                                                                    <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
                                                                        <i className="icon-cross" />
                                                                    </button>
                                                                    <span className="vd_alert-icon">
                                                                        <i className="fa fa-exclamation-circle vd_red" />
                                                                    </span>
                                                                    <strong>Oh snap!</strong> Change a few things up and try submitting again.{" "}
                                                                </div>
                                                                <div className="alert alert-success vd_hidden">
                                                                    <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
                                                                        <i className="icon-cross" />
                                                                    </button>
                                                                    <span className="vd_alert-icon">
                                                                        <i className="fa fa-check-circle vd_green" />
                                                                    </span>
                                                                    <strong>Well done!</strong>.{" "}
                                                                </div>
                                                                <div className="form-group  mgbt-xs-20">
                                                                    <div className="col-md-12">
                                                                        <div className="label-wrapper sr-only">
                                                                            <label className="control-label" htmlFor="email">
                                                                                Email
                                                                            </label>
                                                                        </div>
                                                                        <div className="vd_input-wrapper" id="email-input-wrapper">
                                                                            {" "}
                                                                            <span className="menu-icon">
                                                                                {" "}
                                                                                <i className="fa fa-envelope" />{" "}
                                                                            </span>
                                                                            <input type="text" placeholder="Username" autoFocus="on" className="required" value={username} required onChange={(e) => setUsername(e.target.value)} />
                                                                        </div>
                                                                        <div className="label-wrapper">
                                                                            <label className="control-label sr-only" htmlFor="password">
                                                                                Password
                                                                            </label>
                                                                        </div>
                                                                        <div className="vd_input-wrapper" id="password-input-wrapper">
                                                                            {" "}
                                                                            <span className="menu-icon">
                                                                                {" "}
                                                                                <i className="fa fa-lock" />{" "}
                                                                            </span>
                                                                            <input type="password" placeholder="Password" className="required" value={password} required onChange={(e) => setPassword(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="vd_login-error" className="alert alert-danger hidden">
                                                                    <i className="fa fa-exclamation-circle fa-fw" /> Please fill the necessary field{" "}
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="col-md-12 text-center mgbt-xs-5">
                                                                        <button className="btn vd_bg-green vd_white width-100" type="submit" id="login-submit">
                                                                            Login
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2"></div>
                                            </div>
                                            {/* Panel Widget */}
                                        </div>
                                        {/* vd_login-page */}
                                    </div>
                                    {/* .vd_content-section */}
                                </div>
                                {/* .vd_content */}
                            </div>
                            {/* .vd_container */}
                        </div>
                        {/* .vd_content-wrapper */}
                        {/* Middle Content End */}
                    </div>
                    {/* .container */}
                </div>
                {/* .content */}
                {/* Footer Start */}
        {/* <footer className="footer-1" id="footer">      
          <div className="vd_bottom ">
            <div className="container">
              <div className="row">
                <div className=" col-xs-12">
                  <div className="copyright">
                    Copyright ©{Copyright} STGCInc
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
                {/* Footer END */}
            </div>
        </div>
    );
};

export default BQMAdmin;
