import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import {
  useParams,
  useHistory
} from "react-router-dom";
import validator from 'validator'
import image from './46964720_931525337041328_7708105959918272512_n.jpg';
import COUNTRY from './world_countries/Country';
import STATES from './world_countries/States';
import ENV from './ENV';
import QRCode from "qrcode.react";
import QRCodes from 'qrcode'
import swal from 'sweetalert';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import html2canvas from "html2canvas";
import { styled, Box, borderRadius } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import { format } from 'date-fns'
import { rgbToHex } from '@mui/lab/node_modules/@mui/system';

// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';
// 1110963309746630



const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 400,
  height: "90%",
  bgcolor: '#fff',
  // border: '2px solid #000',
  p: 1,
  // px: 4,
  // pb: 3,
};









const AppointmentForm = () => {

  useEffect(() => {
    fetchCountries();
    fetchLocalProvince();
  }, [])

  const history = useHistory();
  const printRef = useRef();

  const [ReserveDetails, setReserveDetails] = useState('');
  const [Reserve, setReserve] = useState(false);


  const [errorFirstname, setErrorFirstname] = useState({textTransform:"uppercase"});
  const [errorMiddlename, setErrorMiddlename] = useState({textTransform:"uppercase"});
  const [errorLastname, setErrorLastname] = useState({textTransform:"uppercase"});
  const [errorSuffix, setErrorSuffix] = useState({ height: 38 });
  const [errorCivilStatus, setErrorCivilStatus] = useState({ height: 38 });
  const [errorContact, setErrorContact] = useState(null);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorBirthday, setErrorBirthday] = useState({ height: 38 });
  const [errorAge, setErrorAge] = useState({ height: 38 });
  const [errorGender, setErrorGender] = useState({ height: 38 });
  const [errorCountries, setErrorCountries] = useState({ height: 38 });
  const [errorState, setErrorState] = useState({ height: 38 });
  const [errorCity, setErrorCity] = useState({ height: 38 });
  const [errorBarangay, setErrorBarangay] = useState({ height: 38 });
  const [errorAddress, setErrorAddress] = useState({ resize: "none" });
  const [errorValidity, setErrorValidity] = useState({ height: 38 });
  const [errorPurpose, setErrorPurpose] = useState({ height: 38 });
  const [errorDateSched, setErrorDateSched] = useState({ height: 38 });
  const [errorTimeSched, setErrorTimeSched] = useState({ height: 38 });
  const [errorimageDisplayOne, setErrorimageDisplayOne] = useState({ height: 38, width: 200 });
  const [errorSymptomsSelection, setErrorSymptomsSelection] = useState({ height: 38 })
  const [errorSymptomsDescription, setErrorSymptomsDescription] = useState({ resize: "none" })







  const [errorDatesched, setErrorDatesched] = useState(null);
  const [errorTimesched, setErrorTimesched] = useState(null);
  const [errorScheduleSwabbing, setErrorScheduleSwabbing] = useState({ height: 38 });
  const [errorSwabbingTime, setErrorSwabbingTime] = useState({ height: 38 });
  const [errorOccupation, setErrorOccupation] = useState({ height: 38 });






  const [onLocalProvince, setLocalProvince] = useState([]);
  const [onLocalProvinceValue, setLocalProvinceValue] = useState('');
  const [onLocalCity, setLocalCity] = useState([]);
  const [onLocalCityValue, setLocalCityValue] = useState('');
  const [onLocalBarangay, setLocalBarangay] = useState([]);
  const [onLocalBarangayValue, setLocalBarangayValue] = useState('');




  const [callState, setState] = useState([]);
  const [callCities, setCities] = useState([]);
  const [dataCountries, setdataCountries] = useState([]);


  const [civilstatus, setCivilStatus] = useState('');


  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [suffix, setSuffix] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [birthday, setBirthday] = useState('');
  const [age, setAge] = useState(0);
  const [gender, setGender] = useState('');
  // const [datesched, setDateSched] = useState('');
  const [Address, setAddress] = useState('');
  const [agreement, setAgreement] = useState(false);
  const [validity, setValidity] = useState('');
  const [purpose, setPurpose] = useState('');

  const [UploadFrontID, setUploadFrontID] = useState(null);
  const [UploadBackID, setUploadBackID] = useState(null);

  const [GetCountry, setGetCountry] = useState(0);
  const [GetState, setGetState] = useState(0);
  const [GetCity, setGetCity] = useState(0);
  const [onOption, setOption] = useState('Local');
  const [ScheduleSwabbing, setScheduleSwabbing] = useState('')
  const [SwabbingTime, setSwabbingTime] = useState('')
  
  const [Symptoms, setSymptoms] = useState('')
  const [SymptomsShowTextField, setSymptomsShowTextField] = useState(false)
  const [SymptomsDescription, setSymptomsDescription] = useState('')
  const [PurposeDescription, setPurposeDescription] = useState('')
  const [Occupation, setOccupation] = useState('');
  const [ScheduleforSwabbing, setScheduleforSwabbing] = useState('');

  const [TextOPD, setTextOPD] = useState('');
  const [TextTRAVEL, setTextTRAVEL] = useState('');
  const [TextCOMPANY, setTextCOMPANY] = useState('');
  const [TextINPATIENT, setTextINPATIENT] = useState('');

  const [imageDisplayOne, setimageDisplayOne] = useState(null)
  const [imageDisplayTwo, setimageDisplayTwo] = useState(null)
  // console.log(GetCountry, GetState, GetCity)

  const fetchCountries = async () => {
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchCountries.php',
    })
      .then((res) => {
        setdataCountries(res.data)
      }).catch((err) => {

      })
  }

  const fetchLocalProvince = async () => {
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchLocalProvince.php',
    })
      .then((res) => {
        setLocalProvince(res.data)
      }).catch((err) => {

      })
  }

  const onFindCity = async (value) => {
    setLocalProvinceValue(value);
    setLocalCityValue('')
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchLocalCity.php?provCode=' + value,
    })
      .then((res) => {
        setLocalCity(res.data)
      }).catch((err) => {

      })
  }

  const onFindBarangay = async (value) => {
    // setLocalCityId(value); 
    setLocalBarangay([]);
    setLocalCityValue(value)
    setLocalBarangayValue('')
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchLocalBarangay.php?citymunCode=' + value,
    })
      .then((res) => {
        setLocalBarangay(res.data)
      }).catch((err) => {

      })
  }

  const onBarangay = (value) => {
    setLocalBarangayValue('')
    setLocalBarangayValue(value)
    // console.log(onOption, onLocalProvinceValue, onLocalCityValue, value)
  }



  // console.log(onLocalBarangay)

  const fetchState = async (e) => {
    setGetCountry(e)
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchCountriesState.php?id=' + e,
    })
      .then((res) => {
        if (res.data.length > 0) {
          setState(res.data)
        }
      }).catch((err) => {


      })
  }

  const fetchCities = async (e) => {
    setGetState(e);
    await axios({
      method: 'get',
      url: ENV.DOMAIN + 'php/fetchCountriesStateCities.php',
    })
      .then((res) => {
        setCities(res.data)
      }).catch((err) => {


      })
  }
  const [ButtonDisabled, setButtonDisabled] = useState(true);
  const [ErrorTextOPD, setErrorTextOPD] = useState({height: 38, width:361, border: '1px solid #d5d5d5' }); 
  const [ErrorTextTRAVEL, setErrorTextTRAVEL] = useState({height: 38, width:361, border: '1px solid #d5d5d5' }); 
  const [ErrorTextCOMPANY, setErrorTextCOMPANY] = useState({height: 38, width:361, border: '1px solid #d5d5d5' }); 
  const [ErrorTextINPATIENT, setErrorTextINPATIENT] = useState({height: 38, width:361, border: '1px solid #d5d5d5' }); 

  const onSubmit = async () => {
    
    setButtonDisabled(false)
    if (agreement) {
      var formData = new FormData();
      formData.append('firstname', firstname);
      formData.append('lastname', lastname);
      formData.append('middlename', middlename);
      formData.append('suffix', suffix);
      formData.append('contact', contact);
      formData.append('email', email);
      formData.append('birthday', birthday);
      formData.append('age', age);
      formData.append('gender', gender);
      formData.append('civil_status', civilstatus);
      formData.append('country', GetCountry);
      formData.append('inter_local', onOption);
      formData.append('state', GetState);
      formData.append('city', GetCity);
      formData.append('localProvinceValue', onLocalProvinceValue);
      formData.append('localCityValue', onLocalCityValue);
      formData.append('localBarangayValue', onLocalBarangayValue);
      formData.append('address', Address);
      formData.append('Occupation', Occupation);
      formData.append('validity', validity);
      formData.append('purpose', purpose);
      formData.append('TextOPD', TextOPD);
      formData.append('TextTRAVEL', TextTRAVEL);
      formData.append('TextCOMPANY', TextCOMPANY);
      formData.append('TextINPATIENT', TextINPATIENT);
      formData.append('PurposeDescription', PurposeDescription);
      formData.append('Symptoms', Symptoms);
      formData.append('SymptomsShowTextField', SymptomsShowTextField);
      formData.append('SymptomsDescription', SymptomsDescription);
      formData.append('ScheduleSwabbing', ScheduleSwabbing);
      formData.append('SwabbingTime', SwabbingTime);
      formData.append('imageDisplayOne', imageDisplayOne);
      // formData.append('imageDisplayTwo', imageDisplayTwo);

      if(purpose == 'OPD')
      {
        var toProposeDesc = true;
        // if(TextOPD != ''){
        //   var toProposeDesc = true;
        //   setErrorTextOPD({height: 38, width:361, border: '1px solid #d5d5d5' })
        // } else {
        //   var toProposeDesc = false;
        //   setErrorTextOPD({height: 38, width:361, border: '1px solid red' })
        // }
      } else if(purpose == 'TRAVEL')
      {
        if(TextTRAVEL != ''){
          var toProposeDesc = true;
          setErrorTextTRAVEL({height: 38, width:361, border: '1px solid #d5d5d5' })
        } else {
          var toProposeDesc = false;
          setErrorTextTRAVEL({height: 38, width:361, border: '1px solid red' })
        }
      } else if(purpose == 'COMPANY')
      {
        if(TextCOMPANY != ''){
          var toProposeDesc = true;
          setErrorTextCOMPANY({height: 38, width:361, border: '1px solid #d5d5d5' })
        } else {
          var toProposeDesc = false;
          setErrorTextCOMPANY({height: 38, width:361, border: '1px solid red' })
        }
      } else if(purpose == 'INPATIENT')
      {
        if(TextINPATIENT != ''){
          var toProposeDesc = true;
          setErrorTextINPATIENT({height: 38, width:361, border: '1px solid #d5d5d5' })
        } else {
          var toProposeDesc = false;
          setErrorTextINPATIENT({height: 38, width:361, border: '1px solid red' })
        }
      }

      if (onOption === 'International') {

        if(Symptoms!=''){
          if(Symptoms == 'With Symptoms') {
            if(SymptomsDescription != '') {
              var Symptomss = true;
              setErrorSymptomsSelection({ height: 38, border: '1px solid #D5D5D5' })
              setErrorSymptomsDescription({ resize: "none", border: '1px solid #D5D5D5' })
            } else {              
              if (SymptomsDescription == '') {
                var Symptomss = false;
                setErrorSymptomsDescription({ resize: "none", border: '1px solid red' })
              } 
              else {
                var Symptomss = false;
                setErrorSymptomsSelection({ height: 38, border: '1px solid #D5D5D5' })
              }              
            }
          } else {
            var Symptomss = true;
            setErrorSymptomsSelection({ height: 38, border: '1px solid #D5D5D5' })
            setErrorSymptomsDescription({ resize: "none", border: '1px solid #D5D5D5' })
          }
        } else {
          var Symptomss = false;
          setErrorSymptomsSelection({ height: 38, border: '1px solid red' })
        }

        if (firstname != '' && lastname != ''
          && middlename != '' && suffix != ''
          && contact != '' && email != ''
          && gender != '' && civilstatus != ''
          && GetCountry != '' && GetState != ''
          && Address != '' && birthday != '' 
          && age != '' && ScheduleSwabbing != '' && SwabbingTime!=''
          && imageDisplayOne != '' && Symptomss && toProposeDesc) {



          // if (inserted) {
            await axios({
              method: 'post',
              url: ENV.DOMAIN + 'php/addAppointment.php',
              data: formData
            })
              .then((res) => {
                // console.log(res.data)
                if (res.data[0].status === 'success') {
                  swal({
                    title: "",
                    text: "Successfully Sent",
                    icon: "success",
                    timer: 2000,
                    button: false,
                  }).then(() => {
                    setSymptoms('')
                    setSymptomsDescription('')
                    setimageDisplayOne(null)
                    setReserveDetails(res.data)
                    setReserve(true)
                    setOpen(false)
                    setSwabbingTime('')
                    setButtonDisabled(true)
                    QRCodes.toDataURL(ENV.DOMAIN + 'php/QRCodeResult.php?id=' + res.data[0].resno)
                      .then(async (base64) => {
                        var formDataQRCode = new FormData();
                        formDataQRCode.append('resno', res.data[0].resno);
                        formDataQRCode.append('qrcode_url', ENV.DOMAIN + 'php/QRCode/' + res.data[0].resno);
                        formDataQRCode.append('base64', base64);
                        await axios({
                          method: 'post',
                          url: ENV.DOMAIN + 'php/saveQRCodeImage.php',
                          data: formDataQRCode
                        })
                        // console.log(base64)
                      });
                    // console.log(res.data)
                  });
                } else {
                  swal({
                    title: "",
                    text: "Invalid",
                    icon: "error",
                    timer: 2000,
                    button: false,
                  }).then(() => {
                    setButtonDisabled(true)
                  })
                }
              }).catch((err) => {

              })
          // }
        } else {
          if (firstname == '') {
            setErrorFirstname({ border: '1px solid red' });
          } else {
            setErrorFirstname({ border: '1px solid #d5d5d5' });
          }
          if (lastname == '') {
            setErrorLastname({ border: '1px solid red' });
          } else {
            setErrorLastname({ border: '1px solid #d5d5d5' });
          }
          if (middlename == '') {
            setErrorMiddlename({ border: '1px solid red' });
          } else {
            setErrorMiddlename({ border: '1px solid #d5d5d5' });
          }
          if (suffix == '') {
            setErrorSuffix({ height: 38, border: '1px solid red' });
          } else {
            setErrorSuffix({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (civilstatus == '') {
            setErrorCivilStatus({ height: 38, border: '1px solid red' });
          } else {
            setErrorCivilStatus({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (contact == '') {
            setErrorContact({ border: '1px solid red' });
          } else {
            setErrorContact({ border: '1px solid #d5d5d5' });
          }
          if (email == '') {
            setErrorEmail({ border: '1px solid red' });
          } else {
            setErrorEmail({ border: '1px solid #d5d5d5' });
          }
          if (birthday == '') {
            setErrorBirthday({ height: 38, border: '1px solid red' });
          } else {
            setErrorBirthday({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (age == '') {
            setErrorAge({ height: 38, border: '1px solid red' });
          } else {
            setErrorAge({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (gender == '') {
            setErrorGender({ height: 38, border: '1px solid red' });
          } else {
            setErrorGender({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (GetCountry == '') {
            setErrorCountries({ height: 38, border: '1px solid red' });
          } else {
            setErrorCountries({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (GetState == '') {
            setErrorState({ height: 38, border: '1px solid red' });
          } else {
            setErrorState({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (GetCity == '') {
            setErrorCity({ height: 38, border: '1px solid red' });
          } else {
            setErrorCity({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (validity == '') {
            setErrorValidity({ height: 38, border: '1px solid red' });
          } else {
            setErrorValidity({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (purpose == '') {
            setErrorPurpose({ height: 38, border: '1px solid red' });
          } else {
            setErrorPurpose({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (Occupation == '') {
            setErrorOccupation({ height: 38, border: '1px solid red' });
          } else {
            setErrorOccupation({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (ScheduleSwabbing == '') {
            setErrorScheduleSwabbing({ height: 38, border: '1px solid red' });
          } else {
            setErrorScheduleSwabbing({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (SwabbingTime == '') {
            setErrorSwabbingTime({ height: 38, border: '1px solid red' });
          } else {
            setErrorSwabbingTime({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (imageDisplayOne == null) {
            setErrorimageDisplayOne({ width: 200, height: 38, border: '1px solid red' });
          } else {
            setErrorimageDisplayOne({ width: 200, height: 38, border: '1px solid #d5d5d5' });
          }
          setButtonDisabled(true)
        }
      } else {



        let Symptomss;
        if(Symptoms!=''){
          if(Symptoms == 'With Symptoms') {
            if(SymptomsDescription != '') {
              Symptomss = true;
              setErrorSymptomsSelection({ height: 38, border: '1px solid #D5D5D5' })
              setErrorSymptomsDescription({ resize: "none", border: '1px solid #D5D5D5' })
            } else {              
              if (SymptomsDescription == '') {
                Symptomss = false;
                setErrorSymptomsDescription({ resize: "none", border: '1px solid red' })
              } 
              else {
                Symptomss = false;
                setErrorSymptomsSelection({ height: 38, border: '1px solid #D5D5D5' })
              }              
            }
          } else {
            Symptomss = true;
            setErrorSymptomsSelection({ height: 38, border: '1px solid #D5D5D5' })
            setErrorSymptomsDescription({ resize: "none", border: '1px solid #D5D5D5' })
          }
        } else {
          Symptomss = false;
          setErrorSymptomsSelection({ height: 38, border: '1px solid red' })
        }


        if (firstname != '' && lastname != '' && middlename != ''
          && suffix != '' && contact != '' && email != ''
          && gender != '' && civilstatus != ''
          && onLocalProvinceValue != '' && onLocalCityValue != ''
          && onLocalBarangayValue != '' && ScheduleSwabbing!='' && SwabbingTime!=''
          && Address != '' && birthday != '' && age != '' && imageDisplayOne != null && Symptomss && toProposeDesc) {

          await axios({
            method: 'post',
            url: ENV.DOMAIN + 'php/addAppointment.php',
            data: formData
          })
          .then((res) => {
            if (res.data[0].status === 'success') {
              swal({
                title: "",
                text: "Successfully Sent",
                icon: "success",
                timer: 2000,
                button: false,
              }).then(() => {
                setSymptoms('')
                setSymptomsDescription('')
                setimageDisplayOne(null)
                setReserveDetails(res.data)
                setReserve(true)
                setOpen(false)
                setSwabbingTime('')
                setButtonDisabled(true)
                if(res.data[0].purpose == 'OPD') {
                  setPurposeDescription(res.data[0].OPD);
                } else if(res.data[0].purpose == 'TRAVEL'){
                  setPurposeDescription(res.data[0].TRAVELs);
                } else if(res.data[0].purpose == 'COMPANY'){
                  setPurposeDescription(res.data[0].COMPANY);
                } else if(res.data[0].purpose == 'INPATIENT'){
                  setPurposeDescription(res.data[0].INPATIENT);
                }
                QRCodes.toDataURL(ENV.DOMAIN + 'php/QRCodeResult.php?id=' + res.data[0].resno)
                  .then(async (base64) => {
                    var formDataQRCode = new FormData();
                    formDataQRCode.append('resno', res.data[0].resno);
                    formDataQRCode.append('qrcode_url', ENV.DOMAIN + 'php/QRCode/' + res.data[0].resno);
                    formDataQRCode.append('base64', base64);
                    await axios({
                      method: 'post',
                      url: ENV.DOMAIN + 'php/saveQRCodeImage.php',
                      data: formDataQRCode
                    })
                    // console.log(base64)
                  });
                // console.log(res.data)
              });
            } else {
              swal({
                title: "",
                text: "Invalid",
                icon: "error",
                timer: 2000,
                button: false,
              }).then(() => {
                setButtonDisabled(true)
              })
            }
          }).catch((err) => {

          })          
        } else {
          if (firstname == '') {
            setErrorFirstname({ border: '1px solid red' });
          } else {
            setErrorFirstname({ border: '1px solid #d5d5d5' });
          }
          if (lastname == '') {
            setErrorLastname({ border: '1px solid red' });
          } else {
            setErrorLastname({ border: '1px solid #d5d5d5' });
          }
          if (middlename == '') {
            setErrorMiddlename({ border: '1px solid red' });
          } else {
            setErrorMiddlename({ border: '1px solid #d5d5d5' });
          }
          if (suffix == '') {
            setErrorSuffix({ height: 38, border: '1px solid red' });
          } else {
            setErrorSuffix({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (civilstatus == '') {
            setErrorCivilStatus({ height: 38, border: '1px solid red' });
          } else {
            setErrorCivilStatus({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (contact == '') {
            setErrorContact({ border: '1px solid red' });
          } else {
            setErrorContact({ border: '1px solid #d5d5d5' });
          }
          if (email == '') {
            setErrorEmail({ border: '1px solid red' });
          } else {
            setErrorEmail({ border: '1px solid #d5d5d5' });
          }
          if (birthday == '') {
            setErrorBirthday({ height: 38, border: '1px solid red' });
          } else {
            setErrorBirthday({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (age == '') {
            setErrorAge({ height: 38, border: '1px solid red' });
          } else {
            setErrorAge({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (gender == '') {
            setErrorGender({ height: 38, border: '1px solid red' });
          } else {
            setErrorGender({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (onLocalProvinceValue == '') {
            setErrorState({ height: 38, border: '1px solid red' });
          } else {
            setErrorState({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (onLocalCityValue == '') {
            setErrorCity({ height: 38, border: '1px solid red' });
          } else {
            setErrorCity({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (onLocalBarangayValue == '') {
            setErrorBarangay({ height: 38, border: '1px solid red' });
          } else {
            setErrorBarangay({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (Address == '') {
            setErrorAddress({ resize: 'none', border: '1px solid red' });
          } else {
            setErrorAddress({ resize: 'none', border: '1px solid #d5d5d5' });
          }
          if (validity == '') {
            setErrorValidity({ height: 38, border: '1px solid red' });
          } else {
            setErrorValidity({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (purpose == '') {
            setErrorPurpose({ height: 38, border: '1px solid red' });
          } else {
            setErrorPurpose({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (Occupation == '') {
            setErrorOccupation({ height: 38, border: '1px solid red' });
          } else {
            setErrorOccupation({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (ScheduleSwabbing == '') {
            setErrorScheduleSwabbing({ height: 38, border: '1px solid red' });
          } else {
            setErrorScheduleSwabbing({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (SwabbingTime == '') {
            setErrorSwabbingTime({ height: 38, border: '1px solid red' });
          } else {
            setErrorSwabbingTime({ height: 38, border: '1px solid #d5d5d5' });
          }
          if (imageDisplayOne == null) {
            setErrorimageDisplayOne({ width: 200, height: 38, border: '1px solid red' });
          } else {
            setErrorimageDisplayOne({ width: 200, height: 38, border: '1px solid #d5d5d5' });
          }
          setButtonDisabled(true)

        }
      }
    } else {
      swal({
        title: "Error",
        text: "Check Aggrement",
        icon: "error",
        timer: 2000,
        button: false,
      }).then(() => {
        setButtonDisabled(true)
      })
    }
  }

  // const onSubmitLocal = async () => {
  //   if (agreement) {
  //     var formData = new FormData();
  //     formData.append('firstname', firstname);
  //     formData.append('lastname', lastname);
  //     formData.append('middlename', middlename);
  //     formData.append('suffix', suffix);
  //     formData.append('contact', contact);
  //     formData.append('email', email);
  //     formData.append('birthday', birthday);
  //     formData.append('gender', gender);
  //     formData.append('civil_status', civilstatus);
  //     formData.append('inter_local', onOption);
  //     formData.append('LocalProvinceValue', onLocalProvinceValue);
  //     formData.append('LocalCityValue', onLocalCityValue);
  //     formData.append('LocalBarangayValue', onLocalBarangayValue);
  //     formData.append('country', GetCountry);
  //     formData.append('state', GetState);
  //     formData.append('city', GetCity);
  //     formData.append('datesched', datesched);
  //     // formData.append('timesched', timesched);
  //     formData.append('validity', validity);
  //     formData.append('purpose', purpose);
  //     await axios({
  //       method:'post',
  //       url:ENV.DOMAIN+'php/addAppointment.php',
  //       data: formData
  //     })
  //     .then((res)=>{
  //       if(res.data[0].status === 'success'){
  //         swal({
  //           title: "",
  //           text: "Successfully Send",
  //           icon: "success",
  //           timer: 2000,
  //           button: false,
  //         }).then(() => {
  //           setFirstname('');
  //           setLastname('');
  //           setMiddlename('');
  //           setSuffix('');
  //           setContact('');
  //           setEmail('');
  //           setBirthday('');
  //           setGender('');
  //           setCivilStatus('');
  //           setDateSched('');
  //           // setTimeSched('');
  //           setAgreement(false);
  //           setValidity('');
  //           setPurpose('');
  //           setGetCountry(0);
  //           setGetState(0);
  //           setGetCity(0);
  //           console.log(res.data)
  //           setReserveDetails(res.data)
  //           setReserve(true)
  //         });
  //       } else {
  //         swal({
  //           title: "",
  //           text: "Invalid",
  //           icon: "error",
  //           timer: 2000,
  //           button: false,
  //         })
  //       }
  //     }).catch((err)=>{


  //     })

  //   } else {
  //     swal({
  //       title: "Error",
  //       text: "Check Aggrement",
  //       icon: "error",
  //       timer: 2000,
  //       button: false,
  //     })
  //   }
  // }


  const [emailError, setEmailError] = useState('')
  const validateEmail = (e) => {
    var email = e.target.value
    if (validator.isEmail(email)) {
      setEmail(email)
      setEmailError('')
    } else {
      setEmailError(<div style={{ color: "red" }}>Enter valid Email!</div>)
    }
  }

  const BackReservation = () => {
    setReserve(false)
  }

  const [DevPurpose, setDevPurpose] = useState(false);

  const ActionPurpose = (purpose) => {
    // console.log(purpose)
    if (purpose == 'OPD') {
      return (
        <div className="col-md-12">
          <div className="label-wrapper">
            <label className="control-label">Purpose: Specify<span className="vd_red">*</span></label>
          </div>
          <input type="text" onChange={(e) => setTextOPD(e.target.value)} placeholder="OPD" autocomplete="off" style={ErrorTextOPD} />
        </div>
      )
    } else if (purpose == 'INPATIENT') {
      return (
        <div className="col-md-6">
          <div className="label-wrapper">
            <label className="control-label">Room Number<span className="vd_red">*</span></label>
          </div>
          <input type="text" onChange={(e) => setTextINPATIENT(e.target.value)} placeholder="Room Number" autocomplete="off" style={ErrorTextINPATIENT} />
        </div>
      )
    } else if (purpose == 'COMPANY') {
      return (
        <div className="col-md-6">
          <div className="label-wrapper">
            <label className="control-label">Company Name<span className="vd_red">*</span></label>
          </div>
          <input type="text" onChange={(e) => setTextCOMPANY(e.target.value)} placeholder="Company Name" autocomplete="off" style={ErrorTextCOMPANY} />
        </div>
      )
    } else if (purpose == 'TRAVEL') {
      return (
        <div className="col-md-6">
          <div className="label-wrapper">
            <label className="control-label">Date/Time of Flight<span className="vd_red">*</span></label>
          </div>
          <input type="datetime-local" onChange={(e) => setTextTRAVEL(e.target.value)} placeholder="Date/Time of Flight" autocomplete="off" style={ErrorTextTRAVEL} />
        </div>
      )
    }




    // if(value === 'Others'){
    //   setPurpose(value)
    //   setDevPurpose(true);
    // } else {
    //   setPurpose(value)
    //   setDevPurpose(false);
    // }
  }

  const selectOption = (e) => {
    setOption(e);

    setGetCountry('');
    setGetState('');
    setGetCity('');
    setLocalProvinceValue('');
    setLocalCityValue('');
    setLocalBarangayValue('');
  }

  const displayAddressSelection = (value) => {
    if (value === 'International') {
      return (
        <>
          {/* <div className="form-group"> */}
          <div className="col-md-4">
            <div className="label-wrapper">
              <label className="control-label">Countries <span className="vd_red">*</span></label>
            </div>
            <select style={errorCountries} onChange={(e) => fetchState(e.target.value)}>
              <option value="" disabled selected>Select...</option>
              {
                dataCountries.map((data) => {
                  return (<option value={data.id}>{data.country}</option>)
                })
              }
            </select>
          </div>
          <div className="col-md-4">
            <div className="label-wrapper">
              <label className="control-label">Province / State <span className="vd_red">*</span></label>
            </div>
            <select style={errorState} onChange={(e) => fetchCities(e.target.value)}>
              <option value="" disabled selected>Select...</option>
              {
                callState.map((data) => {
                  return (<option value={data.id}>{data.name}</option>)
                })
              }
            </select>
          </div>
          {/* <div className="col-md-4">
              <div className="label-wrapper">
                <label className="control-label">Municipality / City <span className="vd_red">*</span></label>
              </div>
              <select style={errorCity} onChange={(e)=>setGetCity(e.target.value)}>
                <option value="" disabled selected>Select...</option>
                {
                  callCities.map((data)=>{
                    return (<option value={data.id}>{data.name}</option>)
                  })
                }
              </select>
            </div> */}
          {/* </div>
          <div className="form-group"> */}
          <div className="col-md-12">
            <div className="label-wrapper">
              <label className="control-label">Current Address (City / Street / Block / Lot No / Subd.)  <span className="vd_red">*</span></label>
            </div>
            <textarea style={{ resize: "none" }} onChange={(e) => setAddress(e.target.value)} placeholder="City / Street / Block / Lot No / Subd." autoComplete='off'></textarea>
          </div>
          {/* </div> */}
        </>
      )
    } else {
      return (
        <>
          {/* <div className="form-group"> */}
          <div className="col-md-3">
            <div className="label-wrapper">
              <label className="control-label">Province / State <span className="vd_red">*</span></label>
            </div>
            <select style={errorState} onChange={(e) => onFindCity(e.target.value)}>
              <option value="" disabled selected>Select...</option>
              {
                onLocalProvince.map((data) => {
                  return (<option value={data.id}>{data.provDesc}</option>)
                })
              }
            </select>
          </div>

          <div className="col-md-3">
            <div className="label-wrapper">
              <label className="control-label">Municipality / City <span className="vd_red">*</span></label>
            </div>
            <select style={errorCity} onChange={(e) => onFindBarangay(e.target.value)} value={onLocalCityValue ? onLocalCityValue : ''}>
              <option value="" disabled selected>Select...</option>
              {
                onLocalCity.map((data) => {
                  return (<option value={data.id}>{data.citymunDesc}</option>)
                })
              }
            </select>
          </div>

          <div className="col-md-3">
            <div className="label-wrapper">
              <label className="control-label">Barangay <span className="vd_red">*</span></label>
            </div>
            <select style={errorBarangay} onChange={(e) => onBarangay(e.target.value)} value={onLocalBarangayValue ? onLocalBarangayValue : ''}>
              <option value="" disabled selected>Select...</option>
              <option value="0">Out of List</option>
              {
                onLocalBarangay.map((data) => {
                  return (<option value={data.id}>{data.brgyDesc}</option>)
                })
              }
            </select>
          </div>
          {/* </div>
          <div className="form-group"> */}
          <div className="col-md-12">
            <div className="label-wrapper">
              <label className="control-label">Current Address (Street / Block / Lot No. / Subd.) <span className="vd_red">*</span></label>
            </div>
            <textarea style={errorAddress} onChange={(e) => setAddress(e.target.value)} placeholder="City / Street / Block / Lot No / Subd." autoComplete='off'></textarea>
          </div>
          {/* </div> */}
        </>
      )
    }
  }



  const addressShow = () => {
    if (ReserveDetails[0].inter_local === 'International') {
      return (
        <>
          <span style={{ fontSize: 12, fontWeight: "bold", textTransform: "capetalize" }}>
            <div>Country: {ReserveDetails[0].country}</div>
            <div>State: {ReserveDetails[0].province}</div>
            <div>{ReserveDetails[0].address}</div>
          </span>
        </>
      )
    } else if (ReserveDetails[0].inter_local === 'Local') {
      return (
        <>
          <span style={{ fontSize: 12, fontWeight: "bold", textTransform: "capetalize" }}>
            <div>Province: {ReserveDetails[0].province}</div>
            <div>City: {ReserveDetails[0].city}</div>
            <div>Barangay: {ReserveDetails[0].barangay}</div>
            <div>Landmark: {ReserveDetails[0].address}</div>
          </span>
        </>
      )
    }
  }




  // const SaveQrCode = async () => {

  //   const element = printRef.current;
  //   const canvas = await html2canvas(element);
  //   const data = canvas.toDataURL("image/PNG");
  //   const link = document.createElement("a");
  //   var formData = new FormData();
  //   console.log(data)
  // }
  // SaveQrCode();


  const changeHandlerImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = (e) => {
        setimageDisplayOne(e.target.result)
        // console.log(e.target.result)
      }
    }
  };
  const changeHandlerImageTwo = (event) => {
    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = (e) => {
        setimageDisplayTwo(e.target.result)
        // console.log(e.target.result)
      }
    }
  };






  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSymptoms = (value) => {
    if (value === 'With Symptoms') {
      setSymptomsShowTextField(true)
    } else if (value === 'Without Symptoms') {
      setSymptomsShowTextField(false)
    }
    setSymptoms(value)
  }



  // console.log(birthday.split('-')[0], format(new Date(), 'Y'), format(new Date(), 'Y')-birthday.split('-')[0])

  const pad = (number, length) => {
    var str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

  const disabledDates = () => {
    var today, dd, mm, yyyy;
    today = new Date();
    dd = pad(today.getDate() + 0, 2);
    mm = pad(today.getMonth() + 1, 2);
    yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }

  // console.log(disabledDates())
  const processingBTN = () => {
    if (ButtonDisabled) {
      return <button className='btn vd_bg-green vd_white' onClick={() => onSubmit()}>Submit {/* ()=>onSubmit() */}</button>
    } else {
      return <button className='btn vd_bg-default vd_white'>Submitting...</button>
    }
  }

  const suffixName = (name) => {
    if (name != 'None') {
      return ', '+name;
    } else {
      return '';
    }
  }

  const closed = () => {
    return false;
  }
  

  return (
    <>
    {
      closed() ? 
      <>
        <div className='hidden-xs' style={{display:"flex", flexDirection:"row", width:"100%", height:"100vh", justifyContent:"center", alignItems:"center", position:"absolute", zIndex:5}}>
          <div style={{width:"100vw", height:"100%", backgroundColor:"#f0f0f0", position:"absolute", opacity:0.5, zIndex:6}}>
          </div>
          <div style={{width:"25%", height:null, backgroundColor:"#fff", position:"absolute", zIndex:8, borderRadius:40}}>
            <div style={{backgroundColor:"#ad1017", height:80, width:"100%", display:"flex", flexDirection:"row", alignItems:"end", justifyContent:"center", borderTopLeftRadius:40, borderTopRightRadius:40}}>
              <label style={{fontSize:50, color:"#fff", fontFamily:"bahnschrift"}}>TEMPORARILY</label>
            </div>
            <div style={{backgroundColor:"#ad1017", height:200, display:"flex", alignItems:"center", justifyContent:"center", gap:10, flexDirection:"column", border:"20px solid #ad1017", borderBottomLeftRadius:40, borderBottomRightRadius:40}}>
              <div style={{backgroundColor:"#fff", display:"flex", height:"100%", width:"100%", alignItems:"center", justifyContent:"center", gap:20, flexDirection:"column", borderBottomLeftRadius:25, borderBottomRightRadius:25}}>
                <div style={{fontSize:100, textAlign:"center", fontWeight:"bold", fontFamily:"Bahnschrift Condensed"}}>CLOSED</div>
                <div style={{fontSize:20, textAlign:"center", fontWeight:"800", fontFamily: 'Rockwell'}}>DUE TO BIOLAB RENOVATION</div>
              </div>
            </div>
          </div>
        </div>
        <div className='visible-xs' style={{width:"100%", height:"100%", position:"absolute", zIndex:5}}>
          <div style={{display:"flex", flexDirection:"row", width:"100%", height:"100%", justifyContent:"center", alignItems:"center", position:"absolute", zIndex:5}}>
            <div style={{width:"100vw", height:"100%", backgroundColor:"#f0f0f0", position:"absolute", opacity:0.5, zIndex:6}}>
            </div>
            <div style={{width:"90%", height:null, backgroundColor:"#fff", position:"absolute", zIndex:8, borderRadius:20}}>
              <div style={{backgroundColor:"#ad1017", height:80, width:"100%", display:"flex", flexDirection:"row", alignItems:"end", justifyContent:"center", borderTopLeftRadius:20, borderTopRightRadius:20}}>
                <label style={{fontSize:35, color:"#fff", fontFamily:"bahnschrift"}}>TEMPORARILY</label>
              </div>
              <div style={{backgroundColor:"#ad1017", height:150, display:"flex", alignItems:"center", justifyContent:"center", gap:10, flexDirection:"column", border:"20px solid #ad1017", borderBottomLeftRadius:20, borderBottomRightRadius:20}}>
                <div style={{backgroundColor:"#fff", display:"flex", height:"100%", width:"100%", alignItems:"center", justifyContent:"center", gap:10, flexDirection:"column", borderBottomLeftRadius:10, borderBottomRightRadius:10}}>
                  <div style={{fontSize:80, textAlign:"center", fontWeight:"bold", fontFamily:"Bahnschrift Condensed"}}>CLOSED</div>
                  <div style={{fontSize:15, textAlign:"center", fontWeight:"800", fontFamily: 'Rockwell'}}>DUE TO BIOLAB RENOVATION</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      :
      null
    }

      <div id="pages" className="full-layout no-nav-left no-nav-right  nav-top-fixed background-login     responsive remove-navbar login-layout   clearfix" data-active="pages " data-smooth-scrolling="1">
        <div className="vd_body">

          <div className="content">
            <div className="container">
              <div className="vd_content-wrapper">
                <div className="vd_container">
                  <div className="vd_content clearfix">
                    <div className="vd_content-section clearfix">


                      <StyledModal
                        aria-labelledby="unstyled-modal-title"
                        aria-describedby="unstyled-modal-description"
                        open={open}
                        onClose={handleClose}
                        BackdropComponent={Backdrop}
                      >
                        <Box sx={style} style={{overflowX:"auto"}}>
                          <div style={{ height: "50%"}}>
                            <div style={{ textAlign: "center" }}>
                              <h3>Terms and Conditions</h3>
                            </div>
                            <div style={{overflowX: 'auto' }}>
                              {/* {ENV.TERMS_SERVICES} */}

                              <h3><b>Declaration and Data Privacy Consent</b></h3><br />
                              By submitting, I am agreeing that the information I have is true, correct, and<br />
                              complete. I understand that failure to answer any question or giving false answer can be<br />
                              penalized in accordance with law. I voluntarily and freely consent to the collection and<br />
                              sharing of the above personal information in relation to <b>BQMH</b> COVID-19 intenal protocols<br />
                              and for the purpose of affecting control of the COVID-19 infection as required by R.A.<br />
                              11469, Bayanihan to Heal as One Act.<br />
                              <br />
                              <h3><b>Terms of Use</b></h3><br />
                              The laboratory will not be responsible for any delays in flights. rebooking of hotels,<br />
                              rebooking of flights, and any logistical concerns that is caused by any positive result for<br />
                              COVID-19 and/or similar conditions.<br />
                              <br />
                              The laboratory merely interprets the data or specimen gathered from the patient.
                            </div>
                            <div className='row' style={{ paddingBottom: 5 }}>
                              <div className="col-md-6 mgbt-xs-5" style={{ paddingBottom: 0 }}>
                                <div style={{ display: "flex", justifyContent: "flex-start", paddingBottom: -5 }}>
                                  <div style={{ width: 25 }}>
                                    {
                                      agreement ?
                                        <label>
                                          <CheckBoxIcon onClick={() => setAgreement(false)} style={{ fontSize: 20 }} />
                                        </label>
                                        :
                                        <label>
                                          <CheckBoxOutlineBlankIcon onClick={() => setAgreement(true)} style={{ fontSize: 20 }} />
                                        </label>
                                    }

                                  </div>
                                  <div>
                                    <label htmlFor="checkbox-2"> I agree with <a>Terms and Conditions</a></label>
                                  </div>
                                </div>
                                <div className="col-md-6 mgbt-xs-5 text-right" style={{ paddingBottom: 5 }}>
                                  {processingBTN()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </StyledModal>



                      <div className='row'>
                        <div className='col-sm-6'>
                          <div className="heading clearfix">
                            <div className="logo">
                              <h2 className="mgbt-xs-5"><img src={image} style={{ width: "50%", borderRadius: "100%" }} /></h2>
                              <h2 className="mgbt-xs-5">
                                {/* <b>BQMHospital</b> */}
                              </h2>
                            </div>
                          </div>
                          {/* <video src={"276001546_5212206362131275_6564083002394236155_n.mp4"} style={{width:500, height:500}} /> */}
                          {/* <div style={{padding:50}}>{ENV.HomePage_Note}</div> */}

                        </div>
                        <div className='col-sm-6'>
                          <div className="">
                            <div className="panel widget">
                              <div className="panel-body">
                                {
                                  Reserve ?
                                    <div>
                                      <div style={{ textAlign: "center" }}>
                                        <h2>Reservation Number</h2>
                                        <h1><b>{ReserveDetails[0].resnumber}</b></h1>
                                      </div>
                                      <div>
                                        <div className="well">
                                          <div><b>Full Name :</b>
                                            <span style={{ fontSize: 15, fontWeight: "bold", textTransform: "uppercase" }}>
                                              {ReserveDetails[0].firstname} {ReserveDetails[0].lastname} {ReserveDetails[0].middlename} {suffixName(ReserveDetails[0].suffix)}
                                            </span>
                                          </div>
                                          <div><b>Contact:</b> <span style={{ fontSize: 15, fontWeight: "bold", textTransform: "capitalize" }}>{ReserveDetails[0].contact}</span></div>
                                          <div><b>Email:</b> <span style={{ fontSize: 15, fontWeight: "bold", textTransform: "lowercase" }}>{ReserveDetails[0].email}</span></div>
                                          <div>
                                            <div >
                                              {addressShow()}
                                            </div>
                                          </div>
                                          <div><b>Occupation:</b> {ReserveDetails[0].Occupation}</div>
                                        </div>
                                        <div className="well">
                                          <div><b>Purpose: {ReserveDetails[0].purpose}</b></div>
                                          <div><b> {PurposeDescription}</b></div>
                                        </div>
                                        <div className="well">
                                          <div className='row'>
                                            <div className='col-sm-2'>
                                              <QRCode value={ENV.DOMAIN + 'php/QRCodeResult.php?id=' + ReserveDetails[0].resno} ref={printRef} />
                                            </div>
                                            <div className='col-sm-10'>
                                              <div>URL Link: <a style={{ textDecoration: "underline" }}>
                                                <a href={ENV.DOMAIN + 'php/QRCodeResult.php?id=' + ReserveDetails[0].resno}>{ENV.DOMAIN}php/QRCodeResult.php?id={ReserveDetails[0].resno}</a>
                                              </a></div>
                                              <div>
                                                <div style={{fontSize:20}}>1. Save/Screenshot QR Code or URL Link to your Phone.</div>
                                                <div style={{fontSize:20}}>2. Bring CIF Form before proceeding.</div>
                                              
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="well text-center">
                                          <div style={{fontSize:35, marginBottom:15}}>Download, Fill-up and Print</div>
                                          <a href={ENV.DOMAIN + "php/CIF_-_Version_9_fillable.pdf"} className={"btn btn-primary btn-xs"} target="_blank" style={{fontSize:30}}>Link to CIF-FORM</a>
                                        </div>

                                      </div>
                                      <br />
                                      <div>
                                        <button className="btn btn-default btn-xs" onClick={() => BackReservation()}>{"<-"} Back</button>
                                      </div>
                                    </div>
                                    :
                                    <form className="form-horizontal" action="functions/register-form.php" role="form" id="register-form">
                                      <div className="form-group">
                                        <div className="col-md-4">
                                          <div className="label-wrapper">
                                            <label className="control-label">First Name <span className="vd_red">*</span></label>
                                          </div>
                                          <input type="text" placeholder="" className="required" required name="company" id="company" autoComplete='off'
                                            style={errorFirstname}
                                            onChange={(e) => setFirstname(e.target.value.toUpperCase())} />
                                        </div>

                                        <div className="col-md-3">
                                          <div className="label-wrapper">
                                            <label className="control-label">Middle Name <span className="vd_red">*</span></label>
                                          </div>
                                          <input type="text" placeholder="" className="required" required name="company" id="company" autoComplete='off'
                                            style={errorMiddlename}
                                            onChange={(e) => setMiddlename(e.target.value.toUpperCase())} />
                                        </div>

                                        <div className="col-md-3">
                                          <div className="label-wrapper">
                                            <label className="control-label">Last Name <span className="vd_red">*</span></label>
                                          </div>
                                          <input type="text" placeholder="" className="required" required name="company" id="company" autoComplete='off'
                                            style={errorLastname}
                                            onChange={(e) => setLastname(e.target.value.toUpperCase())} />
                                        </div>

                                        <div className="col-md-2">
                                          <div className="label-wrapper">
                                            <label className="control-label">Suffix <span className="vd_red">*</span></label>
                                          </div>
                                          <select style={errorSuffix} onChange={(e) => setSuffix(e.target.value)}>
                                            <option value="" disabled selected>Select...</option>
                                            <option value="None">None</option>
                                            <option value="Jr">Jr</option>
                                            <option value="Sr">Sr</option>
                                            <option value="II">II</option>
                                            <option value="III">III</option>
                                          </select>
                                          {/* <input type="text" placeholder="" className="required" required name="company" id="company" autoComplete='off'
                                      style={errorLastname}
                                      onChange={(e)=>setLastname(e.target.value)} /> */}
                                        </div>
                                      </div>

                                      <div className="form-group">
                                        <div className="col-md-3">
                                          <div className="label-wrapper">
                                            <label className="control-label">Civil Status <span className="vd_red">*</span></label>
                                          </div>
                                          <select style={errorCivilStatus} onChange={(e) => setCivilStatus(e.target.value)}>
                                            <option value="" disabled selected>Select...</option>
                                            <option value="Single">Single</option>
                                            <option value="Married">Married</option>
                                            <option value="Separated">Separated</option>
                                            <option value="Divorced">Divorced</option>
                                            <option value="Widowed">Widowed</option>
                                          </select>
                                          {/* {emailError} */}
                                        </div>

                                        <div className="col-md-4">
                                          <div className="label-wrapper">
                                            <label className="control-label">Contact <span className="vd_red">*</span></label>
                                          </div>
                                          <input type="number" placeholder="" className="required" required name="company" id="company" autoComplete='off'
                                            style={errorContact}
                                            onChange={(e) => setContact(e.target.value)} />
                                        </div>

                                        <div className="col-md-5">
                                          <div className="label-wrapper">
                                            <label className="control-label">Email <span className="vd_red">*</span></label>
                                          </div>
                                          <input type="text" placeholder="" className="required" required autoComplete='off'
                                            style={errorEmail}
                                            onChange={(e) => validateEmail(e)} />
                                          {emailError}
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="col-md-4">
                                          <div className="label-wrapper">
                                            <label className="control-label">Birth Date <span className="vd_red">*</span></label>
                                          </div>
                                          <input type="date" placeholder="" className="required" required name="company" id="company"
                                            style={errorBirthday}
                                            onChange={(e) => setBirthday(e.target.value)} />
                                        </div>
                                        <div className="col-md-2">
                                          <div className="label-wrapper">
                                            <label className="control-label">Age <span className="vd_red">*</span></label>
                                          </div>
                                          <input type="number" placeholder="" className="required" required name="company" id="company"
                                            style={errorAge}
                                            onChange={(e) => setAge(e.target.value)} />
                                        </div>
                                        <div className="col-md-2">
                                          <div className="label-wrapper">
                                            <label className="control-label">Gender <span className="vd_red">*</span></label>
                                          </div>
                                          <select style={errorGender} onChange={(e) => setGender(e.target.value)}>
                                            <option value="" disabled selected>Select...</option>
                                            <option>Male</option>
                                            <option>Female</option>
                                          </select>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="label-wrapper">
                                            <label className="control-label">Occupation <span className="vd_red">*</span></label>
                                          </div>
                                          <input type="text" style={errorOccupation} onChange={(e) => setOccupation(e.target.value)} placeholder="" value={Occupation} className="required" required name="company" id="company" autoComplete='off' />
                                        </div>
                                      </div>

                                      <div className="form-group">
                                        <div className="col-md-3">
                                          <div className="label-wrapper">
                                            <label className="control-label">International / Local <span className="vd_red">*</span></label>
                                          </div>
                                          <select style={{ height: 38 }} onChange={(e) => selectOption(e.target.value)}>
                                            <option selected value="Local">Local</option>
                                            <option value="International">International</option>
                                          </select>
                                        </div>
                                        {displayAddressSelection(onOption)}
                                      </div>




                                      <div className="form-group">
                                        <div className="col-md-3">
                                          <div className="label-wrapper">
                                            <label className="control-label">Symptoms <span className="vd_red">*</span></label>
                                          </div>
                                          <select style={errorSymptomsSelection} onChange={(e) => onSymptoms(e.target.value)}>
                                            <option value="" disabled selected>Select...</option>
                                            <option value={"With Symptoms"}>With Symptoms</option>
                                            <option value={"Without Symptoms"}>Without Symptoms</option>
                                          </select>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="label-wrapper">
                                            <label className="control-label">Schedule for Swabbing <span className="vd_red">*</span></label>
                                          </div>
                                          <input type="date" placeholder="" className="required" required
                                            style={errorScheduleSwabbing}
                                            onChange={(e) => setScheduleSwabbing(e.target.value)} min={disabledDates()} />
                                        </div>
                                        <div className="col-md-3">
                                          <div className="label-wrapper">
                                            <label className="control-label">Swabbing Time <span className="vd_red">*</span></label>
                                          </div>
                                          <select style={errorSwabbingTime} onChange={(e) => setSwabbingTime(e.target.value)}>
                                            <option value="" disabled selected>Select...</option>
                                            <option value={"6:00AM-8:00AM"}>6:00AM-8:00AM</option>
                                            <option value={"10:00AM-12:00NN"}>10:00AM-12:00NN</option>
                                            <option value={"8:00PM-10:00PM"}>8:00PM-10:00PM</option>
                                          </select>
                                        </div>
                                        {/* <div className="col-md-3">
                                      <div className="label-wrapper">
                                        <label className="control-label">Swabbing Time <span className="vd_red">*</span></label>
                                      </div>
                                      <div style={{fontSize:20, fontWeight:"bold"}}>6:00AM-8:00PM</div>
                                    </div> */}
                                      </div>
                                      {/* ////////////////////////////////////// */}
                                      {
                                        SymptomsShowTextField ?
                                          <div className="form-group">
                                            {/* Symptoms */}
                                            <div className="col-md-12">
                                              <div className="label-wrapper">
                                                <label className="control-label">Symptoms <span className="vd_red">*</span></label>
                                              </div>
                                              <textarea placeholder="Symptoms" onChange={(e) => setSymptomsDescription(e.target.value)} style={errorSymptomsDescription}></textarea>
                                            </div>
                                          </div>
                                          :
                                          <></>
                                      }
                                      {/* ////////////////////////////////////// */}
                                      <div className="form-group">
                                        <div className="col-md-6">
                                          <div className="label-wrapper">
                                            <label className="control-label">Purpose<span className="vd_red">*</span></label>
                                          </div>
                                          <select
                                            style={errorPurpose}
                                            onChange={(e) => setPurpose(e.target.value)}>
                                            <option value="" disabled selected>Select...</option>
                                            <option value="OPD">OPD</option>
                                            <option value="INPATIENT">INPATIENT</option>
                                            <option value="COMPANY">COMPANY</option>
                                            <option value="TRAVEL">TRAVEL</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        {ActionPurpose(purpose)}
                                      </div>
                                      {/* <div className="form-group">
                                    <div className="col-md-6">
                                      <div className="label-wrapper">
                                        <label className="control-label">PhilHealth ID Number <span className="vd_red">*</span></label>
                                      </div>
                                      <input type="text" placeholder="" className="required" />
                                    </div>                  
                                  </div>                   */}
                                      <div className="form-group">
                                        <div className="col-md-12">
                                          <div className="label-wrapper">
                                            <label className="control-label">UPLOAD ID<span className="vd_red">*</span></label>
                                          </div>
                                          <input type="file" placeholder="" style={errorimageDisplayOne} className="required" onChange={(e) => changeHandlerImage(e)} />
                                          {imageDisplayOne ?
                                            <><br /><img src={imageDisplayOne} style={{ width: 200, border: "5px solid #fff", borderRadius: 5 }} /><br /></>
                                            :
                                            null
                                          }
                                        </div>
                                      </div>
                                      <div id="vd_login-error" className="alert alert-danger hidden"><i className="fa fa-exclamation-circle fa-fw" /> Please fill the necessary field </div>
                                      <div className="form-group">
                                        <div className="col-md-12 mgbt-xs-5">
                                          {/* {DisBtnAppointment()} */}
                                          {
                                            closed() == true ?
                                            <button className="btn vd_bg-grey vd_white width-xs-2 width-lg-4" type="button">Disabled</button>
                                            :
                                            <button className="btn vd_bg-green vd_white width-xs-2 width-lg-4" type="button" onClick={handleOpen}>Set Appointment</button>
                                          }
                                          {/* ()=>onSubmit() */}
                                        </div>
                                      </div>
                                    </form>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}




export default AppointmentForm
