import React, { useEffect } from 'react'
import image from './../46964720_931525337041328_7708105959918272512_n.jpg';
import { ArrowDropDown, Logout } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';
import ENV from '../ENV';


const Header = () => {
  const history = useHistory();
  const isLoggedin = sessionStorage.getItem('isLoggedin');
  const isFullname = sessionStorage.getItem('isFullname');
  const isPosition = sessionStorage.getItem('isPosition');
  const isUsername = sessionStorage.getItem('isUsername');
  const isUserID = sessionStorage.getItem('isUserID');
  if (!isLoggedin) {
    if(history.location.pathname=='/upload'){

    } else {
      history.push('/')
    }    
  }

  // console.log(history.location.pathname)
  
  const onLogout = () => {
    swal({
      title: "Successfully",
      text: "Logged Out",
      icon: "success",
      timer: 2000,
      button: false
    }).then(() => {
      sessionStorage.removeItem('isLoggedin');
      history.push('/admin')
    })
  }

  const top_menu_profile = (isUserID, isPosition, isFullname, isUsername) => {
    if(isPosition!='BDC' && isPosition!='INFO' && isPosition!='SWABBER' && isPosition!='STAFF'){
      if(isUserID == 1){
        return (
          <>
            <li id="top-menu-profile" className="profile mega-li">
              <span className="mega-name" style={{color:"#fff"}}> {isFullname} </span> 
            </li>
          </>
        )
      } else {
        return (
          <>
            <li id="top-menu-profile" className="profile mega-li">
              <span className="mega-name" style={{color:"#fff"}}> {isUsername} </span> 
            </li>
          </>
        )
      }
    } else {
      return (
        <>
          <li id="top-menu-profile" className="profile mega-li">
            <span className="mega-name" style={{color:"#fff"}}> {isUsername} </span> 
          </li>
        </>
      )
    }
  }

  return (
    <header className="header-1" id="header">
      <div className="vd_top-menu-wrapper">
        <div className="container " style={{ backgroundColor: "#520408" }}>
          <div className="vd_top-nav vd_nav-width  ">
            <div className="vd_panel-header">
              <div className="logo">
                <a href="index.php"><img alt="logo" src={image} style={{ borderRadius: 100, position: "absolute", width: 50, marginTop: -10, left: 50 }} /></a>
              </div>
              <div className="vd_panel-menu left-pos visible-sm visible-xs">
                <span className="menu" data-action="toggle-navbar-left">
                  <i className="fa fa-ellipsis-v" />
                </span>
              </div>
            </div>
          </div>
          <div className="vd_container">
            <div className="row">
              <div className="col-sm-5 col-xs-12">

              </div>
              <div className="col-sm-7 col-xs-12">
                <div className="vd_mega-menu-wrapper">
                  {
                    history.location.pathname != '/upload' ?
                    <div className="vd_mega-menu pull-right">
                      <ul className="mega-ul">
                        {top_menu_profile(isUserID, isPosition, isFullname, isUsername)}
                        <li id="top-menu-profile" className="profile mega-li">
                          <a onClick={() => onLogout()} style={{ cursor: "pointer" }} className="btn btn-danger btn-xs"> Logout </a>
                        </li>
                      </ul>
                    </div>
                    :
                    null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

  )
}

export default Header
