import React from 'react'

const SwabbedTaleRow = ({item, num, ViewD}) => {
  if(item.noData != 1){
    return (
      <tr>
          <td>{num}</td>
          <td>{item.resnumber}</td>
          <td>{item.fullname} {item.suffix}</td>
          <td>{item.contact}</td>
          <td>{item.gender}</td>
          <td>{item.date_issued}</td>
          <td className="menu-action text-right"> {ViewD(item)} </td>
      </tr>
    )
  } else {
    return (
      <tr>
          <td colSpan={7} style={{textAlign:"center"}}>[No Record]</td>
      </tr>
    )
  }
}

export default SwabbedTaleRow
