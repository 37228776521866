import React from 'react'
import { Link } from 'react-router-dom'

const PendingTable = ({ isPosition, tableList, setSearch, onView, onEdit, onDelete, search }) => {

    var num = 1;

    const actionBTN = (item) => {
        if (isPosition === 'ADMIN') {
            return (
                <>
                    <a href={"/admin/appointment/details/" + item.id} data-original-title="view" data-toggle="tooltip" data-placement="top" className="btn menu-icon vd_bd-green vd_green"> Details </a>
                    <a href={'/admin/pending/result/encode/' + item.id} data-original-title="view" data-toggle="tooltip" data-placement="top" className="btn menu-icon vd_bd-grey vd_grey"> Encode </a>
                    <a onClick={() => onDelete(item.id)} data-original-title="Cancel" data-toggle="tooltip" data-placement="top" className="btn menu-icon  vd_bd-red vd_red"> Cancel </a>
                </>
            )
        } else {
            return (
                <>
                    <a href={'/admin/pending/result/encode/' + item.id} data-original-title="view" data-toggle="tooltip" data-placement="top" className="btn menu-icon vd_bd-grey vd_grey"> Encode </a>
                </>
            )
        }
    }

    const TableHead = (isPosition) => {
        if (isPosition === 'SECRETARY') {
            return (
                <thead>
                    <tr>
                        <th style={{ width: 50 }}>#</th>
                        <th>Res No.</th>
                        <th>Fullname</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Date Request</th>
                    </tr>
                </thead>
            )
        } else {
            return (
                <thead>
                    <tr>
                        <th style={{ width: 50 }}>#</th>
                        <th>Res No.</th>
                        <th>Fullname</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Date Request</th>
                        <th style={{ width: 200 }}></th>
                    </tr>
                </thead>
            )
        }
    }
    console.log(tableList.length)
    const TableBody = (tableList, isPosition) => {
        if (isPosition === 'SECRETARY') {
            return (
                <tbody>
                    {
                        tableList.map((item) => {
                            return (
                                <tr>
                                    <td>{num++}</td>
                                    <td>{item.resnumber}</td>
                                    <td>{item.fullname}</td>
                                    <td>{item.contact}</td>
                                    <td>{item.email}</td>
                                    <td>{item.date_registered}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            )
        } else {

            if(tableList.length!=0){
                return (
                    <tbody>
                        { 
                            tableList.map((item) => {
                                return (
                                    <tr>
                                        <td>{num++}</td>
                                        <td>{item.resnumber}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.contact}</td>
                                        <td>{item.email}</td>
                                        <td>{item.date_registered}</td>
                                        <td className="menu-action">
                                            {actionBTN(item)}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                )
            } else {
                return (
                    <tbody>
                        <tr>
                            <td colSpan={8} style={{textAlign:"center"}}>[No Pending Record]</td>
                        </tr>
                    </tbody>
                )
            }
        }
    }

    return (
        <div>
            <div style={{ padding: 10, flexDirection: "row" }}>
                <div>
                    <b>Search:</b>
                    <input onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                        value={search}
                        style={{ borderRadius: 5, borderWidth: 1, borderColor: "#c1c1c1", outline: "none" }} />
                </div>
            </div>
            <div className='table-responsive'>
                <table className='table table-striped table-hover dataTable no-footer'>
                    {TableHead(isPosition)}
                    {TableBody(tableList, isPosition)}
                </table>`
            </div>
        </div>
    )
}

export default PendingTable
