import axios from 'axios';
import React, { useEffect, useState } from 'react'
import image from './../46964720_931525337041328_7708105959918272512_n.jpg';
import QRCode from "qrcode.react";
import {
  useParams,
  useHistory
} from "react-router-dom";


const ReservationDetails = ({tableList, setViewDetails, ViewIds, onBack}) => {
    const history = useHistory();

    const [fullname, setFullname] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    // console.log(address)

    const [EditDetails, setEditDetails] = useState(false)
    const [EditDetailsShow, setEditDetailsShow] = useState(false)

    const [id, setID] = useState(ViewIds);

    useEffect(()=>{
        DisplayData();
    },[])

    const DisplayData = () => {
        axios.get('http://localhost/BQMH/client/php/getAppointment.php?id='+id)
        .then((res)=>{
            setEditDetails(res.data[0])
            console.log(res.data[0])
            setFullname(res.data[0].fullname);
            setContact(res.data[0].contact);
            setEmail(res.data[0].email);
            setAddress(res.data[0].address);
        }).catch((err)=>{
            console.log(err)
        })
    }

    const onEdit = (id) => {
        setEditDetailsShow(true);
    }
    const onCancelEdit = (id) => {
        setEditDetailsShow(false);

        setFullname(EditDetails.fullname);
        setContact(EditDetails.contact);
        setEmail(EditDetails.email);
        setAddress(EditDetails.address);
    }

    const onUpdate = async () => {
        var formData = new FormData();
        formData.append('id', EditDetails.id);
        formData.append('fullname', fullname);
        formData.append('contact', contact);
        formData.append('email', email);
        formData.append('address', address);
        await axios({
            method:'post',
            url:'http://localhost/BQMH/client/php/updateAppointment.php',
            // url:'http://bqmh.goldtigerclub.com/php/adminLogin.php',
            data: formData
        })
        .then((res)=>{
            console.log(res.data)
            setID(EditDetails.id)
            DisplayData();
            setEditDetailsShow(false);
        }).catch((err)=>{
            console.log(err)
        })

        // // console.log(formData, EditDetails.id);
    }

    const onConfirm = async () => {
        var formData = new FormData();
        formData.append('id', EditDetails.id);
        formData.append('fullname', fullname);
        formData.append('contact', contact);
        formData.append('email', email);
        formData.append('address', address);
        await axios({
            method:'post',
            url:'http://localhost/BQMH/client/php/confirmAppointment.php',
            // url:'http://bqmh.goldtigerclub.com/php/adminLogin.php',
            data: formData
        })
        .then((res)=>{
            if (res.data === 'success') {
                // history.push('/')
                setViewDetails(false)
                console.log(res.data) 
            }
            // setID(EditDetails.id)
            // DisplayData();
            // setEditDetailsShow(false);
        }).catch((err)=>{
            console.log(err)
        })

    }


  return (
    <div className='row'>
        {/* <div className='col-sm-4'></div> */}
        <div className='col-sm-4'></div>
        <div className='col-sm-4'>
            {
                EditDetailsShow ?
                <div className="vd_register-page">
                    <div className="panel widget" style={{height:700}}>
                        <div class="panel-body">
                            <div className='text-center' style={{fontSize:25, fontWeight:"bold"}}>Edit Information</div>
                            <form class="form-horizontal" action="functions/register-form.php" role="form" id="register-form">
                                <div class="alert alert-danger vd_hidden">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="icon-cross"></i></button><span class="vd_alert-icon"><i class="fa fa-exclamation-circle vd_red"></i></span><strong>Oh snap!</strong> Change a
                                    few things up and try submitting again.
                                </div>
                                <div class="alert alert-warning vd_hidden">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="icon-cross"></i></button><span class="vd_alert-icon"><i class="fa fa-exclamation-circle vd_red"></i></span>Your password and Confirm password
                                    are not same
                                </div>
                                <div class="alert alert-success vd_hidden">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="icon-cross"></i></button><span class="vd_alert-icon"><i class="fa fa-check-circle vd_green"></i></span>Registration confirmation has been sent
                                    to your email.
                                </div>
                                <div class="form-group">
                                    <div class="col-md-12">
                                        <div class="label-wrapper">
                                            <label class="control-label">Fullname <span class="vd_red">*</span></label>
                                        </div>
                                        <input type="text" placeholder="" class="required" required="" value={fullname ? fullname : EditDetails.fullname} onChange={(e)=>setFullname(e.target.value)} />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-12">
                                        <div class="label-wrapper">
                                            <label class="control-label">Contact <span class="vd_red">*</span></label>
                                        </div>
                                        <input type="text" placeholder="" class="required" required="" value={contact ? contact : EditDetails.contact} onChange={(e)=>setContact(e.target.value)} />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-12">
                                        <div class="label-wrapper">
                                            <label class="control-label">Email <span class="vd_red">*</span></label>
                                        </div>
                                        <input type="text" placeholder="" class="required" required="" value={email ? email : EditDetails.email} onChange={(e)=>setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-12">
                                        <div class="label-wrapper">
                                            <label class="control-label">Address <span class="vd_red">*</span></label>
                                        </div>
                                        <textarea rows="3" onChange={(e)=>setAddress(e.target.value)}>{address ? address : EditDetails.address}</textarea>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-6 text-center mgbt-xs-5"><button onClick={()=>onCancelEdit(EditDetails.id)} class="btn vd_bg-red vd_white width-100" type="button">Cancel</button></div>
                                    <div class="col-md-6 text-center mgbt-xs-5"><button onClick={()=>onUpdate()} class="btn vd_bg-green vd_white width-100" type="button">Update</button></div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
                :
                <div className="vd_register-page">
                    <div className="panel widget" style={{height:700}}>
                        <div class="panel-body" style={{borderRadius:10}}>
                        <QRCode value="0003" style={{ marginRight: 50 }}/>
                            <div style={{textAlign:"center"}}><h2 class="mgbt-xs-5"><img src={image} style={{width:200, borderRadius:"100%"}} /></h2></div>
                            <br />
                            <div>
                                {/* {ViewIds} */}
                                <table className='table table-striped'>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2} style={{textAlign:"center"}}>
                                                <div><b>Reservation</b></div> 
                                                <b style={{fontSize:20}}>{EditDetails.resnumber}</b>
                                                <br />
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{width:130}}>Fullname</td>
                                            <td>: <b style={{fontSize:15}}>{EditDetails.fullname}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Contact</td>
                                            <td>: <b style={{fontSize:15}}>{EditDetails.contact}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>: <b style={{fontSize:15}}>{EditDetails.email}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Date and Time</td>
                                            <td>: <b style={{fontSize:15}}>{EditDetails.date_sched} {EditDetails.time_sched}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>: <b>{EditDetails.address}</b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="menu-action">
                                                <div className='pull-left'><button onClick={()=>history.goBack()} className="btn btn-danger btn-xs">Back</button> &nbsp;</div>
                                                <div className='pull-right'>
                                                    <button onClick={()=>onConfirm()} className="btn btn-primary btn-xs">Confirm Payment</button> &nbsp;
                                                    <button onClick={()=>onEdit(EditDetails.id)} className="btn btn-info btn-xs">Edit Information</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br />
                            <div>                               
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div className='col-sm-4'></div>
    </div>
  )
}

export default ReservationDetails
