import React, { createRef, useEffect, useState } from "react";
import jsPDF from "jspdf";
import Pdf from "react-to-pdf";
import QRCode from "qrcode.react";
import { renderToString } from "react-dom/server";
import image from "./46964720_931525337041328_7708105959918272512_n.jpg";
import ENV from "./ENV";
import "./style.css";

import { saveAs } from "file-saver";

// import qrcode from './QRCode/1645933738874.png';
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';
const ExportReportsPDF = () => {
    const history = useHistory();
    const { token } = useParams();
    const ref = createRef();
    console.log(token);

    const [data, setData] = useState([]);
    const [openImage, setImage] = useState(false);
    const [PasswordAuth, setPasswordAuth] = useState(false);

    useEffect(() => {
        DisplayData();
    }, []);

    const DisplayData = async () => {
        await axios({
            method: "get",
            url: API_URL+"ClientSwabbedPDF.php?token=" + token,
        })
            .then((res) => {
                if (res.data[0].status === "open") {
                    setData(res.data[0]);
                } else if (res.data[0].status === "close") {
                    setData(res.data[0]);
                } else if (res.data[0].status === "expired") {
                    history.push("");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const downloadImage = (image) => {
        saveAs(image, "image.jpg"); // Put your image url here.
    };

    const accessPassword = () => {
        if (PasswordAuth != "") {
            if (data.password === PasswordAuth) {
                swal({
                    text: "Submitted",
                    icon: "success",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    setImage(true);
                });
            } else {
                swal({
                    text: "Incorrect Password",
                    icon: "error",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    setImage(false);
                });
            }
        } else {
            swal({
                text: "Please Enter Password",
                icon: "error",
                timer: 2000,
                button: false,
            }).then(() => {
                setImage(false);
            });
        }
    };

    return (
        <>
            <div id="pages" class="full-layout no-nav-left no-nav-right  nav-top-fixed background-login     responsive remove-navbar login-layout   clearfix" data-active="pages " data-smooth-scrolling="1">
                <div className="vd_body">
                    <div className="content">
                        <div className="container">
                            {/* Middle Content Start */}
                            <div className="vd_content-wrapper">
                                <div className="vd_container">
                                    <div className="vd_content clearfix">
                                        <div className="vd_content-section clearfix">
                                            <div className="row">
                                                <div className="col-sm-4"></div>
                                                <div className="col-sm-4" style={{ padding: 10, backgroundColor: "#fff", height: "auto" }}>
                                                    <img src={"http://bqmh.goldtigerclub.com/resultImage/" + data.image} style={{ width: "100%", height: "auto" }} />
                                                </div>
                                                <div className="col-sm-4" style={{ paddingTop: 50 }}>
                                                    <a href={"http://bqmh.goldtigerclub.com/resultImage/" + data.image} download="My_File.pdf">
                                                        Download as Image
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExportReportsPDF;
