import React, { createRef, useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import Pdf from "react-to-pdf";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";
import { renderToString } from "react-dom/server";
import image from "./46964720_931525337041328_7708105959918272512_n.jpg";
import ENV from "./ENV";

import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import RestorePageIcon from "@mui/icons-material/RestorePage";

// import qrcode from './QRCode/1645933738874.png';
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Header from "./Extra/Header";
import Navbar from "./Extra/Navbar";
import ReactToPrint, { useReactToPrint } from "react-to-print";
const RenderPDF = () => {
    const printRef = useRef();
    let componentRef = useRef();
    const history = useHistory();
    const { id } = useParams();
    const ref = createRef();

    const [datas, setData] = useState([]);

    // const isLoggedin = sessionStorage.getItem("isLoggedin");
    // if (isLoggedin === "false") {
    //     history.push("/");
    // }

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        DisplayData();
        // setTimeout(()=>{
        //   handleDownloadImage()
        // }, 1000)
    }, []);

    const DisplayData = async () => {
        await axios({
            method: "get",
            url: "http://bqmh.goldtigerclub.com/php/getSwabbedPDF.php?id=" + id,
        })
            .then((res) => {
                if (res.data[0].status === "open") {
                    setData(res.data[0]);
                } else if (res.data[0].status === "close") {
                    setData(res.data[0]);
                } else if (res.data[0].status === "expired") {
                    history.push("");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleDownloadImage = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL("image/jpg");
        const link = document.createElement("a");

        // console.log(data);
        var formData = new FormData();
        formData.append("resno", datas.id);
        formData.append("base64", data);
        await axios({
            method: "post",
            url: "http://bqmh.goldtigerclub.com/php/adminImageResult.php",
            data: formData,
        })
            .then((res) => {
                if (res.data === "success") {
                    swal({
                        title: "Done!",
                        text: "Successfully",
                        icon: "success",
                        timer: 2000,
                        button: false,
                    }).then(() => {
                        DisplayData();
                        // history.push('/swabbedresult')
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const sendViaEmail = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL("image/jpg");
        const link = document.createElement("a");

        // console.log(data);

        var formData = new FormData();
        formData.append("resno", datas.id);
        formData.append("base64", data);
        formData.append("email", datas.email);
        formData.append("token", datas.token);
        await axios({
            method: "post",
            url: "http://bqmh.goldtigerclub.com/php/sendUrlEmail.php",
            data: formData,
        })
            .then((res) => {
                if (res.data === "success") {
                    swal({
                        title: "Done!",
                        text: "Successfully",
                        icon: "success",
                        timer: 2000,
                        button: false,
                    }).then(() => {
                        DisplayData();
                        // history.push('/swabbedresult')
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <div id="pages" className="full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975" data-active="pages " data-smooth-scrolling={1}>
                <div className="vd_body">
                    {/* Header Start */}
                    <Header />
                    <div className="content">
                        <div className="container">
                            <Navbar />

                            {/* Middle Content Start */}
                            <div className="vd_content-wrapper" style={{ minHeight: 1059 }}>
                                <div className="vd_container" style={{ height: 1059 }}>
                                    <div className="vd_content clearfix">
                                        <div className="vd_head-section clearfix">
                                            <div className="vd_panel-header">
                                                <ul className="breadcrumb">
                                                    <li>
                                                        <a href="index.php">Home</a>{" "}
                                                    </li>
                                                    <li>
                                                        <a href="pages-custom-product.php">Pages</a>{" "}
                                                    </li>
                                                </ul>
                                                <div
                                                    className="vd_panel-menu hidden-sm hidden-xs"
                                                    data-intro="<strong>Expand Control</strong><br/>To expand content page horizontally, vertically, or Both. If you just need one button just simply remove the other button code."
                                                    data-step={5}
                                                    data-position="left"
                                                >
                                                    <div data-action="remove-navbar" data-original-title="Remove Navigation Bar Toggle" data-toggle="tooltip" data-placement="bottom" className="remove-navbar-button menu">
                                                        {" "}
                                                        <i className="fa fa-arrows-h" />{" "}
                                                    </div>
                                                    <div data-action="remove-header" data-original-title="Remove Top Menu Toggle" data-toggle="tooltip" data-placement="bottom" className="remove-header-button menu">
                                                        {" "}
                                                        <i className="fa fa-arrows-v" />{" "}
                                                    </div>
                                                    <div data-action="fullscreen" data-original-title="Remove Navigation Bar and Top Menu Toggle" data-toggle="tooltip" data-placement="bottom" className="fullscreen-button menu">
                                                        {" "}
                                                        <i className="glyphicon glyphicon-fullscreen" />{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="vd_content-section clearfix">
                                            {/* <button onClick={()=>print()}>Save PDF</button> */}
                                            <div className="row">
                                                <div className="col-sm-1"></div>
                                                <div className="col-sm-6" style={{ padding: 10, backgroundColor: "#fff", height: 1000 }}>
                                                    <div ref={(el)=>(componentRef = el)}>
                                                        <div ref={printRef} style={{ paddingTop: 30, height: 1000 }}>
                                                            <img src={image} style={{ position: "absolute", width: "70%", marginLeft: 110, marginTop: 200, opacity: 0.05 }} />
                                                            <div className="row">
                                                                <div className="col-sm-10 text-left" style={{ paddingLeft: 50, width: 650 }}>
                                                                    <img src={image} style={{ borderRadius: 50, width: 100, float: "left" }} />
                                                                    {/* </div> */}
                                                                    <div style={{ float: "left", paddingLeft: 20 }}>
                                                                        <span style={{ fontSize: 25, fontFamily: "timenewroman", fontWeight: "bold" }}>{ENV.COMPANY_NAME}</span>
                                                                        <br />
                                                                        Vestibulum et sem eu eros fringilla bibendum quis non quam
                                                                        <br />
                                                                        Nunc tempor sodales tempus
                                                                        <br />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-2 text-left" style={{ paddingTop: 15 }}>
                                                                    {/* <img src={qrcode ? qrcode : null} style={{borderRadius:5, width:100}} /> */}
                                                                    <QRCode value={"http://bqmh.goldtigerclub.com/resulttest/pdf/" + datas.token} style={{ width: 70, height: 70 }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ paddingTop: 30, textAlign: "center", borderBottom: "2px solid #787878" }}>
                                                                <span style={{ fontWeight: "bold" }}>LABORATORY REPORT</span>
                                                            </div>
                                                            <br />
                                                            <div style={{ padding: 30, paddingTop: 0 }}>
                                                                <table className="" style={{ width: "95%" }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Document ID</td>
                                                                            <td style={{ fontWeight: "bold" }}>: {datas.id}</td>
                                                                            <td>Request Date</td>
                                                                            <td style={{ fontWeight: "bold" }}>: {datas.date_request}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Patient Name</td>
                                                                            <td style={{ textTransform: "uppercase", fontWeight: "bold" }}>: {datas.fullname}</td>
                                                                            <td>Release Date</td>
                                                                            <td style={{ fontWeight: "bold" }}>
                                                                                : {datas.date_released} {datas.time_released}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Birth Date</td>
                                                                            <td style={{ fontWeight: "bold" }}>: {datas.birthdate}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Address</td>
                                                                            <td colSpan={3} style={{ fontWeight: "bold" }}>
                                                                                : {datas.address}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div style={{ paddingTop: 30, textAlign: "center", borderBottom: "2px solid #787878" }}>
                                                                <span style={{ fontWeight: "bold" }}>EXAMINATION</span>
                                                            </div>
                                                            <div style={{ padding: 30, paddingTop: 0 }}>
                                                                <table className="" style={{ width: "95%" }}>
                                                                    <tr>
                                                                        <td>
                                                                            COVID-19 VIRUS: <b style={{ textTransform: "uppercase", fontWeight: "bold" }}>{datas.test_status}</b>
                                                                        </td>
                                                                        <td>
                                                                            {/* Body Temp.: <b>{datas.body_temp}</b> */}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                            <div style={{ padding: 30, paddingTop: 0 }}>
                                                                <div style={{ textAlign: "center" }}> Note </div>
                                                                <div style={{ textAlign: "left" }}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    {ENV.PDF_NOTE}
                                                                </div>

                                                                <div style={{ padding: 20 }}></div>

                                                                <div style={{ float: "left", paddingLeft: 20, width: "50%", textAlign: "center" }}>
                                                                    _____________________ <br />
                                                                    Signature
                                                                </div>
                                                                {/* <div style={{float:"left", paddingLeft:20, width:"50%", textAlign:"center"}}>

                    _____________________ <br />
                    Signature
                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5" style={{ paddingTop: 50 }}>
                                                    <div style={{ marginBottom: 10 }}>
                                                        <ReactToPrint
                                                            trigger={() => 
                                                                <a className="btn btn-primary"> <PrintIcon /> Print
                                                                </a>
                                                            }
                                                            content={() => componentRef}
                                                        />
                                                        {/* <button onClick={()=>handlePrint()}>Print this out!</button> */}
                                                    </div>
                                                    <div style={{ marginBottom: 10 }}>
                                                        <Pdf targetRef={printRef} filename={Date.now() + ".pdf"}>
                                                            {({ toPdf }) => (
                                                                <button onClick={() => toPdf()} className="btn btn-primary">
                                                                    <PictureAsPdfIcon /> Save PDF
                                                                </button>
                                                            )}
                                                        </Pdf>
                                                    </div>
                                                    <div style={{ marginBottom: 10 }}>
                                                        <button type="button" onClick={() => sendViaEmail()} className="btn btn-primary">
                                                            <AttachEmailIcon /> Send via Email
                                                        </button>
                                                    </div>
                                                    <div style={{ marginBottom: 10 }}>
                                                        <button type="button" onClick={() => handleDownloadImage()} className="btn btn-info">
                                                            <RestorePageIcon style={{ fontSize: 15 }} /> Generate and Save
                                                        </button>
                                                    </div>
                                                    <div className="well">
                                                        <div>
                                                            Contact: <span style={{ textDecoration: "underline" }}>{datas.contact}</span>
                                                        </div>
                                                        <div>
                                                            Email: <span style={{ textDecoration: "underline" }}>{datas.email}</span>
                                                        </div>
                                                        <div>
                                                            URL: <span style={{ textDecoration: "underline" }}>http://bqmh.goldtigerclub.com/resulttest/pdf/{datas.token}</span>
                                                        </div>
                                                        {/* <div>Password:{datas.password}</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RenderPDF;
