import axios from 'axios'
import QRCode from 'qrcode.react'
import React, { useEffect, useMemo, useState } from 'react'
import Header from './Extra/Header'
import Navbar from './Extra/Navbar'
import image from './46964720_931525337041328_7708105959918272512_n.jpg';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { a, useHistory } from 'react-router-dom'
import swal from 'sweetalert'
import ENV from './ENV'
import { format } from 'date-fns'
import { styled, Box, borderRadius } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';




const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 700,
  // height: 700,
  bgcolor: '#fff',
  // border: '2px solid #000',
  p: 1,
  // px: 4,
  // pb: 3,
};








const AdminPendingResult = () => {
  const isPosition = sessionStorage.getItem("isPosition")
  const history = useHistory()
  const {id} = useParams();

  if(isPosition === 'SWABBER' || isPosition === 'BDC' || isPosition === 'INFO'){
    history.push('/admin/appointment')
  } else {

  }

  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [lastname, setLastname] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');

  const [EditDetails, setEditDetails] = useState(false)
  const [PurposeDescription, setPurposeDescription] = useState('')
  
  useEffect(()=>{
    DisplayData();
  },[])

  const DisplayData = () => {
    // url:'http://localhost/BQMH/client/php/fetchReservation.php',
    // axios.get('http://bqmh.goldtigerclub.com/php/getAppointment.php?id='+id)
    axios.get(ENV.DOMAIN+'php/getAppointment.php?id='+id)
    .then((res)=>{
        setEditDetails(res.data[0])
        setFirstname(res.data[0].firstname);
        setMiddlename(res.data[0].middlename);
        setLastname(res.data[0].lastname);
        setContact(res.data[0].contact);
        setEmail(res.data[0].email);
        setAddress(res.data[0].address);

        if(res.data[0].purpose == 'OPD') {
          setPurposeDescription(res.data[0].OPD);
        } else if(res.data[0].purpose == 'TRAVEL'){
          setPurposeDescription(res.data[0].TRAVELs);
        } else if(res.data[0].purpose == 'COMPANY'){
          setPurposeDescription(res.data[0].COMPANY);
        } else if(res.data[0].purpose == 'INPATIENT'){
          setPurposeDescription(res.data[0].INPATIENT);
        }

    }).catch((err)=>{
        console.log(err)
    })
  }
  // console.log(EditDetails.birthdate)


  const ConfirmPayment = async () => {
    var formData = new FormData();
    formData.append('id', EditDetails.id);
    formData.append('firstname', EditDetails.firstname);
    formData.append('middlename', EditDetails.middlename);
    formData.append('lastname', EditDetails.lastname);
    formData.append('contact', EditDetails.contact);
    formData.append('email', EditDetails.email);
    formData.append('address', EditDetails.address);
    formData.append('birthdate', EditDetails.birthdate);
    formData.append('date_sched', EditDetails.date_sched);
    formData.append('time_sched', EditDetails.time_sched);
    formData.append('datetime', EditDetails.datetime);
    formData.append('purpose', EditDetails.purpose);
    formData.append('validity', EditDetails.validity);
    await axios({
      method:'post',
      url:ENV.DOMAIN+'php/confirmAppointment.php',
      data: formData
    })
    .then((res)=>{
      if (res.data === 'success') {
        swal({
          title: "",
          text: "Success!",
          icon: "success",
          timer: 2000,
          button: false
        }).then(()=>{
          history.goBack();
        })

        // setViewDetails(false)
      }
      // console.log(res.data) 
    }).catch((err)=>{
        console.log(err)
    })
  }



  const btnConfirm = (isPosition) => {
    if(EditDetails.status == 'reserve') {
      if(isPosition != 'SECRETARY'){
        return (
          <>
            <div className='pull-left' style={{padding:10}}><button onClick={()=>history.goBack()} className="btn btn-warning btn-xs">Back</button> &nbsp;</div>
            <div className='pull-right' style={{padding:10}}>
                <button onClick={()=>ConfirmPayment()} className="btn btn-primary btn-xs">Confirm Payment</button> &nbsp;
                <a href={"/admin/appointment/details/edit/"+id} className="btn btn-info btn-xs">Edit Information</a>
            </div>
          </>
        )
      } else {
        return (
          <>
            <div className='pull-left' style={{padding:10}}><button onClick={()=>history.goBack()} className="btn btn-warning btn-xs">Back</button> &nbsp;</div>
          </>
        )
      }
    } else
    if(EditDetails.status == 'encoded') {
      return (
        <>
          <div className='pull-left' style={{padding:10}}>
            <button onClick={()=>history.goBack()} className="btn btn-warning btn-xs">Back</button> &nbsp;
            {/* <a to={history.goBack()} className="btn btn-success btn-xs">Back</a> */}
          </div>
          <div className='pull-left' style={{padding:10}}>
            <a href={"/admin/appointment/details/edit/"+id} className="btn btn-info btn-xs">Edit Information</a>
          </div>
          <div className='pull-left' style={{padding:10}}>
            <a href={"/admin/swabbed/result/"+EditDetails.resno+"/"+EditDetails.id} className="btn btn-success btn-xs">Show Result</a>
          </div>
        </>
      )
    }
    else {
      return (
        <>
          <div className='pull-left' style={{padding:10}}><button onClick={()=>history.goBack()} className="btn btn-warning btn-xs">Back</button> &nbsp;</div>
          <div className='pull-left' style={{padding:10}}>
            <a href={"/admin/appointment/details/edit/"+id} className="btn btn-info btn-xs">Edit Information</a>
          </div>
          <div className='pull-left' style={{padding:10}}>
            <a href={"/admin/pending/result/encode/"+EditDetails.id} className="btn menu-icon vd_bd-grey vd_grey">Encode</a>
          </div>
        </>
      )
    }
  }





    const onBack = (value) => {
      history.push('/bqmadminappoint')
    }

    const AddressDisplay = () => {
      if(EditDetails.inter_local === 'International'){
        return (
          <tr>
              <td>Address</td>
              <td>: <b>{EditDetails.country ? EditDetails.country : ''}<br/>{EditDetails.province ? ' - '+EditDetails.province : ''}<br/>{EditDetails.address ? ' - '+EditDetails.address : ''} </b></td>
          </tr>
        )
      } else if(EditDetails.inter_local === 'Local') {
        return (
          <tr>
              <td>Address</td>
              <td>: <b>{EditDetails.province ? EditDetails.province : ''}<br/>{EditDetails.city ? ' - '+EditDetails.city : ''}<br/>{EditDetails.address ? ' - '+EditDetails.address : ''} </b></td>
              {/* {EditDetails.barangay ? ' - '+EditDetails.barangay : ''}<br/> */}
          </tr>
        )
      }
    }

    const SuffixDisplay = (value) => {
      if(value != 'None') {
        if(EditDetails.suffix != undefined){
          return ', '+EditDetails.suffix;
        } else {
          return '';
        }
      } else {
        return '';
      }
    }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const RTPCRTest = (value) => {
    if(value === 'POSITIVE FOR SARS-COV-2 RNA'){
      return <span style={{color:"red"}}>POSITIVE FOR SARS-COV-2 RNA</span>;
    } else if(value === 'NEGATIVE FOR SARS-COV-2 RNA'){
      return <span style={{color:"#000"}}>NEGATIVE FOR SARS-COV-2 RNA</span>;
    } else {
      return <span style={{color:"#000", textTransform:"uppercase"}}>Pending</span>;
    }
  }
  const RTPCRTestIssued = (value) => {
    if(value!=''){
      return value;//format(value, 'MM-yy-');
    } else {
      return 'PENDING';
    }
  }

  const vd_container = (isPosition) => {
    if(isPosition === 'SWABBER' || isPosition === 'STAFF' || isPosition === 'INFO' || isPosition === 'BDC'){
        return '';
    } else {
        return 'vd_container';
    }
}

  return (
    <div id="pages" className="full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975" data-active="pages " data-smooth-scrolling={1}>     
      <div className="vd_body">
        {/* Header Start */}
        <Header />
        {/* Header Ends */} 
        <div className="content">
          <div className="container">
            <Navbar isPosition={isPosition} />    
              
            {/* Middle Content Start */}
            <div className="vd_content-wrapper" style={{minHeight: 1059}}>
              <div className={vd_container(isPosition)} style={{minheight: 1059}}>
                <div className="vd_content clearfix">





                    <StyledModal
                      aria-labelledby="unstyled-modal-title"
                      aria-describedby="unstyled-modal-description"
                      open={open}
                      onClose={handleClose}
                      BackdropComponent={Backdrop}
                    >
                      <Box sx={style}>
                        <img src={EditDetails.imageOneStatus ? ENV.DOMAIN+"php/images/"+EditDetails.imageDisplayOne : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKkUdiPgZg2ClfTbJ5jTUqyZz2mrEhTwg2S6J9oePSQijZXjRuKg"} />
                      </Box>
                    </StyledModal>








                  <div className="vd_head-section clearfix">
                    <div className="vd_panel-header">
                      
                    </div>
                  </div>
                  <div className="vd_title-section clearfix">
                    <div className="vd_panel-header no-subtitle">
                        <h1>Details</h1>
                    </div>
                  </div>
                  <div className="vd_content-section clearfix">
                    <div className="row"> 
                        {/* <div className='col-sm-4'></div> */}
                        <div className='col-sm-2'></div>
                        <div className='col-sm-8'>
                                <div className="vd_register-page">
                                    <div className="panel widget" style={{minHeight:700}}>
                                        <div className="panel-body" style={{borderRadius:10}}>
                                        {/* <QRCode value="http://bqmhospital.com?qr=3123235423&auth=1&token=1sf4dsd4f46554df568" style={{ marginRight: 50 }}/> */}
                                            <div style={{textAlign:"center"}}><h2 className="mgbt-xs-5"><img src={image} style={{width:200, borderRadius:"100%"}} /></h2></div>
                                            <br />
                                            <div className='table-responsive'>
                                                {/* {ViewIds} */}
                                                <table className='table table-striped'>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2} style={{textAlign:"center"}}>
                                                                <div><b>Reservation</b></div> 
                                                                <b style={{fontSize:20}}>{EditDetails.resnumber}</b>
                                                                <div>Registered</div>
                                                                <div>{EditDetails.date_reserve}</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{width:165}}>Fullname</td>
                                                            <td>: <b style={{fontSize:15}}><span style={{textTransform:"capitalize"}}>{EditDetails.firstname} {EditDetails.middlename} {EditDetails.lastname}{SuffixDisplay(EditDetails.suffix)}</span> </b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Contact</td>
                                                            <td>: <b style={{fontSize:15}}>{EditDetails.contact}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Email</td>
                                                            <td>: <b style={{fontSize:15}}>{EditDetails.email}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Birthday</td>
                                                            <td>: <b style={{fontSize:15}}>{EditDetails.birthday}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Age</td>
                                                            <td>: <b style={{fontSize:15}}>{EditDetails.age}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Civil Status</td>
                                                            <td>: <b>{EditDetails.civil_status}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gender</td>
                                                            <td>: <b>{EditDetails.gender}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Occupation</td>
                                                            <td>: <b>{EditDetails.Occupation}</b></td>
                                                        </tr>
                                                        {AddressDisplay()}
                                                        <tr>
                                                            <td>Schedule for Swabbing</td>
                                                            <td>
                                                              <div>: <b>{EditDetails.ScheduleSwabbing}</b></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Swabbing Time</td>
                                                            <td>
                                                              <div>: <b>{EditDetails.SwabbingTime}</b></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Symptoms</td>
                                                            <td>
                                                              <div>: <b>{EditDetails.Symptoms}</b></div>
                                                              <div>{EditDetails.SymptomsDescription?': '+EditDetails.SymptomsDescription:''}</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Purpose</td>
                                                            <td>
                                                              <div>: <b>{EditDetails.purpose}</b></div>
                                                              <div>: {PurposeDescription}</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>ID Picture</td>
                                                            <td>
                                                              <div className='row'>
                                                                <div className='col-sm-4'>
                                                                  <img  onClick={handleOpen} src={EditDetails.imageOneStatus ? ENV.DOMAIN+"php/images/"+EditDetails.imageDisplayOne : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKkUdiPgZg2ClfTbJ5jTUqyZz2mrEhTwg2S6J9oePSQijZXjRuKg"} />
                                                                </div>
                                                              </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>RT-PCR Test Result</td>
                                                            <td>
                                                              <div>Status: <span style={{textTransform:"uppercase"}}>{EditDetails.status}</span></div> 
                                                              <div>RT-PCR Test: {RTPCRTest(EditDetails.RTPCRTestRresult)}</div> 
                                                              <div>Date Issued: {RTPCRTestIssued(EditDetails.date_issued)}</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Link</td>
                                                            <td colSpan={2} className="menu-action">
                                                              <a href={ENV.DOMAIN+"php/QRCodeResult.php?id="+EditDetails.resno} target="_blank" style={{textDecoration:"underline"}}>{ENV.DOMAIN}php/QRCodeResult.php?id={EditDetails.resno}</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} className="menu-action">
                                                                {btnConfirm(isPosition)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                            <div>                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    {/* row */} 
                  </div>
                  {/* .vd_content-section */} 
                </div>
                {/* .vd_content */} 
              </div>
              {/* .vd_container */} 
            </div>
            {/* .vd_content-wrapper */} 
            {/* Middle Content End */} 
          </div>
          {/* .container */} 
        </div>
        {/* .content */}
        {/* Footer Start */}
        {/* <footer className="footer-1" id="footer">      
          <div className="vd_bottom ">
            <div className="container">
              <div className="row">
                <div className=" col-xs-12">
                  <div className="copyright">
                    Copyright ©{Copyright} STGCInc
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
        {/* Footer END */}
      </div>
      {/* .vd_body END  */}
      <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
      {/*
    <a className="back-top" href="#" id="back-top"> <i className="icon-chevron-up icon-white"> </i> </a> */}
      {/* Javascript =============================================== */} 
      {/* Placed at the end of the document so the pages load faster */} 
      {/*[if lt IE 9]>
      
    <![endif]*/}
    </div>
    
  )
}

export default AdminPendingResult
