import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import {
  useParams,
  useHistory
} from "react-router-dom";
import validator from 'validator'
import image from './logo-no-background.png';
import bqmlogowhite from './bqmlogowhite.png';
import COUNTRY from './world_countries/Country';
import STATES from './world_countries/States';
import ENV from './ENV';
import QRCode from "qrcode.react";
import QRCodes from 'qrcode'
import swal from 'sweetalert';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import html2canvas from "html2canvas";
import { styled, Box, borderRadius } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { format } from 'date-fns'
import { Helmet } from 'react-helmet-async';

// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';
// 1110963309746630



const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 600,
  height: 700,
  bgcolor: '#fff',
  // border: '2px solid #000',
  p: 1,
  // px: 4,
  // pb: 3,
};









const Home = () => {

  
  const history = useHistory();

  return (
    <div>
      <Helmet>
        <title>BQMHospital Home</title>
        <meta name="description" content="Hospital Bacolod Queen of Mercy" />
        <link rel='canonical' href='/' />
      </Helmet>
      <div className="vd_body">
        {/* Header Start */}
        <header className="header-2" id="header">
          <div className="vd_top-menu-wrapper" style={{background:"#520408"}}>
            <div className="container">
              <div className="vd_top-nav vd_nav-width  ">
                <div className="vd_panel-header">
                  <div className="logo">
                    <a href="index.php">
                      {/* <img alt="logo" src="img/logo-white.png" /> */}
                    </a>
                  </div>
                  {/* logo */}
                  <div className="vd_panel-menu visible-sm visible-xs">
                    <span className="menu visible-xs" data-action="submenu">
                      <WidgetsIcon style={{color:"#ddd", fontSize:15, marginTop:6}} />
                    </span>
                  </div>
                  {/* vd_panel-menu */}
                </div>
                {/* vd_panel-header */}
              </div>
              <div className="vd_container">
                <div className="row">
                  <div className="col-sm-8 col-xs-12">
                    <div className="vd_mega-menu-wrapper text-center">
                      <div className="vd_mega-menu">
                        <ul className="mega-ul nav">
                          <li className="mega-li">
                            <a href="/" className="mega-link" data-waypoint="services">
                              <span className="menu-text">HOME</span>
                            </a>
                          </li>
                          <li className="hover-trigger mega-li">
                            <a href="#" className="mega-link" data-action="click-trigger">
                              <span className="menu-text">SERVICES</span>
                            </a>
                            <div className="vd_mega-menu-content  width-xs-2  right-xs hover-target no-top" data-action="click-target" style={{ display: 'none' }}>
                              <div className="child-menu">
                                <div className="content-list content-menu">
                                  <ul className="list-wrapper">
                                    <li> <a style={{cursor:"pointer"}} onClick={()=>history.push('/form')}> APPOINTMENT FOR RT-PCR TEST</a> </li>
                                    <li> <a href="/services/pricing"> DEPARTMENTAL ITEM PRIVATE LIST</a> </li>
                                    <li>
                                      <a href="/services/room/rates">ROOM RATES</a>
                                    </li>
                                    <li>
                                      <a href="/services/medical/packages">MEDICAL PACKAGES</a>
                                    </li>
                                    {/* <li> <a href="/services/pricing"> DEPARTMENTAL ITEM PRIVATE LIST</a> </li> */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="mega-li">
                            <a href="/services/contact" className="mega-link" data-waypoint="services">
                              <span className="menu-text">CONTACT</span>
                            </a>
                          </li>
                          <li className="mega-li">
                            <a href="/services/about" className="mega-link" data-waypoint="services">
                              <span className="menu-text">ABOUT</span>
                            </a>
                          </li>
                        </ul>
                        {/* Head menu search form ends */}                       </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            {/* container */}
          </div>
          {/* vd_primary-menu-wrapper */}
        </header>
        {/* Header Ends */}
        <div className="content">
          <div id="front-1-features">
            <div className="container" style={{padding:0}}>
              <div className="vd_content clearfix" style={{background:"rgb(84 43 45)", backgroundImage:"url('')", backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat', overflow:"auto" }}>
                <div className="row" style={{height:"100vh"}}>
                  <div className="col-md-4 hidden-xs">
                    <div className="text-center" style={{paddingTop:100}}> <img style={{ width: 400, borderRadius:'100%' }} src={bqmlogowhite} /> </div>
                  </div>
                  <div className="col-md-4 visible-xs">
                    <div className="text-center" style={{paddingTop:50}}> <img style={{ width: 200, borderRadius:'100%' }} src={bqmlogowhite} /> </div>
                  </div>
                  <div className="col-md-7">
                    <div style={{color:"#fff"}}>
                      <div className="text-center" style={{ fontSize: 25, fontWeight: "bold", fontFamily: "timesnewroman", marginTop:50 }}>
                        <strong>MISSION</strong>
                      </div>
                      <div className='hidden-xs' style={{ padding: 20 }}>
                        <div style={{ fontSize: 25, fontWeight: "bold", width:600, margin:" auto", fontFamily: "timesnewroman" }}>
                          
                          <div style={{paddingBottom:5}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bacolod Queen of Mercy Hospital is committed</div>
                          <div style={{paddingBottom:5}}>to meet the health care needs of the community thru</div>
                          <div style={{paddingBottom:5}}>the delivery of quality, basic and specialized health</div>
                          <div style={{paddingBottom:5}}>care service that are:</div>
                        </div>
                      </div>
                      <div className='visible-xs' style={{ padding: 30 }}>
                        <div style={{ fontSize: 15, fontWeight: "bold", width: "100%", fontFamily: "timesnewroman" }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bacolod Queen of Mercy Hospital is committed
                          to meet the health care needs of the community thru
                          the delivery of quality, basic and specialized health
                          care service that are:</div>
                      </div>

                      <div className='hidden-xs' style={{ padding: 20, textAlign: "center", fontFamily: "timesnewroman" }}>
                        <div style={{ paddingBottom:5, fontSize: 25, fontWeight: "bold" }}>Competent</div>
                        <div style={{ paddingBottom:5, fontSize: 25, fontWeight: "bold" }}>Compassionate</div>
                        <div style={{ paddingBottom:5, fontSize: 25, fontWeight: "bold" }}>Cost Effective</div>
                        <div style={{ paddingBottom:5, fontSize: 25, fontWeight: "bold" }}>Patient Centered</div>
                      </div>
                      <div className='visible-xs' style={{ padding: 20, textAlign: "center", fontFamily: "timesnewroman" }}>
                        <div style={{ paddingBottom:5, fontSize: 18, fontWeight: "bold" }}>Competent</div>
                        <div style={{ paddingBottom:5, fontSize: 18, fontWeight: "bold" }}>Compassionate</div>
                        <div style={{ paddingBottom:5, fontSize: 18, fontWeight: "bold" }}>Cost Effective</div>
                        <div style={{ paddingBottom:5, fontSize: 18, fontWeight: "bold" }}>Patient Centered</div>
                      </div>
                      <div className="text-center" style={{ fontSize: 25, fontWeight: "bold", fontFamily: "timesnewroman", marginTop:50 }}>
                        <strong>VISION</strong>
                      </div>
                      <div className='hidden-xs'  style={{ padding: 20 }}>
                        <div style={{ fontSize: 25, fontWeight: "bold", width: 600, margin: " auto", fontFamily: "timesnewroman" }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BQMH seeks to be the hospital for all and a
                          <div style={{paddingBottom:5}}>leader in medical education and promotion of</div>
                          <div style={{paddingBottom:5}}>community wellness among all classes especially</div>
                          <div style={{paddingBottom:5}}>the under privileged members of the society.</div>
                        </div>
                      </div>
                      <div className='visible-xs'  style={{ padding: 20 }}>
                        <div style={{ fontSize: 15, fontWeight: "bold", width: 600, margin: " auto", fontFamily: "timesnewroman" }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BQMH seeks to be the hospital for all and a
                          <div style={{paddingBottom:5}}>leader in medical education and promotion of</div>
                          <div style={{paddingBottom:5}}>community wellness among all classes especially</div>
                          <div style={{paddingBottom:5}}>the under privileged members of the society.</div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                {/* <br />
                <br />
                <hr />
                <br />
                <br /> */}
                {/* <div className="row">
                  <div className="col-md-12 text-center mgbt-xs-20">
                    <div className="btn vd_btn vd_bg-white vd_round-btn  vd_bd-green vd_green btn-lg  mgbt-xs-15"><i className="fa  fa-camera fa-fw" /></div>
                    <h2 className="font-semibold"> Vendroid Screen Shot </h2><br />
                  </div>
                  <div className="vd_gallery">
                    <div className="col-lg-4 col-md-6 col-sm-6 mgbt-xs-15">
                      <div className="gallery-item full-width gallery-1 animated fadeInUp" style={{ opacity: 0 }}> <a href="img/screenshot/vendroid-01.jpg"> <img alt="example image" src="img/screenshot/vendroid-01-thumbs.jpg" />
                        <div className="bg-cover" />
                      </a>
                        <div className="vd_info">
                          <h3 className="mgbt-xs-15"><span className="font-semibold">Stunning</span> Dashboard</h3>
                          <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="img/screenshot/vendroid-01.jpg" data-rel="prettyPhoto[1]" rel="prettyPhoto[1]"><i className="fa fa-search" /></a> <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="#"><i className="fa fa-link" /></a> </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mgbt-xs-15">
                      <div className="gallery-item full-width gallery-2 animated fadeInUp" style={{ opacity: 0 }}> <a href="img/screenshot/vendroid-02.jpg"> <img alt="example image" src="img/screenshot/vendroid-02-thumbs.jpg" />
                        <div className="bg-cover" />
                      </a>
                        <div className="vd_info">
                          <h3 className="mgbt-xs-15"><span className="font-semibold">A Lot of</span> premade Pages</h3>
                          <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="img/screenshot/vendroid-02.jpg" data-rel="prettyPhoto[1]" rel="prettyPhoto[1]"><i className="fa fa-search" /></a> <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="#"><i className="fa fa-link" /></a> </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mgbt-xs-15">
                      <div className="gallery-item full-width gallery-3 animated fadeInUp" style={{ opacity: 0 }}> <a href="img/screenshot/vendroid-03.jpg"> <img alt="example image" src="img/screenshot/vendroid-03-thumbs.jpg" />
                        <div className="bg-cover" />
                      </a>
                        <div className="vd_info">
                          <h3 className="mgbt-xs-15"><span className="font-semibold">Dashboard </span>Examples</h3>
                          <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="img/screenshot/vendroid-03.jpg" data-rel="prettyPhoto[1]" rel="prettyPhoto[1]"><i className="fa fa-search" /></a> <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="#"><i className="fa fa-link" /></a> </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mgbt-xs-15">
                      <div className="gallery-item full-width gallery-4 animated fadeInUp" style={{ opacity: 0 }}> <a href="img/screenshot/vendroid-04.jpg"> <img alt="example image" src="img/screenshot/vendroid-04-thumbs.jpg" />
                        <div className="bg-cover" />
                      </a>
                        <div className="vd_info">
                          <h3 className="mgbt-xs-15"><span className="font-semibold">A lot of Skin and Functionality</span> to Choose</h3>
                          <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="img/screenshot/vendroid-04.jpg" data-rel="prettyPhoto[1]" rel="prettyPhoto[1]"><i className="fa fa-search" /></a> <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="#"><i className="fa fa-link" /></a> </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mgbt-xs-15">
                      <div className="gallery-item full-width gallery-5 animated fadeInUp" style={{ opacity: 0 }}> <a href="img/screenshot/vendroid-05.jpg"> <img alt="example image" src="img/screenshot/vendroid-05-thumbs.jpg" />
                        <div className="bg-cover" />
                      </a>
                        <div className="vd_info">
                          <h3 className="mgbt-xs-15"><span className="font-semibold">Neat </span> Invoice</h3>
                          <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="img/screenshot/vendroid-05.jpg" data-rel="prettyPhoto[1]" rel="prettyPhoto[1]"><i className="fa fa-search" /></a> <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="#"><i className="fa fa-link" /></a> </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mgbt-xs-15">
                      <div className="gallery-item full-width gallery-6 animated fadeInUp" style={{ opacity: 0 }}> <a href="img/screenshot/vendroid-06.jpg"> <img alt="example image" src="img/screenshot/vendroid-06-thumbs.jpg" />
                        <div className="bg-cover" />
                      </a>
                        <div className="vd_info">
                          <h3 className="mgbt-xs-15"><span className="font-semibold">Email</span> Ready Page</h3>
                          <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="img/screenshot/vendroid-06.jpg" data-rel="prettyPhoto[1]" rel="prettyPhoto[1]"><i className="fa fa-search" /></a> <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="#"><i className="fa fa-link" /></a> </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mgbt-xs-15">
                      <div className="gallery-item full-width gallery-7 animated fadeInUp" style={{ opacity: 0 }}> <a href="img/screenshot/vendroid-07.jpg"> <img alt="example image" src="img/screenshot/vendroid-07-thumbs.jpg" />
                        <div className="bg-cover" />
                      </a>
                        <div className="vd_info">
                          <h3 className="mgbt-xs-15"><span className="font-semibold">Website Layout Tour Guide</span> Ready Examples</h3>
                          <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="img/screenshot/vendroid-07.jpg" data-rel="prettyPhoto[1]" rel="prettyPhoto[1]"><i className="fa fa-search" /></a> <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="#"><i className="fa fa-link" /></a> </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mgbt-xs-15">
                      <div className="gallery-item full-width gallery-8 animated fadeInUp" style={{ opacity: 0 }}> <a href="img/screenshot/vendroid-08.jpg"> <img alt="example image" src="img/screenshot/vendroid-08-thumbs.jpg" />
                        <div className="bg-cover" />
                      </a>
                        <div className="vd_info">
                          <h3 className="mgbt-xs-15"><span className="font-semibold">Registration, Login, Error</span> and many example form</h3>
                          <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="img/screenshot/vendroid-08.jpg" data-rel="prettyPhoto[1]" rel="prettyPhoto[1]"><i className="fa fa-search" /></a> <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="#"><i className="fa fa-link" /></a> </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mgbt-xs-15">
                      <div className="gallery-item full-width gallery-9 animated fadeInUp" style={{ opacity: 0 }}> <a href="img/screenshot/vendroid-09.jpg"> <img alt="example image" src="img/screenshot/vendroid-09-thumbs.jpg" />
                        <div className="bg-cover" />
                      </a>
                        <div className="vd_info">
                          <h3 className="mgbt-xs-15"><span className="font-semibold">User Profile</span> Page</h3>
                          <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="img/screenshot/vendroid-09.jpg" data-rel="prettyPhoto[1]" rel="prettyPhoto[1]"><i className="fa fa-search" /></a> <a className="vd_bg-green vd_white mgr-10 btn vd_round-btn btn-xs" role="button" href="#"><i className="fa fa-link" /></a> </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>


          {/* front-1-contacts */}
        </div>
        {/* content */}
        {/* Middle Content End */}
        {/* Footer Start */}
        {/* <footer className="footer-2" id="footer">
          <div className="vd_bottom vd_bg-dark-green pd-20">
            <div className="container">
              <div className="row">
                <div className=" col-xs-12">
                  <div className="text-center mgbt-xs-10">
                    <a className="btn vd_btn vd_bg-facebook vd_round-btn btn-sm  mgr-10"><i className="fa fa-facebook fa-fw " /></a>
                    <a className="btn vd_btn vd_bg-googleplus vd_round-btn btn-sm  mgr-10"><i className="fa fa-google-plus fa-fw" /></a>
                    <a className="btn vd_btn vd_bg-twitter vd_round-btn btn-sm mgr-10"><i className="fa fa-twitter fa-fw " /></a>
                  </div>
                  <div className="copyright text-center">
                    <p><span className="mgr-10">795 Folsom Ave, Suite 600</span><span className="mgr-10">-</span><span className="mgr-10">San Francisco, CA 94107</span><br />
                      P: (123) 456-7890
                    </p>
                    Copyright ©2014 Venmond Inc. All Rights Reserved
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
        {/* Footer END */}
      </div>
      {/* .vc_body END  */}
      <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
      {/*
                                                  <a class="back-top" href="#" id="back-top"> <i class="icon-chevron-up icon-white"> </i> </a> */}
      {/* Javascript =============================================== */}
      {/* Placed at the end of the document so the pages load faster */}
      {/*[if lt IE 9]>
                                                  
                                                  <![endif]*/}
      {/* Specific Page Scripts Put Here */}
    </div>

  )
}




export default Home
