import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import Header from './Extra/Header'
import Navbar from './Extra/Navbar'
import ReservationDetails from './Extra/ReservationDetails'
import PendingTable from './Extra/PendingTable'
import swal from 'sweetalert'
import { useHistory } from 'react-router-dom'
import SwabbedResultTable from './Extra/SwabbedResultTable'
import ENV from './ENV'
// const API_URL = 'http://bqmh.goldtigerclub.com/php/';
// const API_URL = 'http://localhost/BQMH/client/php/';

const AdminTestingResult = () => {

  // const handleResize = () => {
  //   if (window.innerWidth < 720) {
  //       document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix';
  //   } else {
  //       document.body.className = 'full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975';
  //   }
  // }
  const isPosition = sessionStorage.getItem("isPosition")
  const history = useHistory();
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')

  useEffect(()=>{
      DisplayData();
      window.scrollTo(0, 0)
      // handleResize();
  },[])

  const DisplayData = async () => {
    await axios({
      method:'get',
      url:ENV.DOMAIN+'php/fetchSwabbedPending.php',
    })
    .then((res)=>{
      if(res.data){
        setData(res.data)
      }
    }).catch((err)=>{
      console.log(err)
    })
  }

  const [ViewDetails, setViewDetails] = useState(false);
  const [ViewIds, setViewIds] = useState();

  const onView = (item) => {
    setViewIds(item);
    setViewDetails(true);
    console.log(item);
  }

  const onBack = (value) => {
    setViewDetails(value);
    DisplayData();
  }
  

  const onDelete = (id) => {
    swal({
        title: "Confirmation!",
        text: "Are you sure!",
        icon: "info",
        // timer: 2000,
        buttons: {
          confirm:"Yes",
          cancel:"No"
        }
      }).then(async (isdelete) => {
        if (isdelete) {
          var formData = new FormData();
          formData.append("id", id);
          await axios({
              method: "post",
              url: ENV.DOMAIN + "php/deleteReservation.php",
              data: formData,
          })
          .then((res) => {
              if (res.data === "success") {
                  DisplayData();
              }
          })
          .catch((err) => {
              console.log(err);
          });
        }
    });
  }
  // console.log(ViewIds)







  const pad = (number, length) => {
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
}



  const reserveData = useMemo(()=>{
    let computedReserveData = data; 
    if (search) {
      computedReserveData = computedReserveData.filter(Search=>
                              Search.fullname.toLowerCase().includes(search.toLowerCase()) || 
                              Search.resnumber.toLowerCase().includes(search.toLowerCase()) || 
                              Search.contact.toLowerCase().includes(search.toLowerCase())
                            );
    }
    return computedReserveData;
  }, [data, search])
  


  return (
    <div id="pages" className="full-layout  nav-right-hide nav-right-start-hide  nav-top-fixed      responsive    clearfix breakpoint-975" data-active="pages " data-smooth-scrolling={1}>     
      <div className="vd_body">
        {/* Header Start */}
        <Header />
        {/* Header Ends */} 
        <div className="content">
          <div className="container">
            <Navbar isPosition={isPosition} />    
              
            {/* Middle Content Start */}
            <div className="vd_content-wrapper" style={{minHeight: 1059}}>
              <div className="vd_container" style={{minheight: 1059}}>
                <div className="vd_content clearfix">
                  <div className="vd_head-section clearfix">
                    <div className="vd_panel-header">
                      <div className="vd_panel-menu hidden-sm hidden-xs" data-intro="<strong>Expand Control</strong><br/>To expand content page horizontally, vertically, or Both. If you just need one button just simply remove the other button code." data-step={5} data-position="left">
                        
                      </div>
                    </div>
                  </div>
                  <div className="vd_title-section clearfix">
                    <div className="vd_panel-header no-subtitle">
                      <h1>Pending Result</h1>
                    </div>
                  </div>
                  <div className="vd_content-section clearfix" style={{minHeight:1000}}>
                    <div className="row">                      
                      <PendingTable isPosition={isPosition} tableList={reserveData} setSearch={setSearch} onView={onView} onDelete={onDelete} search={search} />  
                    </div>
                    {/* row */} 
                  </div>
                  {/* .vd_content-section */} 
                </div>
                {/* .vd_content */} 
              </div>
              {/* .vd_container */} 
            </div>
            {/* .vd_content-wrapper */} 
            {/* Middle Content End */} 
          </div>
          {/* .container */} 
        </div>
        {/* .content */}
        {/* Footer Start */}
        {/* <footer className="footer-1" id="footer">      
          <div className="vd_bottom ">
            <div className="container">
              <div className="row">
                <div className=" col-xs-12">
                  <div className="copyright">
                    Copyright ©{Copyright} STGCInc
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
        {/* Footer END */}
      </div>
      {/* .vd_body END  */}
      <a id="back-top" href="#" data-action="backtop" className="vd_back-top"> <i className="fa  fa-angle-up"> </i> </a>
      {/*
    <a className="back-top" href="#" id="back-top"> <i className="icon-chevron-up icon-white"> </i> </a> */}
      {/* Javascript =============================================== */} 
      {/* Placed at the end of the document so the pages load faster */} 
      {/*[if lt IE 9]>
      
    <![endif]*/}
    </div>
    
  )
}

export default AdminTestingResult
