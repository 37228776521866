import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SwabbedTaleRow from './SwabbedTaleRow';

const SwabbedResultTable = ({ isPosition, HeaderTable, tableList, setSearch, onView, onEdit, onDelete, search, onSorting, ShowAllData, Today, LastDayData }) => {

    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc");

    const onSortingChange = (field) => {
        const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc';
        setSortingField(field)
        setSortingOrder(order)
        onSorting(field, order)
    }

    const Status = (item) => {
        if (item.test_status === 'Negative') {
            return (<span className="label vd_bg-green append-icon">{item.test_status}</span>);
        } else if (item.test_status === 'Positive') {
            return (<span className="label vd_bg-red append-icon">{item.test_status}</span>);
        }
    }


    const ViewD = (item) => {
        // console.log(isPosition)
        if (isPosition === 'ADMIN') {
            return (
                <>
                    <a href={'/admin/appointment/details/' + item.resid} data-original-title="view" data-toggle="tooltip" data-placement="top" className="btn menu-icon vd_bd-grey vd_grey"> Details </a>
                    <a href={'/admin/swabbed/result/' + item.qrcode + '/' + item.id} data-original-title="view" data-toggle="tooltip" data-placement="top" className="btn menu-icon vd_bd-green vd_green"> View PDF </a>
                    {/* <a onClick={()=>onDelete(item.id)} data-original-title="delete" data-toggle="tooltip" data-placement="top" className="btn menu-icon  vd_bd-red vd_red"> Delete </a> */}
                </>
            )
        } if (isPosition === 'SECRETARY') {
            return (
                <a href={'/admin/swabbed/result/' + item.qrcode + '/' + item.id} data-original-title="view" data-toggle="tooltip" data-placement="top" className="btn menu-icon vd_bd-green vd_green"> View PDF </a>
            )
        }
    }

    var num = 1;

    return (
        <div>
            <div style={{ padding: 10, flexDirection: "row" }}>
                <div class="col-sm-2">
                    <b>Search:</b>
                    <input onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                        value={search}
                        style={{ borderRadius: 5, borderWidth: 1, borderColor: "#c1c1c1", outline: "none", width:185 }} />
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <button class="btn btn-info btn-xs" onClick={()=>ShowAllData()}>Show All</button> 
                        &nbsp;
                        <button onClick={()=>Today()} class="btn btn-info btn-xs">Today</button>
                        &nbsp;
                        <button onClick={()=>LastDayData()} class="btn btn-info btn-xs">Show Last 3Day/s</button>
                    </div>
                </div>
            </div>
            <div className='table-responsive'>
                <table className='table table-striped dataTable no-footer table-hover'>
                    <thead>
                        <tr>
                            {
                                HeaderTable.map(({ name, width, field, sortable }) => {
                                    // console.log(field)
                                    // onSortingChange(field)
                                    return (
                                        <th style={{ width: width }} onClick={() => sortable ? onSortingChange(field) : null}>
                                            {name}
                                            {
                                                sortingField && sortingField === field && (
                                                    <>{sortingOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</>
                                                )
                                            }
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableList.map((item, index) => {
                                return (
                                    <SwabbedTaleRow item={item} num={num++} ViewD={ViewD} key={item.id} />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SwabbedResultTable
